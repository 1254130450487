$default-main-columns-md: 8;
$default-sidebar-columns-md: 4;

.main{
  &.has-sidebar{
    @include media($md){
      @include span-columns($default-main-columns-md);
    }
  }
}

.sidebar-default{
  @include media($md){
    @include span-columns($default-sidebar-columns-md);
  }
}