.tile.is-gsm.is-provider{
  &:hover{
    border-color: darken($blue, 10);
  }
  .tile-content{
    display: table;
    width: 100%;
  }
  h3{
    margin-bottom: 1.5rem;
    vertical-align: middle;

  }
  .provider-image{
     max-height: 2.5rem;
     display: block;
  }
  &.is-kpn{
    .provider-image{
      max-height: 3.75rem;
    }
  }
  .usps{
    vertical-align: middle;
    li{
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      &:before{
        @extend .fa;
        content: "\f00c";
        margin-right: 0.5rem;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  @include media($xs){}
  @include media($sm-only){
    h3 {
      @include span-columns(10, table);
      padding: 0 2.5rem;
    }
    .provider-image{
      margin: 0 auto;
      max-height: 4rem;
    }
    &.is-kpn{
      .provider-image{
        max-height: 6rem;
      }
    }
    .usps{
      @include span-columns(14, table);
    }
  }
  @include media($md){
    h3{
      height: 3rem;
    }
  }
  @include media($lg){
    h3 {
      @include span-columns(10, table);
      vertical-align: middle;
      padding: 0 2.5rem;
    }
    .provider-image{
      margin: 0 auto;
      max-height: 4rem;
    }
    &.is-kpn{
      .provider-image{
        max-height: 6rem;
      }
    }
    .usps{
      @include span-columns(14, table);
      vertical-align: middle;
    }
  }
  @include media($xl){}
}