.is-gsm {
    .overview-contracts-sim-only {
        .offer-wrapper.slick-slider {
            border: 2px solid $medium-grey;
        }
        .offer-wrapper:not(.slick-slider) {
            .tile {
                border: 2px solid $medium-grey;
            }
        }
        @include media($xs-only){
            .offer-wrapper.slick-slider {
                margin-right: 8rem;
            }
        }
        @include media($xs) {
        }
        @include media($sm) {
            .offer-wrapper:not(.slick-slider) {
                .tile {
                    @include span-columns(12);
                    @include omega(2n);
                }
            }
        }
        @include media($md) {
            .offer-wrapper:not(.slick-slider) {
                .tile {
                    @include span-columns(8);
                    @include omega-reset(2n);
                }
            }
        }
        @include media($lg) {
        }
        @include media($xl) {
        }
    }
}
