// TODO: restructure

form.is-stepped{
  section{
    a.header-trigger{
      position: relative;
      font-size: 2rem;
      color: $light-grey;
      padding: 1rem 0;
      margin-bottom: 1rem;
      border-bottom: 2px solid $light-grey;
      display: block;
      h2{
        font-size: 2.4rem;
      }
      .edit{
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -5px;
        font-size: 1.4rem;
        color: $blue;
        text-decoration: underline;
      }
    }
    .control-row{
      &.is-basic{
      }
      &.is-not-sure{
        color: lighten($red,10);
      }
      &.is-specific{
        color: $blue;
      }
      &.is-conditional{
        //temp styling
        background: $lightest-grey;
        padding: 0.5rem 1rem;
      }
    }
    &.is-complete{
      a.header-trigger{
        border-bottom: 2px solid darken($light-grey,20);
        color: darken($light-grey,20);
      }
      h2{
        //&:after{
        //  @extend .fa;
        //  content: '\f00c';
        //  margin-left: 0.5rem;
        //  color: $green;
        //}
      }
    }
    &.is-complete:not(.is-active),&.is-visited:not(.is-active){
      &:hover{
        a.header-trigger{
          border-bottom: 2px solid $medium-grey;
          color: $medium-grey;
        }
      }
      .edit{
        display: block;
      }
    }
    &.is-active{
      padding-bottom: 2rem;
      border-bottom: 2px solid $blue;
      &:first-child{
        border-bottom: 2px solid $blue;
      }
      a.header-trigger{
        color: $black;
        border-bottom: 2px solid $blue;
      }
    }
    &.is-last{
      padding-bottom: 0;
      fieldset{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  &.is-checked{
    section{
      &.is-active{
        border-bottom: none;
      }
    }
  }
}