body{
  min-width: 20em;
}

.container{
  @include outer-container();
  @include media($xxs-only){
    min-width: 18em;
    max-width: $max-width-xxs - ((flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
  }
  @include media($xs-only){
    max-width: $max-width-xs - ((flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
  }
  @include media($sm-only){
    max-width: $max-width-sm - ((flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
  }
  @include media($md-only){
    max-width: $max-width-md - ((flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
  }
  @include media($lg-only){
    max-width: $max-width-lg - ((flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
  }
  @include media($xl-only){
    max-width: $max-width-xl - ((flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
  }
}

.container-mobile{
  @include media($mobile){
    min-width: 18em;
    @include outer-container();
  }
  @include media($xxs-only){
    max-width: $max-width-xxs - ((flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
  }
  @include media($xs-only){
    max-width: $max-width-xs - ((flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
  }
  @include media($sm-only){
    max-width: $max-width-sm - ((flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
  }
}

.container-desktop-max{
  @include outer-container();
  @include media($xxs-only){
    min-width: 18em;
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns))));
  }
  @include media($xs-only){
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns))));
  }
  @include media($sm-only){
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns))));
  }
  @include media($md-only){
    max-width: $max-width-md - ((flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
  }
  @include media($lg-only){
    max-width: $max-width-lg - ((flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
  }
  @include media($xl-only){
    max-width: $max-width-xl - ((flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
  }
}

.container-desktop{
  @include outer-container();
  @include media($md-only){
    max-width: $max-width-md - ((flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns))));
  }
  @include media($lg-only){
    max-width: $max-width-lg - ((flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns))));
  }
  @include media($xl-only){
    max-width: $max-width-xl - ((flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * 2);
    padding-left: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
    padding-right: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns))));
  }
}