.button{
  display: block;
  border-radius: $global-border-radius-half;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  &.is-medium-grey{
    background: $medium-grey;
    color: $white;
    &:hover{
      background: darken($medium-grey,10);
    }
  }
  &.is-blue{
    background: $blue;
    color: $white;
    &:hover{
      background: darken($blue,10);
    }
  }
}