.site-message{
    .wrapper{
        position: relative;
    }
    &.is-global{
        background: $black;
        color: $white;
        padding: 1.5rem 0;
        .wrapper{
            padding-right: 2rem;
        }
        &.is-warning{
            background: $red;
            .dismiss-trigger{
                &:hover{
                    color: $black;
                }
            }
            a{
                &:hover{
                    color: $black;
                }
            }
        }
        &.is-custom{
            display: block;
            padding: 1rem 0;
            &:hover{
            }
        }
        &.is-tmobile{
            background: $t-mobile;
            &:hover{
                background: darken($t-mobile, 5);
            }
        }
        &.is-vodafone{
            background: $vodafone;
            &:hover{
                background: darken($vodafone, 5);
            }
        }
    }
    &.is-label{
        .wrapper{
            background: $lightest-grey;
            color: $medium-grey;
            padding: 1rem;
        }
    }
    .entry-content{
        font-size: 1.2rem;
    }
    .dismiss-trigger{
        font-size: 2rem;
        position: absolute;
        right: 0;
        top: -3px;
        cursor: pointer;
        &:hover{
            color: $red;
        }
        .fa{
            margin-right: 0.5rem;
        }
    }
}

body{
    &.template_front{
        .site-message{
            &.is-label{
                margin-top: 1rem;
            }
        }
    }
    &.home{
        .site-message{
            &.is-label{
                margin-bottom: -1rem;
            }
        }
    }
}

@include media($desktop){
    body{
        &:not(.is-miky){
            .site-message{
                &.is-label{
                    margin-top: 3rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
        &.is-miky{
            .site-message{
                &.is-label{
                    margin-top: 1.5rem;
                }
            }
        }
    }
    .site-message{
        .entry-content{
            font-size: 1.4rem;
        }
    }
}

/*Custom global messages*/
body{
    /*Do not display custom global message on these templates*/
    &.template_order-overview,&.template_order-form,&.template_order-form-check,&.template_order-confirmation,&.offer-422{
        .site-message.is-global.is-custom{
            display: none;
        }
    }
    /*Set different styling for custom global message on these templates*/
    &.template_front.is-gsm,&.template_front.is-miky{
        .site-message.is-global.is-custom{
            background: $medium-dark-grey;
            &:hover{
                background: darken($medium-dark-grey, 5);
            }
        }
    }
}
