blockquote{
  .quote-text{
    position: relative;
    &:before,&:after{
      font-size: 2em;
      position: absolute;
      line-height: 1;
      font: normal normal normal 14px/1 FontAwesome;
    }
    &:before{
      content: '\f10d';
      left: 0;
      top: 0;
    }
    &:after{
      content: '\f10e';
      right: 0;
      bottom: 0;
    }
  }
}