.blog-posts{
  padding: 3rem 0;
  article.post{
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $light-grey;
    &:last-child{
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}