.is-gsm {
    &.template_offer {
        .breadcrumbs {
            display: none;
        }

        main {
            display: inline-block;
            width: 100%;
        }

        h1 {
            margin-bottom: 0.5rem;
        }

        .config-teaser-wrap {
            background: $dark-grey;
            width: 14em;
            padding: 1rem 2rem;
            border-radius: $global-border-radius;
            box-shadow: 0 0 5px 0 $medium-grey;
            text-align: center;
            .hint {
                color: $white;
            }
        }

        .offer-main {
            @include span-columns(24);
            position: relative;

            .is-section {
                padding: 4rem 0;
                &.offer-intro {
                    padding: 0 0 1rem 0;
                }
                &.offer-contract-provider-choice {
                    padding: 0;
                    border-bottom: 0;
                }
                &.offer-type {
                    padding: 2rem 0;
                }
            }

            .is-step {
                .is-section {
                    padding: 0 0 $section-padding 0;
                    &.is-first {
                        padding: $step-padding 0 $section-padding 0;
                    }
                    &.is-last {
                        padding: 0 0 $step-padding 0;
                    }
                    &.is-first.is-last {
                        padding: $step-padding 0;
                    }
                    &.offer-intro {
                        padding: 0 0 $section-padding 0;
                    }
                    &.is-section-title {
                        padding: $step-padding 0 0 0;
                    }
                    &.offer-contract-provider-info {
                        padding: 3rem 0;
                    }
                }
                &#step-contract-type{
                    .is-first.is-last {
                        padding: $step-padding 0 0 0;
                    }
                }
            }

        }

        .offer-bundle {
            .inner {
                @include gutter-all-desktop('padding-right', 0.5);
            }
        }
        .offer-information {
            .inner {
                @include gutter-all-mq('padding-right', 'lg', 0.5);
                @include gutter-all-mq('padding-right', 'xl', 0.5);
            }
        }

        .offer-section-title-contract {
            background: $light-blue-grey;
        }

        // Title styles
        .section-title {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2.5rem;
        }
        .info-title {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
        }
        .title {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
        }
        .subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 1.5rem;
        }

        // Button styles
        .button-wrapper {
            @include outer-container();
            button {
                position: relative;
                border-radius: $global-border-radius-half;
                @include gutter-all('margin-bottom');
            }
        }
        .is-small {
            button {
                @include span-columns(12);
                @include omega(2n);
            }
        }
        .is-large {
            button {
                @include span-columns(24);
            }
        }
        .is-basic {
            button {
                font-size: 1.4rem;
                font-weight: 700;
                padding: 0.8rem 1rem;
                background: $blue;
                color: $white;
                &:hover {
                    background: darken($blue, 10);
                }
                &.is-active {
                    background: darken($blue, 20);
                    &:before{
                        @extend .fa;
                        content: '\f00c';
                        margin-right: 0.5rem;
                    }
                }
            }
        }
        .is-extended {
            button {
                .popular {
                    display: block;
                    //Temp remove 'popular' indication until this function is separated from 'active' function
                    display: none;
                    font-size: 1.2rem;
                }
                .name {
                    display: block;
                    font-weight: 700;
                    padding: 0.6rem 1.6rem;
                    background: $blue;
                    color: $white;
                    border-radius: $global-border-radius-half $global-border-radius-half 0 0;
                }
                .content {
                    display: block;
                    padding: 1rem;
                    background: $white;
                    border: 2px solid $light-grey;
                    border-bottom: 1px solid darken($lightest-grey, 10);
                    border-top: none;
                }
                .paragraph {
                    display: block;
                    font-size: 1.2rem;
                    line-height: 1.2;
                    margin-bottom: 0.5rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .price {
                    display: block;
                    padding: 1rem;
                    background: $white;
                    border: 2px solid $light-grey;
                    border-top: none;
                    border-radius: 0 0 $global-border-radius-half $global-border-radius-half;
                    > span {
                        display: block;
                        &.original {
                            // Show/hide original price
                            //display: none;
                        }
                    }
                    .info {
                        margin-top: 0.5rem;
                        font-size: 1.2rem;
                        color: $medium-grey;
                    }
                }
                .name{
                    .actual{

                    }
                    .original{
                        &.strikethrough{
                            position: relative;
                            &:after{
                                position: absolute;
                                content: "";
                                left: 0;
                                top: 40%;
                                right: 0;
                                border-top: 3px solid;
                                transform: rotate(-7deg);
                                border-color: $red;
                            }
                        }
                    }
                }
                .price{
                    position: relative;
                    .allinone-type{
                        margin-bottom: 0.6rem;
                    }
                    .actual {
                        font-size: 2.2rem;
                        font-weight: 700;
                    }
                    .original {
                        margin-top: 0.5rem;
                        font-size: 1.2rem;
                        color: $medium-grey;
                        text-decoration: line-through;
                        height: 12px;
                    }
                    .extra-discount{
                        margin-top: 1rem;
                        padding-top: 1rem;
                        font-size: 1.2rem;
                        font-weight: 700;
                        color: $green;
                        &.has-extra-discount{
                            border-top: 1px solid $light-grey;
                        }

                        //TODO: fix height for absolute positioned extra-discount
                        //border-radius: $global-border-radius-half;
                        //margin-top: 1rem;
                        //margin-left: -1rem;
                        //padding:  0.7rem 0.5rem;
                        //position: absolute;
                        //top: 100%;
                        //width: 100%;
                        //font-size: 1.2rem;
                        //&.has-extra-discount{
                        //  background: $green;
                        //  color: $white;
                        //}
                    }
                }
                .price-month, .price-once {
                    font-weight: 700;
                    font-size: 1.8rem;
                    .type {
                        font-weight: 500;
                        font-size: 1.4rem;
                    }
                }
                .price-month {

                }
                .price-once {
                    margin-top: 0.5rem;
                }
                .price-total {
                    margin-top: 1rem;
                    font-size: 1.2rem;
                    color: $medium-grey;
                }
                &:hover {
                    .name {
                        background: darken($blue, 10);
                    }
                    .content {
                        border-color: darken($blue, 10);
                        border-bottom-color: darken($lightest-grey, 10);
                    }
                    .price {
                        border-color: darken($blue, 10);
                    }
                }
                &.is-active {
                    .name {
                        background: darken($blue, 20);
                        .is-inner{
                            position: relative;
                            &:before{
                                @extend .fa;
                                content: '\f00c';
                                font-size: 1.3rem;
                                position: absolute;
                                left: -1.7rem;
                                margin-top: 0.1rem;
                            }
                            &.is-phonepay{
                                &:before{
                                    left: -2rem;
                                    margin-top: 0.3rem;
                                }
                            }
                        }
                    }
                    .content {
                        border-color: darken($blue, 20);
                        border-bottom-color: darken($lightest-grey, 10);
                    }
                    .price {
                        border-color: darken($blue, 20);
                    }
                }
                &.is-popular {
                    .popular {
                        padding: 0.4rem 1rem 0.6rem 1rem;
                        background: $yellow;
                        border-radius: $global-border-radius-half $global-border-radius-half 0 0;
                        font-weight: 700;
                    }
                    .name {
                        //Temp remove 'popular' indication until this function is separated from 'active' function
                        //border-radius: 0;
                    }
                }
                > span {
                    &:last-child {
                        border: 2px solid $light-grey;
                        border-top: none;
                        border-radius: 0 0 $global-border-radius-half $global-border-radius-half;
                    }
                }
                &:hover {
                    > span {
                        &:last-child {
                            border-color: darken($blue, 10);
                        }
                    }
                }
                &.is-active {
                    > span {
                        &:last-child {
                            border-color: darken($blue, 20);
                        }
                    }
                }
            }
        }
        .is-horizontal{
            margin-bottom: 4rem;
            button{
                @include span-columns(24);
                margin-bottom: 1rem;
                .name{
                    @include span-columns(9);
                    margin-right: 0;
                    border-radius: 4px 0 0 4px;
                    background: $blue;
                    color: #fff;
                    padding: 10px;
                    .is-inner{
                        float: left;
                    }
                    .actual{
                        > span{
                            display: inline;
                            white-space: nowrap;
                            margin-right: 5px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                .price{
                    @include span-columns(15);
                    border-top-width: 2px !important;
                    border-top-style: solid !important;
                    border-top-color: inherit !important;
                    border-left: none !important;
                    border-radius: 0 4px 4px 0 !important;
                    padding: 9px 6px 8px 6px;
                    > span{
                        display: inline-block;
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        text-align: left !important;
                    }
                    .allinone-type{
                        @include span-columns(6);
                        margin-bottom: 0;
                    }
                    .actual{
                        @include span-columns(6);
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                    }
                    .extra-discount{
                        @include span-columns(12);
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        margin-top: 0;
                        padding-top: 0;
                        border: none !important;
                    }
                }
            }
        }


        button{
            &:disabled, &:hover:disabled {
                &.is-active{
                    cursor: default;
                }
                &:not(.is-active){
                    cursor: not-allowed;
                    color: darken($light-grey,10);
                    border-color: darken($light-grey,10);
                    &.is-popular{
                        .popular{
                            background: darken($light-grey,20);
                        }
                    }
                    .name{
                        background: darken($light-grey,10);
                        .original{
                            &:after{
                                border-color: darken($light-grey,20) !important;
                            }
                        }
                    }
                    .content{
                        border-color: darken($light-grey,10) !important;
                    }
                    .price{
                        .original{
                            color: darken($light-grey,10);
                        }
                    }
                    > span:last-child{
                        border-color: darken($light-grey,10);
                    }
                }
            }
        }

        //No bundle placeholder
        .no-bundles {
            margin: 1rem 0 3rem 0;
        }

        // Goodies with specific bundles
        .goodie{
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            z-index: -1;
            &.is-jbl{
                top: -56px;
            }
        }

        button{
            transition: all 0.2s;
        }
        .goodyTrigger{
            button{
                transition: all 0.2s;
                //&.is-active{
                //  margin-top: 50px;
                //}
            }
            button.is-active .goodie.is-jbl{
                transition: opacity 0.2s;
                opacity: 1;
                animation-name: zoomInDown;
            }
        }

        @include media($xxs-only) {
            h1 {
                font-size: 2.2rem;
            }

            //TODO setup mobile styling for buttons
            //.is-extended{
            //  button{
            //    @include span-columns(24);
            //    @include omega();
            //    .name{
            //      @include span-columns(8, 'block-collapse');
            //    }
            //    .price{
            //      @include span-columns(16);
            //    }
            //  }
            //}
        }
        @include media($xs) {
            .offer-main .is-section {
                .is-large {
                    button {
                        @include span-columns(12);
                        @include omega(2n);
                    }
                }
            }
        }
        @include media($xs-only) {
            h1 {
                font-size: 2.8rem;
            }
        }
        @include media($sm) {
        }
        @include media($mobile) {
            h1 {
                margin-bottom: 0;
            }
            .inner {
                @include span-columns(24);
                @include omega();
            }
            .spacer {
                display: none;
            }
        }
        @include media($desktop) {
        }
        @include media($md-only) {
            .inner {
                @include span-columns(15, 'block-collapse');
            }
            .spacer {
                @include span-columns(9);
                @include omega();
            }
            .offer-information {
                .wrapper {
                    padding-right: 0;
                }
                .inner {
                    @include span-columns(24);
                    @include omega();
                }
                .spacer {
                    display: none;
                }
            }
        }
        @include media($lg-only) {
            .inner {
                @include span-columns(17, 'block-collapse');
            }
            .spacer {
                @include span-columns(7);
                @include omega();
            }
        }
        @include media($lg) {
            .offer-main .is-section {
                .is-small {
                    button {
                        @include span-columns(6);
                        @include omega-reset(2n);
                        @include omega(4n);
                    }
                    &.bundle-count-3, &.bundle-count-6 {
                        button {
                            @include span-columns(8);
                            @include omega-reset(4n);
                            @include omega(3n);
                        }
                    }
                    &.bundle-count-5 {
                        button {
                            //24 column grid not divisible by 5 > use custom with
                            width: 18.15%;
                            @include omega-reset(4n);
                            @include omega(5n);
                        }
                    }
                }
            }
        }
        @include media($xl-only) {
            .inner {
                @include span-columns(18, 'block-collapse');
            }
            .spacer {
                @include span-columns(6);
                @include omega();
            }
        }
    }
}
