.service-provider{
  &:nth-child(3n-2){
    background: $yellow;
  }
  &:nth-child(3n-1){
    .accordion{
      ul.submenu{
        background: $lightest-grey;
      }
    }
  }
  &:nth-child(3n){
    background: $lightest-grey;
  }
  h3{
    font-size: 1.8rem;
    font-weight: 500;
    margin: 3rem 0 2rem 0;
  }
  .service-pages{
    margin: 3rem 0 0 0;
    @include clearfix;
  }
  .section-wrapper{
    margin-bottom: 3rem;
  }
  .accordion{
    font-size: 1.4rem;
  }
  a.section-cta{
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    color: $blue;
    &:after{
      @extend .fa;
      content: '\f054';
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    &:hover{
      color: darken($blue,10);
      text-decoration: underline;
    }
  }
  h4{
    font-size: 2rem;
    font-weight: 500;
    margin: 3rem 0 1.5rem 0;
    &:first-child{
      margin-top: 0;
    }
  }
  .overview-cta{
    display: block;
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: $blue;
    &:hover{
      color: darken($blue,10);
    }
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .section-wrapper{
      @include span-columns(12);
    }
  }
  @include media($lg){}
  @include media($xl){}
}