.order-navigation{
  @include clearfix;
  .step{
    padding: 1rem 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    &.is-previous{
      padding-left: 0;
      color: $blue;
      float: left;
      &:before{
        @extend .fa;
        font-size: 1.4rem;
        content: '\f053';
        margin-right: 0.5rem;
      }
      &:hover{
        color: darken($blue,10);
      }
    }
    &.is-next{
      background: $blue;
      color: $white;
      border-radius: $global-border-radius-half;
      float: right;
      &:after{
        @extend .fa;
        font-size: 1.4rem;
        content: '\f054';
        margin-left: 0.5rem;
      }
      &:hover{
        background: darken($blue,10);
      }
    }
  }

  @include media($xs-down){
    .step{
      position: relative;
      width: 100%;
      text-align: center;
      &.is-previous{
        top: 48px;
        float: right;
      }
      &.is-next{
        top: -38px;
        float: left;
      }
      &.is-single{
        top: 0;
        float: none;
        width: 100%;
        display: inline-block;
      }
    }
  }
}
