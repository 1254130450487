.is-gsm {
  .offer-contract-provider-choice {
    background: $blue;
    color: $white;
    min-height: 1.2rem;
    .intro {
      font-size: 2rem;
      font-weight: 300;
      line-height: 4rem;
      display: inline-block;
      margin-right: 1.5rem;
    }
    .contract-providers {
      display: inline-block;
      a {
        background: lighten($blue, 10);
        color: $white;
        display: inline-block;
        font-size: 1.6rem;
        padding: 1rem;
        border-radius: $global-border-radius-half $global-border-radius-half 0 0;
        margin: 0 0.5rem 0 0;
        &:hover {
          background: darken($blue, 20);
        }
        &:last-child {
          margin-right: 0;
        }
        &.is-active {
          background: $light-blue-grey;
          color: darken($blue, 30);
        }
      }
      h2 {
        font-weight: 500;
      }
    }

    @include media($xs) {
      .contract-providers {
        a {
          font-size: 2rem;
          padding: 1.25rem 2rem 1.25rem 2rem;
        }
      }
    }
    @include media($sm) {
      .contract-providers {
        a {
          margin-top: 1rem;
        }
      }
    }
    @include media($md) {
    }
    @include media($lg) {
    }
    @include media($xl) {
    }
  }
}