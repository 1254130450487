.order-service{
  .is-service{
    border: 2px solid $yellow;
    border-radius: $global-border-radius-half;
    padding: 1.5rem;
    font-size: 1.4rem;
    h4{
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }
}
