@mixin tooltip($direction, $bgcolor, $border-size, $border-radius) {
  /*style button as link*/
  background:none !important;
  border:none;
  outline: 0;
  padding:0 !important;

  position: relative;
  display: inline-block;

  .tooltip-content{
    width: 300px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
    transition: 250ms ease-in-out;

    background-color: $bgcolor;
    border-radius: $border-radius;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
    padding: 5px 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
    &:before {
      position: absolute;
      content: '';
      border: $border-size solid transparent;
      z-index: 1000001;
    }
    &:hover{
      background: red;
    }
  }

  &:hover ,&:focus{
    cursor: pointer;
    text-decoration: underline;
    .tooltip-content{
      visibility: visible;
      opacity: 1;
    }
  }

  @if $direction == 'top' {
    .tooltip-content{
      bottom: 150%;
      left: 50%;
      transform: translateX(-50%);
      &:before{
        border-top-color: $bgcolor;
        top: 100%;
        //tooltip padding compensation
        margin-left: $border-size * -1;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        bottom: 130%;
      }
    }
  }

  @else if $direction == 'top-right' {
    .tooltip-content{
      bottom: 150%;
      left: 0;
      &:before{
        border-top-color: $bgcolor;
        top: 100%;
        left: 5px;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        bottom: 130%;
      }
    }
  }

  @else if $direction == 'right' {
    .tooltip-content{
      top: 50%;
      transform: translateY(-50%);
      left: 150%;
      &:before{
        top: 50%;
        transform: translateY(-50%);
        border-right-color: $bgcolor;
        right: 100%;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        left: 130%;
      }
    }
  }

  @else if $direction == 'bottom-right' {
    .tooltip-content{
      top: 150%;
      left: 0;
      &:before{
        border-bottom-color: $bgcolor;
        bottom: 100%;
        left: 5px;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        top: 130%;
      }
    }
  }

  @else if $direction == 'bottom' {
    .tooltip-content{
      top: 150%;
      left: 50%;
      transform: translateX(-50%);
      &:before{
        border-bottom-color: $bgcolor;
        bottom: 100%;
        //tooltip padding compensation
        margin-left: $border-size * -1;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        top: 130%;
      }
    }
  }

  @else if $direction == 'bottom-left' {
    .tooltip-content{
      top: 150%;
      right: 0;
      &:before{
        border-bottom-color: $bgcolor;
        bottom: 100%;
        right: 5px;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        top: 130%;
      }
    }
  }

  @else if $direction == 'left' {
    .tooltip-content{
      top: 50%;
      transform: translateY(-50%);
      right: 150%;
      &:before{
        border-left-color: $bgcolor;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        right: 130%;
      }
    }
  }

  @else if $direction == 'top-left' {
    .tooltip-content{
      bottom: 150%;
      right: 0;
      &:before{
        border-top-color: $bgcolor;
        top: 100%;
        right: 5px;
      }
    }

    &:hover ,&:focus{
      .tooltip-content{
        bottom: 130%;
      }
    }
  }
}

.tooltip-top{
  @include tooltip(top,#333,5px,3px);
}
.tooltip-top-right{
  @include tooltip(top-right,#333,5px,3px);
}
.tooltip-right{
  @include tooltip(right,#333,5px,3px);
}
.tooltip-bottom-right{
  @include tooltip(bottom-right,#333,5px,3px);
}
.tooltip-bottom{
  @include tooltip(bottom,#333,5px,3px);
}
.tooltip-bottom-left{
  @include tooltip(bottom-left,#333,5px,3px);
}
.tooltip-left{
  @include tooltip(left,#333,5px,3px);
}
.tooltip-top-left{
  @include tooltip(top-left,#333,5px,3px);
}

/* mixin use example
.tooltip-top{
  @include tooltip(top,#333,5px,3px);
}
.tooltip-top-right{
  @include tooltip(top-right,#333,5px,3px);
}
.tooltip-right{
  @include tooltip(right,#333,5px,3px);
}
.tooltip-bottom-right{
  @include tooltip(bottom-right,#333,5px,3px);
}
.tooltip-bottom{
  @include tooltip(bottom,#333,5px,3px);
}
.tooltip-bottom-left{
  @include tooltip(bottom-left,#333,5px,3px);
}
.tooltip-left{
  @include tooltip(left,#333,5px,3px);
}
.tooltip-top-left{
  @include tooltip(top-left,#333,5px,3px);
}*/


/* example html
<div>
Top - Lorem ipsum dolor samit
<button class="tooltip-top">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Top Right - Lorem ipsum dolor samit
<button class="tooltip-top-right">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Right - Lorem ipsum dolor samit
<button class="tooltip-right">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Bottom Right - Lorem ipsum dolor samit
<button class="tooltip-bottom-right">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Bottom - Lorem ipsum dolor samit
<button class="tooltip-bottom">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Bottom Left - Lorem ipsum dolor samit
<button class="tooltip-bottom-left">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Left - Lorem ipsum dolor samit
<button class="tooltip-left">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
<div>
Top Left - Lorem ipsum dolor samit
<button class="tooltip-top-left">info
<span class="tooltip-content">
<p>Paragraph number 1</p>
<p>Paragraph number 2</p>
</span>
</button>
consectetur adipiscing elit
</div>
*/
