// TODO restructure to remove redundant rules
// TODO flatten to max 4 levels excluding media queries

//default header styling
header{
  //default styling for both fixed and not fixed
  transition: border $global-transition-duration-short ease-in-out;
  background: $yellow;
  .header-top{
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-top: 3px solid $yellow;
    border-bottom: 3px solid $yellow;
  }
  .branding{
    @include span-columns(24);
    .logo{
      transition: all $global-transition-duration-short ease-in-out;
      float: left;
      img{
        display: block;
        transition: all $global-transition-duration-short ease-in-out;
        width: 62px;
        height: 48px;
        padding-right: 6px;
      }
    }
    .labels{
      transition: all $global-transition-duration-short ease-in-out;
      float: left;
      margin-top: 17px;
      position: relative;
      z-index: 1000;
      .tagline{
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 2px;
      }
      .categories{
        padding: 2px;
        font-size: 1.4rem;
        font-weight: 300;
        &:hover{
          text-decoration: underline;
        }
      }
      .category-menu.is-desktop {
        display: none;
      }
    }
  }
  .service{
    text-align: right;
    margin-top: 6px;
    .service-online{
      display: block;
      margin-bottom: 7px;
      font-size: 1.6rem;
      font-weight: 700;
      .fa{
        font-size: 90%;
      }
    }
    .service-offline{
      font-size: 1.4rem;
      font-weight: 300;
      .call-number{
        font-weight: 700;
      }
    }
  }
  .nav ul ul{
    //force hide 3rd level menus
    //TODO: limit to depth 2 > fix with wordpress navwalker
    display: none !important;
  }

  //default media query styling for both fixed and not fixed
  @include media($xs){}
  @include media($mobile){
    .labels{
      .categories{
        display: none;
      }
    }
    .category-menu.is-desktop {
      display: none !important;
    }
    + .scrollspacer{
      display: none;
      visibility: hidden;
    }
  }
  @include media($sm){
    .service{
      margin-top: 6px;
    }
  }
  @include media($desktop){
    .service-offline a{
      &:hover{
        text-decoration: underline;
      }
    }
  }
  @include media($md){
    .branding{
      @include span-columns(10);
    }
    .category-menu.is-desktop{
      background: $blue;
      color: $white;
      border: 2px solid $white;
      position: absolute;
      margin-top: 3px;
      li{
        height: 36px;
        &:last-child{
          a{
            border-bottom: none;
          }
        }
        &:hover{
          a{
            background: darken($blue, 10);
          }
        }
        &.is-active{
          a{
            background: darken($blue, 20);
          }
        }
      }
      a{
        display: inline-block;
        padding: 0.5rem 2rem 0.5rem 0.5rem;
        width: 170px;
        border-bottom: 1px solid lighten($blue, 10);
      }
      .fa{
        font-size: 3rem;
        text-align: center;
        width: 3rem;
        float: left;
        &.fa-mobile{
          font-size: 3.6rem;
          line-height: 24px;
        }
        &.fa-lightbulb-o{
          font-size: 3rem;
          line-height: 26px;
        }
        &.fa-tv{
          font-size: 2.3rem;
          line-height: 26px;
        }
      }
      .category-name{
        float: left;
        font-weight: 700;
        line-height: 26px;
        padding-left: 6px;
        br{
          display: none;
        }
      }
    }
    .service{
      @include span-columns(14);
      @include omega();
    }
  }
  @include media($lg){}
  @include media($xl){}

  //default styling for not fixed
  &:not(.scroll-to-fixed-fixed){
    @include media($xs){}
    @include media($sm-down){}
    @include media($sm){}
    @include media($md){
      padding: 0.4rem 0;
      .branding{
        .logo{
          img{
            width: 100px;
            height: 76px;
            padding-right: 12px;
          }
        }
        .labels{
          margin-top: 20px;
          .tagline{
            font-size: 2.2rem;
          }
          .categories{
            display: block;
          }
        }
      }
      .service{
        margin-top: 25px;
      }
    }
    @include media($lg){
      padding: 0.8rem 0;
      .branding{
        .logo{
          img{
            width: 120px;
            height: 93px;
          }
        }
        .labels{
          margin-top: 27px;
          .tagline{
            font-size: 2.6rem;
          }
        }
      }
      .service{
        margin-top: 30px;
        .service-online{
          font-size: 2rem;
        }
      }
    }
    @include media($xl){}
  }

  //default styling for fixed
  &.scroll-to-fixed-fixed{
    transition: border 0s ease-in-out;
    border-bottom: 3px solid $black;
    .header-top{
      padding-top: 0;
      padding-bottom: 0;
    }
    .labels{
      .tagline{
        float: left;
      }
      .category-wrapper{
        float: left;
        margin-left: 6px;
        margin-top: 1px;
        .categories{
          &.is-open{
            position: relative;
            top:1px;
          }
        }
      }
    }
    .category-menu.is-desktop{
      display: none;
    }
    @include media($xs-down){}
    @include media($xs){}
    @include media($sm){}
    @include media($md){
      .category-menu.is-desktop{
        li{
          height: 30px;
        }
        a{
          padding: 0.5rem 2rem 0.5rem 0.5rem;
        }
        .fa{
          width: 2.6rem;
          &.fa-mobile{
            font-size: 3rem;
            line-height: 19px;
          }
          &.fa-lightbulb-o{
            font-size: 2.4rem;
            line-height: 20px;
          }
          &.fa-tv{
            font-size: 2rem;
            line-height: 21px;
          }
        }
        .category-name{
          line-height: 20px;
          font-size: 1.5rem;
        }
      }
    }
    @include media($lg){}
    @include media($xl){}
  }
}

//homepage header styling
body.is-miky{
  header {
    //homepage styling for both fixed and not fixed
    .labels {
      .tagline {
        &.is-label {
          display: none;
        }
      }
    }
    .category-menu.is-mobile {
      background: $blue;
      color: $white;
      .trigger {
        height: 30px;
        @include gutter-all('padding-left',1.2);
        @include gutter-all('padding-right',1.2);
        display: inline-block;
        position: relative;
        width: 100%;
        line-height: 30px;
        font-size: 1.4rem;
        font-weight: 300;
        .fa {
          font-size: 1.3rem;
        }
      }
      .menu-category{
        @include outer-container();
        @include gutter-all('margin-left',1.2);
        @include gutter-all('margin-right',1.2);
        @include gutter-all('padding-top',1.2);
        @include gutter-all('padding-bottom',1.2);
        display: none;
        border-top: 1px solid $white;
        li{
          @include span-columns(8);
        }
        a{
          @include gutter-all('padding',1.2);
          background: $white;
          color: $blue;
          border-radius: $global-border-radius;
          display: block;
          text-align: center;
          &:hover{
            background: darken($blue, 20);
            color: $white;
          }
        }
        .is-gsm{
          .fa{
            font-size: 10.6rem;
            line-height: 7.6rem;
            margin-bottom: 0.4rem;
          }
        }
        .is-energy{
          .fa{
            font-size: 8.8rem;
            line-height: 7.8rem;
            margin-bottom: 0.2rem;
          }
        }
        .is-itv{
          .fa{
            font-size: 6rem;
          }
        }
        .category-name{
          font-size: 2rem;
          font-weight: 700;
        }
      }
    }
    //homepage media query styling
    @include media($xxs-down) {
      .branding{
        @include span-columns(15);
        .labels{
          .tagline{
            font-size: 1.7rem;
          }
        }
      }
      .service{
        @include span-columns(9);
        @include omega();
        .service-online{
          font-size: 1.5rem;
          margin-top: 1.3rem;
        }
        .service-offline{
          display: none;
        }
      }
    }
    @include media($xs) {
      .category-menu.is-mobile {
        .menu-category{
          li{
            @include span-columns(6);
            &:first-child{
              @include shift(3);
            }
          }
        }
      }
      .branding{
        @include span-columns(12);
      }
      .service{
        @include span-columns(12);
        @include omega();
      }
    }
    @include media($sm) {
      .branding{
        @include span-columns(10);
      }
      .service{
        @include span-columns(14);
        @include omega();
      }
    }
    @include media($md) {
      .category-menu.is-mobile {
        display: none;
      }
      .branding{
        @include span-columns(14);
      }
      .service{
        @include span-columns(10);
        @include omega();
      }
    }
    @include media($lg) {
    }
    @include media($xl) {
    }

    //homepage styling for not fixed
    &:not(.scroll-to-fixed-fixed) {
      @include media($xs) {
      }
      @include media($sm-down) {
      }
      @include media($sm) {
      }
      @include media($md) {
      }
      @include media($lg) {
      }
      @include media($xl) {
      }
    }
    //homepage styling for fixed
    &.scroll-to-fixed-fixed {
      .header-top {
        border-top: 3px solid $yellow;
      }
    }
  }
}

//All except homepage styling
body:not(.is-miky){
  header {
    //All except homepage styling for both fixed and not fixed
    //All except homepage media query styling
    nav{
      &.is-label-menu{
        .nav {
          > li:first-child > a:before {
            @extend .fa;
            font-size: 110%;
            content: "\f015";
            padding-right: 4px;
          }
        }
      }
      .nav {
        @extend .container-desktop-max;
        .sub-menu {
          z-index: 10;
          background: $white;
          color: $blue;
          li {
            &:hover {
              a {
                background: $lightest-grey;
              }
            }
            &.is-current {
              a {
                background: $white;
                font-weight: 700;
              }
            }
          }
        }
      }
      &.is-menu-bar{
        ul:not(.sub-menu){
          > li {
            &.is-current {
              > a {
                background: darken($blue, 20);
              }
              &:hover{
                > a{
                  background: darken($blue, 20);
                }
              }
            }
            &:hover{
              > a{
                background: darken($blue, 10);
              }
            }
            a{
              transition: all $global-transition-duration-short ease-in-out;
            }
          }
        }
      }
    }
    &.is-normal{
      nav{
        &.is-menu-bar{
          ul:not(.sub-menu){
            > li {
              &:not(:first-child).is-active {
                > a {
                  background: darken($blue, 20);
                }
                &:hover{
                  > a{
                    background: darken($blue, 20);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.is-order{
      nav{
        &.is-menu-bar{
          ul{
            overflow: hidden;
          }
          li{
            position: relative;
            a{
              padding-left: 1rem;
              padding-right: 1rem;
              &:before{
                margin-right: 0.5rem;
              }
            }
            &:not(:first-child){
              a{
                padding-left: 2rem;
              }
            }
            &:not(:last-child){
              &:before,&:after{
                position: absolute;
                content: '';
                border-style: solid;
                top: -1px;
                left: 100%;
              }
              &:before{
                border-width: 2.4rem 0 2.4rem 1.2rem;
                border-color: transparent transparent transparent $white;
                margin-top: -4px;
              }
              &:after{
                border-width: 2rem 0 2rem 1rem;
                border-color: transparent transparent transparent $blue;
                transition: all $global-transition-duration-short ease-in-out;
              }
              &:hover{
                &:after{
                  border-color: transparent transparent transparent darken($blue,10);
                }
              }
              &.is-current{
                &:after{
                  border-color: transparent transparent transparent darken($blue,20);
                }
              }
            }
            &:nth-child(1){
              z-index: 13;
              a{
                &:before{
                  content: '1.';
                }
              }
            }
            &:nth-child(2){
              z-index: 12;
              a{
                &:before{
                  content: '2.';
                }
              }
            }
            &:nth-child(3){
              z-index: 11;
              a{
                &:before{
                  content: '3.';
                }
              }
            }
            &:nth-child(4){
              z-index: 10;
              a{
                &:before{
                  content: '4.';
                }
              }
            }
          }
        }
      }
    }
    @include media($xs) {
    }
    @include media($xs-down){
       .branding {
         @include span-columns(18);
         .labels {
           margin-top: 8px;
           .tagline {
             float: none;
             &.is-label {
               font-size: 1.6rem;
               font-weight: 500;
             }
           }
         }
       }
      .menu{
        @include span-columns(6);
      }
      &.is-order{
        nav{
          &.is-menu-bar{
            li{
              a{
                padding: 1.2rem;
              }
              &:not(.is-current){
                a{
                  font-size: 1px;
                  letter-spacing: -1px;
                  color: transparent;
                  &:before{
                    font-size: 1.5rem; /* Maintain relative font-size in browsers that support it */
                    letter-spacing: normal;
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
     }
    @include media($mobile) {
      background: none;
      .main{
        background: $yellow;
      }
      .header-top{
      }
      .branding {
        .labels {
        }
        .category-menu.is-desktop{
          display: none;
        }
      }
      .menu {
        text-align: right;
        .trigger {
          background: $blue;
          color: $white;
          border-radius: $global-border-radius / 2;
          padding: 0.8rem 1.5rem;
          position: relative;
          top: 1.6rem;
          font-weight: 700;
          &:hover{
            background: darken($blue,10);
          }
          &.is-active{
            background: darken($blue,20);
          }
        }
      }
      .service{
        display: none;
      }
      .menu-wrapper{
        display: none;
        color: $white;
      }
      nav{
        li.has-children{
          > a{
            position: relative;
            &:after{
              position: absolute;
              right: 1rem;
              @extend .fa;
              content: '\f067';
              font-size: 110%;
            }
          }
          &.is-open{
            > a{
              background: darken($blue,10);
              &:after{
                content: "\f068";
              }
            }
          }
        }
        &.is-menu-bar{
          background: $white;
          .nav{
            padding: 0;
            background: $blue;
          }
          li{
            display: inline-block;
          }
          a{
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            @include gutter-all('padding-left');
            @include gutter-all('padding-right');
            display: inline-block;
            width: 100%;
            &:hover{
            }
          }
          > li{
            margin: 0 .5rem;
          }
          .sub-menu{
            display: none;
          }
          .sub-menu li{
            a{
              @include gutter-all('padding-left', 2)
            }
          }
          .sub-menu .sub-menu{
            // never show sub-sub-menus
            // overwrite javascript (see assets/scripts/routing/common.js)
            display: none !important;
          }
        }
        &.is-service-menu{
          background: $black;
          color: $lightest-grey;
          .wrapper{
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            @include gutter-all('margin-left');
            @include gutter-all('margin-right');
            border-bottom: 1px solid $medium-grey;
          }
          .service-online{
            margin-bottom: 1rem;
            display: block;
            font-size: 1.8rem;
            font-weight: 700;
            .fa{
              color: $white;
            }
          }
          .service-offline{
            .call-number{
              color: $white;
              font-weight: 700;
            }
            > a, > span {
              padding: 0.7rem 0;
            }
          }
        }
        &.is-category-menu{
          background: $black;
          color: $lightest-grey;
          .wrapper{
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            @include gutter-all('margin-left');
            @include gutter-all('margin-right');
          }
          .labels-other-header{
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
            color: $white;
          }
          a{
            padding: 0.5rem 0;
            display: block;
            .fa{
              color: $yellow;
            }
          }
        }
      }
      &.is-normal{
        nav{
          &.is-menu-bar{
            li{
              width: 100%;
            }
            > ul > li{
              border-top: 1px solid lighten($blue,10);
              &:first-child{
                border: none;
              }
            }
          }
        }
      }
      &.is-order{
        .menu-wrapper{
          display: block;
        }
        nav{
          &.is-menu-bar{
            li{
              float: left;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    @include media($sm-only){
      .branding {
        @include span-columns(20);
        .labels {
          .tagline {
            &.is-slogan{
              float: right;
              margin-top: 1px;
            }
            &.is-label {
              float: left;
              font-size: 2.2rem;
              margin-top: -2px;
              margin-right: 6px;
            }
          }
        }
      }
      .menu{
        @include span-columns(4);
      }
    }
    @include media($sm) {
    }
    @include media($md) {
      .branding {
        .labels {
          .tagline {
            &.is-label {
              display: none;
            }
          }
        }
      }
      .menu {
        display: none;
      }
      .menu-wrapper{
        // overwrite javascript (see assets/scripts/routing/common.js)
        display: block !important;
        transition: font-size 0.3s ease-in-out;
        .nav{
          .sub-menu{
            width: 20rem;
            position: absolute;
            display: none;
            border: 3px solid darken($blue, 10);
            border-top: none;
            li{
              float: left;
              width: 100%;
              a{
                display: block;
              }
            }
          }
          li.is-active{
            .sub-menu{
              border-color: darken($blue, 20);
            }
          }

          li:hover > .sub-menu{
            display: block;
          }
        }
        nav.is-menu-bar{
          > ul{
            &.nav{
              background: $blue;
              color: $white;
            }
            > li{
              float: left;
              position: relative;
              > a{
                display: inline-block;
              }
              &:hover{
                 > a{
                   background: darken($blue,10);
                 }
              }
              &:not(:first-child).is-active{
                > a{
                  background: darken($blue,20);
                }
                &:hover{
                  > a{
                    background: darken($blue,20);
                  }
                }
              }
            }
          }
        }
        nav.is-label-menu{
          > ul{
            > li{
              &.has-children{
                > a:after{
                  @extend .fa;
                  content: "\f107";
                  padding-left: 6px;
                  position: relative;
                  top: 1px;
                }
              }
            }
          }
        }
        nav.is-service-menu{
          display: none;
        }
        nav.is-category-menu{
          display: none;
        }
      }
    }
    @include media($lg) {
    }
    @include media($xl) {
    }

    //All except homepage styling for not fixed
    &:not(.scroll-to-fixed-fixed) {
      @include media($xs){}
      @include media($sm){}
      @include media($md){
        margin-bottom: 1.4rem;
        .menu-wrapper{
          nav.is-menu-bar{
            height: 1.5rem;
            position: relative;
            top: 3px;
            > ul{
              height: 3rem;
              li{
                a{
                  height: 3rem;
                  padding: 0.5rem 1rem;
                  line-height: 2rem;
                }
              }
            }
          }
        }
        &.is-order{
          .menu-wrapper{
            nav.is-menu-bar{
              > ul{
                li{
                  &:not(:first-child){
                    a{
                      padding-left: 2rem;
                    }
                  }
                  &:not(:last-child){
                    &:before,&:after{
                      top: -5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      @include media($lg){
        .menu-wrapper{
          nav.is-menu-bar{
            top: 7px;
          }
        }
      }
      @include media($xl){}
    }
    //All except homepage styling for fixed
    &.scroll-to-fixed-fixed {
      @include media($xs){}
      @include media($sm){}
      @include media($md){
        .header-top{
          border-top: 6px solid #fb0;
          border-bottom: 6px solid #fb0;
        }
        .menu-wrapper{
          background: $blue;
          nav{
            .sub-menu{
              border-top: 3px solid darken($blue,10);
            }
          }
          nav.is-menu-bar{
            ul{
              li{
                a{
                  height: 2.4rem;
                  font-size: 1.4rem;
                  padding: 0.2rem 1rem;
                  line-height: 2.2rem;
                }
              }
            }
          }
        }
        &.is-order{
          .menu-wrapper{
            nav.is-menu-bar{
              > ul{
                li{
                  &:not(:first-child){
                    a{
                      padding-left: 2rem;
                    }
                  }
                  &:not(:last-child){
                    &:before,&:after{
                      top: -7px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      @include media($lg){}
      @include media($xl){}
    }
  }
}

// Label frontpage styling
// TODO incorporate in default styling
body.template_front-page{
  header{
    .menu-wrapper{
      nav{
        &.is-menu-bar{
          > ul{
            > li{
              &.is-active{
                > a{
                  background: darken($blue,20);
                }
                &:hover{
                  > a{
                    background: darken($blue,20);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
