.is-gsm {
  .offer-receipt {
    h2 {
      position: relative;
      background: $yellow;
      //text-align: center;
      padding: 0.8rem 1rem;
      font-size: 2rem;
      border-bottom: 2px solid $yellow;
      .config-trigger {
        position: absolute;
        font-size: 1.4rem;
        font-weight: 500;
        right: 1rem;
        top: 1.4rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .receipt {
      &.is-desktop {
        .receipt-content {
          font-size: 1.3rem;
          background: $yellow;
          border: 2px solid $yellow;
          border-radius: $global-border-radius-half;
        }
      }
      &.is-mobile + .scrollspacer {
        display: none !important;
      }
    }

    .receipt-offer {
      @include outer-container();
      background: $white;
      border-radius: $global-border-radius-half $global-border-radius-half 0 0;
      padding: 1rem 1rem 1rem 0.8rem;
      .img-wrapper {
        @include span-columns(6);
      }
      .offer-info {
        @include span-columns(18);
        padding-left: 0.2rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        .phone-info {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }
        .contract-type {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-bottom: 0.5rem;

        }
        .contract-data,
        .contract-call,
        .contract-sms {
          font-size: 1.4rem;
        }
      }
    }
    .is-summary {
      user-select: none;
      cursor: pointer;
      background: $lightest-grey;
      padding: 1rem;
      &:hover {
        background: darken($lightest-grey, 5);
      }
      .trigger-cta {
        color: $blue;
        &:after {
          @extend .fa;
          content: '\f0d7';
          margin-left: 0.5rem;
          position: relative;
          top: 1px;
        }
      }
      &.is-open {
        .trigger-cta {
          &:after {
            content: '\f0d8';
            top: 0;
          }
        }
      }
      > .is-row {
        @include outer-container();
        > .name {
          @include span-columns(10);
          font-size: 1.6rem;
          margin-bottom: 2px;
        }
        > .price {
          @include span-columns(14);
          font-size: 2.2rem;
          height: 1.8rem;
          font-weight: 700;
          text-align: right;
          position: relative;
          top: 5px;
        }
      }
    }
    .is-expanded {
      .trigger-cta {
        color: $medium-grey;
      }
    }
    .choice-wrapper {
      &:not(:last-child) {
        padding-bottom: 0.7rem;
        border-bottom: 1px solid $lightest-grey;
        margin-bottom: 0.7rem;
      }
      .is-row {
        @include outer-container();
        margin: 0.1rem 0;
        padding: 0.3rem 1rem;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .name {
          @include span-columns(17);
        }
        .price {
          @include span-columns(7);
          text-align: right;
        }
        &.add-on-empty {
          color: $medium-grey;
        }
        &.is-discount {
          color: $green;
        }
        &.is-total {
          font-weight: 700;
        }
      }
    }

    .receipt-monthly {
      background: $white;
      padding-bottom: 1rem;
      .is-detailed {
        padding: 1rem 0 0 0;
      }
    }
    .receipt-once {
      background: $white;
      .is-detailed {
        padding: 0.7rem 0;
        border-bottom: 1px solid $lightest-grey;
      }
    }
    .receipt-order {
      background: $white;
      border-radius: 0 0 $global-border-radius-fourth $global-border-radius-fourth;
      padding: 1rem;
      button {
        width: 100%;
        padding: 1rem;
        font-size: 2rem;
        background: $blue;
        color: $white;
        border-radius: $global-border-radius-half;
        &:not(.loading):hover {
          background: darken($blue, 10);
        }
        &:not(.loading):focus {
          background: darken($blue, 20);
        }
        .wait{
          color: $white;
          font-size: 1.4rem;
          position: relative;
          top: -3px;
          left: -15px;
          .loader-icon {
            position: absolute;
            top: -1px;
            margin-left: -5px;
            width: 20px;
            height: 20px;
            background: url('#{$image-path}/global/loaders/miky-loader-tiny-inverted.gif') no-repeat center center;
          }
        }
        .order{
          display: none;
        }
      }
      .config-trigger {
        display: block;
        text-align: center;
        background: $medium-dark-grey;
        color: $white;
        box-sizing: border-box;
        width: 90%;
        margin: 1rem 5% 0 5%;
        padding: 0.5rem;
        font-size: 1.6rem;
        font-weight: 300;
        border-radius: $global-border-radius-half;
        &:hover {
          background: $dark-grey;
        }
        &:focus {
          background: $black;
        }
      }
      .offer-status {
        text-align: center;
        line-height: 1.6rem;
        margin: 1rem 0 0 0;
      }
      .legal {
        font-size: 1rem;
        line-height: 1.2;
        text-align: center;
        margin-top: 0.5rem;
        color: $medium-grey;
      }
      .offer-guide {
        position: absolute;
        width: 100%;
        margin-left: -1rem;
        margin-top: 2rem;
        a {
          width: 75%;
          padding: 0.6rem;
          font-size: 1.2rem;
          background: $dark-grey;
          color: $white;
          border-radius: 4px;
          text-align: center;
          margin: 0.5em auto;
          display: none;
          &:hover {
            background: $blue;
          }
          &.is-active {
            background: $green;
            display: block;
          }
        }
      }
    }

    @include media($mobile) {
      @include span-columns(24);
      padding-bottom: 3rem;
      .receipt.is-desktop {
        @extend .container-mobile;
      }
      .receipt.is-mobile {
        .receipt-details-wrapper {
          padding: 0.6rem 0 0.7rem 0;
          display: block;
          background: $dark-grey;
          color: $white;
          &:hover {
            background: darken($dark-grey, 10);
          }
          .receipt-details {
            .receipt-details-trigger {
              font-size: 1.2rem;
              &:after {
                @extend .fa;
                content: '\f054';
                font-size: 1rem;
                margin-left: 0.25rem;
              }
            }
          }
        }
        .receipt-summary-wrapper {
          background: $yellow;
          padding: 1rem 0;
          font-weight: 700;
          .receipt-summary {
          }
          .contract-monthly-total {
            transition: all .2s ease-in-out;
            @include span-columns(8);
            padding: 0.2rem 0;
          }
          .contract-once-total {
            transition: all .2s ease-in-out;
            @include span-columns(8);
            padding: 0.2rem 0;
          }
          .order-wrapper {
            @include span-columns(8);
            text-align: right;
          }
          .price {
            font-size: 1.8rem;
          }
          .type {
            font-size: 1.4rem;
            font-weight: 500;
          }
          button {
            background: $blue;
            color: $white;
            font-size: 1.8rem;
            padding: 1rem 2rem;
            border-radius: $global-border-radius-half;
            &:hover {
              background: darken($blue, 10);
            }
          }
        }
      }

      .is-highlight {
        transform: scale(1.1);
      }
    }
    @include media($xxs) {
      .receipt.is-desktop {

      }
    }
    @include media($xs-only) {
      .receipt.is-desktop .receipt-content {
        @include span-columns(18);
        @include shift(3);
        font-size: 1.3rem;
        .img-wrapper {
          @include span-columns(5);
        }
        .offer-info {
          @include span-columns(19);
          font-size: 1.7rem;
        }
        .phone-info {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .contract-duration {
          margin-bottom: 1rem;
        }
        .is-summary > .is-row {
          > .name {
            font-size: 1.8rem;
          }
          > .price {
            font-size: 2.4rem;
            top: 5px;
          }
        }
      }
      .receipt.is-mobile {
        button {
          padding: 1rem 3rem;
        }
      }
    }
    @include media($sm-only) {
      .receipt.is-desktop .receipt-content {
        @include span-columns(14);
        @include shift(5);
        font-size: 1.3rem;
        .img-wrapper {
          @include span-columns(5);
        }
        .offer-info {
          @include span-columns(19);
          font-size: 1.7rem;
        }
        .phone-info {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .contract-duration {
          margin-bottom: 1rem;
        }
        .is-summary > .is-row {
          > .name {
            font-size: 1.8rem;
          }
          > .price {
            font-size: 2.4rem;
            top: 5px;
          }
        }
      }
      .receipt.is-mobile {
        .receipt-summary-wrapper {
          .contract-monthly-total {
            padding: 1rem 0;
          }
          .contract-once-total {
            padding: 1rem 0;
          }
          button {
            padding: 1rem 4rem;
          }
        }
      }
    }
    @include media($desktop) {
      @extend .container-desktop;
      padding-right: 0;
      display: block;
      .receipt.is-desktop {
        float: none !important;
        &:not(.scroll-to-fixed-fixed) {
          position: relative !important;
        }
      }
      .receipt-content {
        position: absolute;
        width: 100%;
      }
      .contract-once-total {
        .price {
          transition: font .2s ease-in-out;
        }
      }
      .contract-month-total {
        .price {
          transition: font .2s ease-in-out;
        }
      }
      .is-highlight {
        &.is-row {
          color: $blue;
        }
        &.is-summary {
          background: #F9F1DA;
          .price {
            transform: scale(1.05);
          }
        }
      }
    }
    @include media($md-only) {
      .receipt.is-desktop {
        @include span-columns(9);
        @include shift(15);
        @include omega();
      }
    }
    @include media($lg-only) {
      .receipt.is-desktop {
        @include span-columns(7);
        @include shift(17);
        @include omega();
      }
    }
    @include media($xl-only) {
      .receipt.is-desktop {
        @include span-columns(6);
        @include shift(18);
        @include omega();
      }
    }
  }
}
