
/* user-defined settings */

label{
  &.inline-label{
    &.supported{
      /* don't change any of this! */
      position: absolute;
      cursor: text;
      visibility: hidden;
      overflow: hidden;
      background: lighten($dark-grey,10);
      color: $white;
      line-height: 2.8rem;
      padding: 0 0.5rem;
      border: 1px solid lighten($dark-grey,10);
      &.focus{
        background: none;
        border-color: transparent;
        color: $light-grey;
      }
      &.empty{
        visibility: visible !important;
      }
    }
  }
}
