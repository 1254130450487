.template_front.is-gsm{
    h1{
        font-size: 2.6rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }
    @include media($md){
        section.hero{
            border-top: 20px solid transparent;
            margin-top: -14px;
        }
    }
}
