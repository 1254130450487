.is-gsm {
  .offer-phone-insurance {

  }
  //TODO fix overwrites for offer sections
  @include media($md-down) {
    .template_offer .offer-main .offer-phone-insurance.is-section .is-small button {
      &:first-child {
        @include span-columns(24);
        @include omega();
      }
      &:not(:first-child) {
        @include omega-reset(2n);
        @include omega(2n+1);
      }
    }
  }
  @include media($xxs-only) {
    .template_offer .offer-main .offer-phone-insurance.is-section .is-small button {
      @include span-columns(24);
      @include omega();
    }
  }
}