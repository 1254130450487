.label-choice{
  background: $blue;
  color: $white;
  .wrapper{
    @extend .container;
    > h2{
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }
  .tile{
    display: table;
    @include gutter-all("margin-bottom");
    transition: all $global-transition-duration-short ease-in-out;
    background: $white;
    color: $blue;
    &:hover{
      background: darken($blue,20);
      color: $white;
    }
    &:last-child{
      margin-bottom: 0;
    }
    .fa{
      display: table-cell;
      vertical-align: middle;
      font-size: 6rem;
      line-height: 6rem;
      width: 9rem;
      text-align: center;
      padding-right: 1.5rem;
    }
    &.is-gsm{
      .fa{
        font-size: 8rem;
      }
    }
    .description{
      display: table-cell;
      vertical-align: middle;
    }
    h2{
      font-size: 1.8rem;
      margin-bottom: 0.4rem;
    }
    p{
      font-size: 1.4rem;
    }
  }
}

body.is-miky .label-choice{
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .tile{
      @include span-columns(8);
      margin-bottom: 0;
      .fa{
        float: left;
        width: 100%;
      }
      .description{
        br{
          display: block;
        }
      }
    }
  }
  @include media($lg){
    .tile{
      display: table;
      .fa{
        float: none;
        width: 8rem;
      }
    }
  }
  @include media($xl){
    .tile{
      .description{
        br{
          display: none;
        }
      }
    }
  }
}

body:not(.is-miky) .label-choice{
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .tile {
      @include span-columns(12);
      display: table;
      margin-bottom: 0;
    }
  }
  @include media($lg){}
  @include media($xl){}
}