.is-gsm {
  .offer-service {
    background: $blue;
    color: $white;
    .title-wrapper {
      position: relative;
      padding-left: 7rem;
      .fa-stack {
        position: absolute;
        left: 0;
        top: -10px;
        .fa-circle {
          color: $white;
          + .fa {
            color: $blue;
          }
        }
        .fa-support {
          position: absolute;
          height: 40px;
          top: 10px;
          left: 12px;
        }
      }
    }
  }
}