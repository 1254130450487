footer{
  background: #222;
  > .container{
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: $yellow;
  }
  .is-menu{
    margin-bottom: 4rem;
    h4{
      margin-bottom: 2rem;
      font-size: 1.8rem;
    }
    a{
      display: block;
      position: relative;
      margin-top: 2rem;
      font-size: 1.5rem;
      font-weight: 300;
      &:after{
        @extend .fa;
        content: '\f054';
      }
    }
    .fa{
      margin-left: 5px;
    }
  }
  .social{
    .wrapper{
      @include outer-container();
    }
    h4{
      margin-bottom: 0.3rem;
    }
    li {
      @include span-columns(12);
      @include omega(2n);
    }
    a{
      margin-right: 2rem;
      padding-left: 25px;
      position: relative;
      &:after{
        display: none;
      }
    }
    .fa{
      font-size: 2.2rem;
      position: absolute;
      margin-left: 0;
      left: 0;
      top: -5px;
    }
  }
  .review{
    @include span-columns(24);
    h4{
      font-size: 1.8rem;
      display: inline;
    }
    .counter{
      font-size: 1.6rem;
      font-weight: 300;
      display: inline;
      white-space: nowrap;
    }
  }
  @include media($xs-down){
    .review{
      line-height: 1.3;
    }
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){}
  @include media($lg){}
  @include media($xl){}
}

// Footer styling for Miky
body.is-miky{
  footer{
    @include media($xs){
      .is-menu{
        @include span-columns(12);
        @include omega(2n);
      }
      .social{
        margin-bottom: 2rem;
        li{
          @include span-columns(24);
        }
      }
    }
    @include media($sm){}
    @include media($md){
      .is-menu{
        @include span-columns(6);
        @include omega-reset(2n);
        @include omega(4n);
      }
    }
    @include media($lg){}
    @include media($xl){}
  }
}

// Footer styling for labels
body:not(.is-miky){
  footer{
    .about{
      margin-bottom: 3rem;
    }
    @include media($xs){
      .wrapper.is-menu{
        @include outer-container();
        > div{
          @include span-columns(12);
        }
      }
      .about{
        margin-bottom: 0;
      }
    }
    @include media($sm){
      .is-menu{
        @include span-columns(12);
        @include omega(2n);
      }
      .social{
        @include span-columns(24);
      }
      .wrapper.is-menu{
        @include span-columns(24);
      }
    }
    @include media($md){
      .social{
        @include span-columns(12);
      }
    }
    @include media($lg){
      .is-menu{
        @include span-columns(8);
        @include omega-reset(2n);
        @include omega(3n);
      }
      .wrapper.is-menu{
        @include span-columns(8);
        @include omega();
        > div{
          @include span-columns(24);
        }
      }
      .about{
        margin-bottom: 5.4rem;
      }
      .review{
        @include span-columns(16);
      }
    }
    @include media($xl){
      .is-menu{
        @include span-columns(6);
        @include omega-reset(3n);
        @include omega(4n);
      }
      .wrapper.is-menu {
        @include span-columns(6);
      }
      .social{
        margin-bottom: 2rem;
        li{
          @include span-columns(24);
        }
      }
    }
  }
}