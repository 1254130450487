.tile.is-gsm.is-no-contract {
  .subtitle{
    color: $medium-grey;
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
  }
  .price-once{
    font-size: 4.2rem;
    font-weight: 700;
    position: relative;
    .cent{
      position: absolute;
      top: 0.2rem;
      font-size: 2.4rem;
    }
  }
  .specs{
    font-size: 1.5rem;
    li{
      margin-bottom: 0.5rem;
      &:before{
        @extend .fa;
        content: "\f00c";
        margin-right: 0.25rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  @include media($xxs-only){
  }

  @include media($xs-only){
  }

  @include media($sm-only){
    .price-once{
      font-size: 3.6rem;
      .cent{
        top: 0.1rem;
      }
    }

    .specs {
      font-size: 1.4rem;
    }

    &.is-sm-full{

    }
  }

  @include media($md-only){
    .specs {
      font-size: 1.4rem;
    }

    &.is-md-third{
    }
  }

  @include media($lg-only){
    &.is-lg-half{

    }
  }

  @include media($xl-only){
    .price-once{
      font-size: 4rem;
    }

    &.is-xl-third{

    }
    &.is-xl-half{

    }
  }

  // is not hero
  &.is-normal{
    .specs {
      margin: 1rem 0 1.5rem 0;
    }
    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){}
    @include media($lg-only){}
    @include media($xl-only){}
  }

  // is hero
  &.is-hero{
    .specs {
      margin: 0 0 1.5rem 0;
    }
    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){}
    @include media($lg-only){}
    @include media($xl-only){}
  }

  // is featured
  &.is-featured{
    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){}
    @include media($lg-only){}
    @include media($xl-only){}
  }
}