.service-label{
  h3{
    font-size: 1.8rem;
    font-weight: 500;
    margin: 3rem 0 2rem 0;
  }
  .service-pages{
    margin: 3rem 0 2rem 0;
    .section-wrapper{
      border-bottom: 2px solid $white;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    ul{
      @include clearfix;
    }
    li{
      a{
        margin-bottom: 1rem;
        display: block;
        padding: 1rem;
        border-radius: $global-border-radius-half;
        background: $blue;
        color: $white;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        text-align: center;
        &:hover{
          background: darken($blue,10);
        }
      }
    }
    a.section-cta{
      font-size: 1.4rem;
      font-weight: 700;
      margin-top: 1rem;
      &:after{
        @extend .fa;
        content: '\f054';
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }
      &:hover{
        color: darken($blue,10);
      }
    }
    h4{
      font-size: 2rem;
      font-weight: 500;
      margin: 0 0 1rem 0;
    }
  }
  .overview-cta{
    display: block;
    margin-top: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: $blue;
    &:hover{
      color: darken($blue,10);
    }
  }
  &.is-gsm{
    background: $yellow;
  }
  &.is-energy{
    .service-pages{
      .section-wrapper {
        border-color: $light-grey;
      }
    }
  }
  &.is-itv{
    background: $light-grey;
  }
  @include media($xs){
    .section-wrapper ul li{
      @include span-columns(12);
      @include omega(2n);
    }
  }
  @include media($sm){}
  @include media($md){}
  @include media($lg){
    .section-wrapper ul li{
      @include span-columns(6);
      @include omega-reset(2n);
      @include omega(4n);
    }
  }
  @include media($xl){}
}