.template_service-provider{
  .page-content{
    padding: 0;
    h1{

    }
    .entry-content{

    }
    .wrapper{
      padding-top: 3rem;
      padding-bottom: 6rem;
    }
  }
  .callcenter{
    line-height: 1.4;
    a{
      font-weight: 700;
      &.mail-cta{
        text-decoration: underline;
        &:hover{
          color: $blue;
        }
      }
    }
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){}
  @include media($lg){
    .page-content{
      .wrapper{
        background: url("#{$image-path}/global/miky-hero.jpg") no-repeat right bottom;
        background-position-x: 100%;
        background-size: 290px;
      }
      h1,.entry-content{
        @include span-columns(16);
      }
    }
  }
  @include media($xl){
    .page-content{
      .wrapper{
        background-position-x: 82%;
      }
      h1,.entry-content{
        @include span-columns(14);
      }
    }
  }
}
