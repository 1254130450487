.template_order-form-check{
  main{
    @include outer-container();
    padding: 3rem 0 5rem 0;
  }
  .order-form-check{
    section{
      padding-bottom: 2rem;
      border-bottom: 2px solid $light-grey;
      margin-bottom: 2rem;
      &:last-child{
        margin-bottom: 0;
      }
      h2{
        font-size: 2rem;
        cursor: pointer;
        color: $blue;
        &:hover{
          color: darken($blue,10);
        }
        .fa{
          font-size: 1.6rem;
          margin-left: 1rem;
        }
      }
      .section-content{
        margin-top: 1.5rem;
      }
      .is-subsection{
        padding: 0.5rem 0;
      }
      .control-row{
        @include clearfix();
        padding: 0.5rem 0;
        overflow: auto;
        font-size: 1.5rem;
        line-height: 1.2;
      }
      &.overview-short-check{
        border-bottom: none;
      }
    }
  }

  .order-navigation{
    &.is-order{
      margin-top: 2rem;
    }
  }
  .order-service{
    margin-top: 4rem;
  }
  @include media($xs-down){
  }
  @include media($xs-only){
    .order-form-check{
      .is-label{
        @include span-columns(10);
      }
      .is-input{
        @include span-columns(14);
      }
    }
  }
  @include media($sm){
  }
  @include media($sm-only){
    .order-form-check{
      .is-label{
        @include span-columns(10);
      }
      .is-input{
        @include span-columns(14);
      }
    }
  }
  @include media($md-only){
    .order-form-check{
      .is-label{
        @include span-columns(8);
      }
      .is-input{
        @include span-columns(12);
      }
    }
  }
  @include media($lg-only){
    .order-form-check{
      @include span-columns(18);
      .is-label{
        @include span-columns(8);
      }
      .is-input{
        @include span-columns(16);
      }
    }
    .order-service{
      @include span-columns(6);
      margin-top: 4rem;
    }
  }
  @include media($xl-only){
    .order-form-check{
      @include span-columns(18);
      .is-label{
        @include span-columns(6);
      }
      .is-input{
        @include span-columns(12);
      }
    }
    .order-service{
      @include span-columns(6);
    }
  }
}
