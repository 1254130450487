.ribbon-container {
    .ribbon {
        background: $blue;
        color: $white;
    }
    &.is-miky-only{
        .ribbon{

        }
    }
    &.is-topdeal{
        .ribbon{
            background: $yellow;
            color: $black;
        }
    }
    &.is-custom{
        .ribbon{
            background: $yellow;
            color: $black;
        }
    }
    &.is-tmobile-madness,
    &.is-tmobile-go{
        .ribbon{
            background: $t-mobile;
            color: $white;
        }
    }
    &.is-vodafone{
        .ribbon {
            background: $vodafone;
            color: $white;
        }
    }
}

.tile{
    &.is-simonly{
        .ribbon-container{
            .ribbon{
                background: $black;
                color: $white;
            }
            &.is-tele2{
                .ribbon{
                    background: $blue;
                }
            }
        }
    }
}
