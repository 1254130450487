.label-overview{
    .tile{
        &.is-menu{
            background: $blue;
            color: $white;
            border-radius: $global-border-radius;
            .title{
                font-size: 2.2rem;
                margin-bottom: 1.5rem;
            }
            .menu-item{
                a{
                    display: block;
                    padding: 0.75rem 0;
                    margin: 0.25rem 0;
                    font-size: 1.4rem;
                    font-weight: 300;
                    &:hover{
                        text-decoration: underline;
                    }
                    &:after{
                        @extend .fa;
                        content: "\f054";
                        font-size: 80%;
                        padding-left: 3px;
                    }
                }
                &.is-featured{
                    a{
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .offer-wrapper.slick-slider{
        @include gutter-all-mq('margin-bottom', 'xxs');
        @include gutter-all-mq('margin-bottom', 'xs');
        background: $white;
        border-radius: $global-border-radius;
    }
    .offer-wrapper:not(.slick-slider){
        .tile{
            @include gutter-all('margin-bottom');
            background: white;
            border-radius: $global-border-radius;
            &.is-offer{
                display: none;
                &:nth-child(-n+2) {
                    display: block;
                }
            }
        }
    }
    &.is-gsm{
        background: $yellow;
    }
    &.is-energy{
        .offer-wrapper.slick-slider{
            border: 2px solid $light-grey;
        }
        .offer-wrapper:not(.slick-slider){
            .tile.is-offer{
                border: 2px solid $light-grey;
            }
        }
    }
    &.is-itv{
        background: $lightest-grey;
    }
    @include media($xs){}
    @include media($sm){
        .offer-wrapper{
            margin-bottom: 0
        }
        .offer-wrapper{
            @include span-columns(12);
            + .tile.is-menu{
                @include span-columns(12);
            }
        }
        .offer-wrapper:not(.slick-slider){
            @include span-columns(24);
            + .tile.is-menu{
                @include span-columns(24);
            }
            .tile{
                &.is-offer{
                    @include span-columns(12 of 24);
                    @include omega(2n);
                    &:nth-child(n+3) {
                        display: none;
                    }
                }
            }
        }
    }
    @include media($md){
        .offer-wrapper{
            @include span-columns(16);
            + .tile.is-menu{
                @include span-columns(8);
            }
        }
        .offer-wrapper:not(.slick-slider){
            .tile{
                &.is-offer{
                    @include span-columns(8 of 16);
                    @include omega(2n);
                    &:nth-child(n+3) {
                        display: none;
                    }
                }
            }
        }
    }
    @include media($lg){
        .offer-wrapper{
            @include span-columns(16);
            + .tile.is-menu{
                @include span-columns(8);
            }
        }
        .offer-wrapper:not(.slick-slider){
            .tile{
                &.is-offer{
                    @include span-columns(8);
                    @include omega(2n);
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
            }
        }
    }
    @include media($xl){
        .offer-wrapper{
            @include span-columns(18);
            + .tile.is-menu{
                @include span-columns(6);
            }
        }
        .offer-wrapper:not(.slick-slider){
            .tile{
                &.is-offer{
                    @include span-columns(6 of 18);
                    @include omega-reset(2n);
                    @include omega(3n);
                    &:nth-child(-n+3) {
                        display: block;
                    }
                }
            }
        }
    }
}
