#ilt-calculator{
    &.expander{
        margin-top: -2rem;
        margin-bottom: 1rem;
    }
    .is-label{
        .expander{
            margin-top: 0;
        }
    }
    #ilt-response{
        margin-top: 10px;
        .entry-content{
            padding: 10px;
            &.is-success{
                background-color: #c3f0d2;
                color: #2aa354;
            }
            &.is-info{
                background-color: #d2e5f1;
                color: #357ba9;
            }
            &.is-warning{
                background-color: #fff2e5;
                color: #ff7e00;
            }
            &.is-error{
                background-color: #FFD4CB;
                color: #d00000;
            }
            ul{
                margin-bottom: 0;
            }
        }
    }
}
