.tile{
  border-radius: $global-border-radius;
  padding: 1.5rem;
  display: block;
  @include media($xs-only){}
  @include media($sm-only){
    // default: is-sm-half
  }
  @include media($md-only){
    // default: is-md-half
  }
  @include media($lg-only){
    // default: is-lg-third
  }
  @include media($xl-only){
    // default: is-lg-fourth
  }
}