table{
  &.is-default{
    border-collapse: separate;
    border-spacing: 0;
    border-radius: $global-border-radius-half;
    font-size: 1.2rem;
    line-height: 1.2;
    thead{
      tr{
        background: $white;
      }
    }
    th{
      padding: 0.5rem;
      &:first-child{
        border-radius: $global-border-radius-half 0 0 0;
      }
      &:last-child{
        border-radius: 0 $global-border-radius-half 0 0;
      }
    }
    tbody{
      tr{
        background: $white;
        &:nth-child(odd){
          background: lighten($lightest-grey,2);
        }
        &:last-child{
          td{
            &:first-child{
              border-radius: 0 0 0 $global-border-radius-half;
            }
            &:last-child{
              border-radius: 0 0 $global-border-radius-half 0;
            }
          }
        }
      }
    }
    td{
      padding: 0.5rem;
      font-weight: 300;
    }
    tr{
      &:hover{
        td{
          background: lighten($lightest-grey,5);
        }
      }
    }
  }
  &.is-striped{
    border: 1px solid $light-grey;
    thead{

    }
    th{
      border-left: 1px solid darken($lightest-grey,5);
      &:first-child{
        border-left: none;
      }
    }
    td{
      border-left: 1px solid darken($lightest-grey,5);
      border-top: 1px solid darken($lightest-grey,5);
      &:first-child{
        border-left: none;
      }
    }
  }
  @include media($xs){
    &.is-default{
      font-size: 1.4rem;
    }
  }
  @include media($sm){}
  @include media($md){}
  @include media($lg){}
  @include media($xl){}
}