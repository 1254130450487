.is-gsm {
  .offer-review {
    background: $yellow;
    .expander{
      display: block;
    }
    .title-wrapper {
      position: relative;
      padding-left: 7rem;
      .fa-stack {
        position: absolute;
        left: 0;
        top: -10px;
        .fa-circle {
          color: $blue;
        }
      }
    }
    .review-wrapper {
      background: $white;
      margin: 3rem 0 2rem 0;
      border-radius: $global-border-radius-half;
      padding: 1.5rem;
    }
    h4 {
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }
    .review {
      padding: 2rem 0;
      border-top: 1px solid $lightest-grey;
      &:first-child {
        padding-top: 0;
        border: none;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    .review-score {
      color: $yellow;
      .fa-star-o {
        color: $light-grey;
      }
    }
    .entry-content {
      font-size: 1.5rem;
      padding: 1rem 0;
    }
    .review-meta {
      font-size: 1.4rem;
      color: $medium-grey;
    }
    .load-more {
      font-size: 1.5rem;
      color: $blue;
      &:before {
        @extend .fa;
        content: '\f067';
        margin-right: 0.5rem;
        font-size: 1.3rem;
      }
      &:hover {
        color: darken($blue, 10);
      }
    }
    .control-group {
      margin-bottom: 0.5rem;
      display: inline-block;
      width: 100%;
    }
    .write-review, .send-review {
      display: inline-block;
      background: $blue;
      border-radius: $global-border-radius-half;
      color: $white;
      font-weight: 700;
      padding: 1rem 1.5rem;
      &:hover {
        background: darken($blue, 10);
      }
    }
    .expander-content {
      @include outer-container();
    }
    .review-form {
      background: $light-blue-grey;
      border-radius: $global-border-radius-half;
      margin-top: 1rem;
      padding: 1.5rem;
      label:not(.is-checkbox) {
        width: 100px;
        font-weight: 700;
      }
      input, textarea{
        border-color: $medium-blue-grey;
        &:hover,&:focus,&:active{
          border-color: darken($medium-blue-grey, 10);
        }
      }
      .review-score {
        line-height: 3rem;
        direction: rtl;
        text-align: left;
        .fa {
          color: darken($medium-blue-grey, 10);
          font-size: 2rem;
          cursor: pointer;
          padding-right: 0.3rem;
          &:hover {
            color: $yellow;
            &:before {
              content: $fa-var-star;
            }
            ~ span.fa-star-o {
              color: $yellow;
              &:before {
                content: $fa-var-star;
              }
            }
          }
        }
      }
      .review-text {
        margin-bottom: 1rem;
        label {
          display: inline-block;
          margin-bottom: 1rem;
          width: 100%;
        }
      }
    }
    @include media($xxs-only) {
      .review-form {
        label:not(.is-checkbox) {
          width: 100%;
        }
        .review-text {
          label {

          }
        }
        input {
          width: 100%;
        }
        .review-score {
          float: left;
          line-height: initial;
          margin-bottom: 1rem;
        }
      }
    }
    @include media($xs-down) {
      .review-form {
        .review-score {
          .fa {
            font-size: 2.7rem;
            padding-right: 1rem;
          }
        }
      }
    }
    @include media($sm) {
    }
    @include media($md) {
      .review-form {
        @include span-columns(16);
      }
    }
    @include media($lg) {
    }
    @include media($xl) {
      .review-form {
        @include span-columns(12);
      }
    }
  }
}