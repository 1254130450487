.wft-credit-wrapper{
    padding: 2rem 0;
    .credit-notice-wrapper{
        border: 1px solid $black;
        width: 100%;
    }
    .credit-notice{
        max-height: 40px;
        display: block;
        margin: 0 auto;
    }
    .credit-table{
        width: 100%;
        margin-top: 1rem;
        font-size: 1.4rem;
        text-align: left;
        td,th{
            padding: 0.5rem;
        }
        th{
            vertical-align: bottom;
            padding-bottom: 0;
        }
        td{
            &.thead{
                font-weight: 700;
                vertical-align: bottom;
                padding-bottom: 0;
            }
        }
    }
}
