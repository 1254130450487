.bar-usp{
  background: $black;
  color: $white;
  .wrapper{
    @extend .container;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .usp{
    position: relative;
    font-size: 1.4rem;
    font-weight: 300;
    padding: 0.5rem 0 0.5rem 2rem;
    .fa{
      position: absolute;
      margin-left: -2rem;
      top: 0.5rem;
    }
  }
  @include media($xs-down){
    .usp{
      &:nth-child(n+3){
        display: none;
      }
    }
  }
  @include media($xs){
    .usp{
    }
  }
  @include media($sm){
    .usp{
      @include span-columns(12);
      @include omega(2n);
      font-size: 1.4rem;
    }
  }
  @include media($md){
    .usp{
    }
  }
  @include media($lg-only){
    .usp{
      &:nth-child(n+4){
        display: none;
      }
    }
  }
  @include media($lg){
    .usp {
      @include span-columns(8);
      @include omega-reset(2n);
      @include omega(3n);
    }
  }
  @include media($xl){
    .usp{
      @include span-columns(6);
      @include omega-reset(3n);
      text-align: center;
    }
  }
}