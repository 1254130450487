.is-gsm {
    .offer-phone-payment {
        .is-extended{
            button{
                .unit{
                    font-size: 1.4rem;
                }
            }
        }
    }
    .is-tele2, .is-vodafone{
        .offer-phone-payment {
            .noUi-target {
                margin: 5rem 1.5rem 3rem 1.5rem;
            }
            .noUi-base {
                &:hover {
                    cursor: pointer;
                }
            }
            .noUi-horizontal {
                .noUi-handle-lower {
                    &:hover {
                        cursor: pointer;
                    }
                    .noUi-tooltip {
                        white-space: nowrap;
                        overflow: visible;
                        top: -32px;
                        left: -1px;
                    }
                    &.noUi-active {
                        .noUi-tooltip {
                            display: block;
                        }
                    }
                }
                &.noUi-state-tap {
                    .noUi-handle-lower {
                        .noUi-tooltip {
                            display: block;
                        }
                    }
                }
            }
            .noUi-value-normal, .noUi-value-large {
                white-space: nowrap;
            }
            .noUi-value-sub {
                display: none;
            }
            .noUi-marker-normal, .noUi-marker-large {
                display: none;
            }
            .noUi-marker-sub {

            }
            .noUi-pips-horizontal {
                height: auto;
            }

            .phonepay-description{
                margin-top: 1rem;
                line-height: 1.8rem;
            }

            .phonepay-summary{
                margin-top: 1.5rem;
                > h3{
                    margin-bottom: 1rem;
                }
                @include clearfix;
                > div{
                    background: $light-blue-grey;
                    border: 1px solid $medium-blue-grey;
                    color: $dark-grey;
                    padding: 1rem;
                    display: inline-block;
                    @include gutter-all('margin-bottom');
                    h3{
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                    }
                    strong{
                        font-size: 1.8rem;
                    }
                }
                .discount_monthly{
                    margin-right: 1rem;
                }
            }
        }
    }

    .phonepay-summary{
        margin-top: 1.5rem;
        > h3{
            margin-bottom: 1rem;
        }
        @include clearfix;
        > div{
            background: $light-blue-grey;
            border: 1px solid $medium-blue-grey;
            color: $dark-grey;
            padding: 1rem;
            display: inline-block;
            @include gutter-all('margin-bottom');
            h3{
                font-weight: 500;
                margin-bottom: 0.5rem;
            }
            strong{
                font-size: 1.8rem;
            }
        }
        .discount_monthly{
            margin-right: 1rem;
        }
    }
}
