.is-gsm {
  .filter-result {
    @include media($md-only) {
      .tile {
        &.is-no-contract {
          .price-once {
            font-size: 3.6rem;
          }
        }
      }
    }
  }
}