.is-gsm {
  .overview-use-cases {
    background: $blue;
    color: $white;
    .tile {
      background: $white;
      color: $blue;
      &:hover {
        background: darken($blue, 20);
        color: $white;
      }
    }
    p.overview-cta {
      a {
        font-weight: 700;
        &:hover {
          text-decoration: underline;
        }
      }
      .service-offline {
        font-weight: 700;
      }
    }
    @include media($xs) {
      .tile {
        @include span-columns(12);
        @include omega(2n);
      }
    }
    @include media($sm) {
    }
    @include media($md) {
    }
    @include media($lg) {
      .tile {
        @include span-columns(6);
        @include omega-reset(2n);
        @include omega(4n);
      }
    }
    @include media($xl) {
    }
  }
}