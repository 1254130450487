// TODO: restructure

form.is-formatted{
    .is-label{
        font-size: 1.6rem;
        font-weight: 500;
        .required{
            margin-left: 0.5rem;
            color: $red;
        }
    }
    fieldset{
        margin-bottom: 2rem;
        &.is-intro{
            margin-top: -1rem;
            padding: 1rem 1rem 0 1rem;
            background: $light-blue-grey;
            border: 2px solid $blue;
            border-top: none;
        }
    }
    .form-info{
        font-size: 1.4rem;
        &.for-above{
            margin-bottom: 2rem;
        }
        &.for-below{
            margin-top: 2rem;
        }
    }
    .control-row{
        margin: 1.5rem 0;
        &.is-single{
            .is-input > *{
                width: 100%;
            }
        }
        &.is-prefix{
            margin-bottom: -1rem;
        }
        &.is-suffix{
            margin-top: -1rem;
        }
        &.is-interfix{
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
        &.is-no-label{
            .is-label{

            }
        }
        &.has-error{
            label,.is-label{
                color: darken($red, 10);
                font-weight: 700;
            }
            input,select{
                border-color: darken($red, 10);
            }
        }
    }
    @include media($xs-down){
        .is-label {
            display: block;
            margin-bottom: 0.5rem;
        }
        .control-row{
            &.is-no-label{
                h3{
                    display: none;
                }
            }
        }
    }
    @include media($sm){
        .control-row{
            @include outer-container();
        }
        .is-label{
            font-size: 1.5rem;
            line-height: 3rem;
        }
        .is-no-label{
            .is-label{
                line-height: 100%;
            }
        }
    }
}
