.fade-in{
  transition: all 0.4s ease-in-out;
  @include clearfix;
  &.js-fade-element-hide {
    opacity: 0;
    height: 0;
  }

  &.js-fade-element-show {
    opacity: 1;
    height: auto;
  }
}