.template_order-confirmation{
  .order-confirmation{
    padding: 3rem 0 5rem 0;
    .wrapper{
      @extend .container;
    }
  }
  h2{
    font-size: 2rem;
  }
  .order-process{
    margin-top: 0;
    h2{
      margin-bottom: 2rem;
    }
    ol{
      li{
        display: table;
        counter-increment: step;
        min-height: 4.3rem;
        margin: 1rem 0;
        span{
          display: table-cell;
          vertical-align: middle;
          position: relative;
          padding-left: 5.5rem;
          padding-right: 1rem;
          &:before{
            content: counter(step);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2.15rem;
            width: 4.3rem;
            line-height: 4.3rem;
            font-size: 2.4rem;
            text-align: center;
            display: inline-block;
            background: $yellow;
            border-radius: 100%;
          }
        }
      }
    }
    margin-bottom: 4rem;
    line-height: 1.4;
  }
  .order-support{
    h2{
      margin-bottom: 1rem;
    }
    p{
      line-height: 1.4;
    }
    margin-bottom: 4rem;
  }
  .order-suffix{
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .order-confirmation{

      > section:not(.is-section) {
        @include span-columns(20);
      }
    }
  }
  @include media($lg){
    .order-confirmation{
      section:not(.is-section) {
        @include span-columns(16);
      }
    }
  }
  @include media($xl){}
}
