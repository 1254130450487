.is-section{
  .wrapper{
    @extend .container;
    > h2{
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }

}