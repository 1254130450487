.template_service-page{
  .service-page-content{
    padding: 1rem 0 5rem 0;
  }
  .service-provider-menu{
    font-size: 1.4rem;
    padding-top: 1rem;
    h2{
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
  .page-content{

  }
  .provider-subject-collapsibles{
    margin-top: 2rem;
    .accordion{
      > li{
        margin-bottom: 1rem;
      }
    }
  }
  .provider-subject-faq{
    margin-top: 3rem;
    h3{
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    font-size: 1.4rem;
  }

  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .service-provider-menu{
      @include span-columns(8);
      padding-top: 4rem;
    }
    .page-content{
      @include span-columns(16);
    }
  }
  @include media($lg){
    .service-provider-menu{
      @include span-columns(6);
    }
    .page-content{
      @include span-columns(18);
    }
  }
  @include media($xl){}
}
