body#tinymce {
  margin: 12px !important;
  border: none;
  &:before {
    background-image: none;
  }
}

body#tinymce,.entry-content{
  line-height: 1.2;
  h2,h3,h4{
    margin: 2rem 0 1rem 0;
  }
  h2{
    font-size: 2.6rem;
  }
  h3{
    font-size: 2.2rem;
  }
  h4{
    font-size: 1.8rem;
  }
  p{
    margin: 1rem 0;
    line-height: 1.4;
  }
  ul,ol{
    margin: 1.5rem 0 2rem .5rem;
    li{
      display: list-item;
      margin-left: 2rem;
    }
  }
  blockquote {
    background: $lightest-grey;
    border-left: 1rem solid $light-grey;
    margin: 1.5rem 0;
    padding: 1rem;
    line-height: 2.4rem;
    &:before{
      @extend .fa;
      content: '\f10d';
      color: $medium-grey;
      font-size: 2.4rem;
      line-height: 0.1rem;
      margin-right: 1rem;
      vertical-align: -0.4rem;
    }
    p {
      display: inline;
    }
    strong{
      color: darken($medium-grey,10);
    }
  }
  hr{
    border:none;
    border-top: 2px solid $light-grey;
    margin: 2rem 0;
  }
  a{
    text-decoration: underline;
    &:hover{
      color: $blue;
    }
  }
  > *:first-child{
    margin-top: 0;
  }
  > *:last-child{
    margin-bottom: 0;
  }
}