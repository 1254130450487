.service-provider-subjects{
  &:nth-child(3n-2){
    background: $yellow;
  }
  &:nth-child(3n){
    background: $lightest-grey;
  }
  h3{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  .accordion{
  }
  .service-pages{
    margin-bottom: 2rem;
  }
  .service-faq{
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .subject-wrapper{
      @include clearfix;
      > div{
        @include span-columns(12);
      }
    }
    .service-pages{
      margin-bottom: 0;
    }
  }
  @include media($lg){}
  @include media($xl){}
}