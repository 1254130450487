// Actions
.animate {
  transition: all $global-transition-duration-medium ease-in-out;
}

.cta{
  transition: all $global-transition-duration-short ease-in-out;
}

// Sections
section{
  &.is-full-width {
    > .wrapper {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

// Layout
.no-wrap{
  white-space: nowrap;
}

.flex-container{
  display: flex !important;
}

.is-transformed{
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

.is-antialiased{
  font-smoothing: antialiased !important;
}

// Links
.is-cta-link{
  color: $blue;
  &:hover{
    text-decoration: underline;
  }
}

.is-default-link{
  text-decoration: underline;
  &:hover{
    color: $blue;
  }
}

// States
.is-active{

}

.is-disabled{

}

.is-warning{

}

.is-alert{

}

.is-error{

}

// Colors
.is-bg{
  &-black{
    background: $black;
    &:after{
      border-left-color: $black !important;
    }
  }
  &-white{
    background: $white;
    &:after{
      border-left-color: $white !important;
    }
  }
  &-grey, &-titanium{
    background: $medium-grey;
    &:after{
      border-left-color: $medium-grey !important;
    }
  }
  &-light-grey, &-silver, &-platinum{
    background: $light-grey;
    &:after{
      border-left-color: $light-grey !important;
    }
  }
  &-blue{
    background: $blue;
    &:after{
      border-left-color: $blue !important;
    }
  }
  &-red{
    background: $red;
    &:after{
      border-left-color: $red !important;
    }
  }
  &-yellow{
    background: $yellow;
    &:after{
      border-left-color: $yellow !important;
    }
  }
  &-green{
    background: $green;
    &:after{
      border-left-color: $green !important;
    }
  }
  &-purple{
    background: $purple;
    &:after{
      border-left-color: $purple !important;
    }
  }
  &-orange{
    background: $orange;
    &:after{
      border-left-color: $orange !important;
    }
  }
  &-gold{
    background: $gold;
    &:after{
      border-left-color: $gold !important;
    }
  }
  &-pink{
    background: $pink;
    &:after{
      border-left-color: $pink !important;
    }
  }
}

.is-text{
  &-black{
    color: $black;
  }
  &-white{
    color: $white;
  }
  &-grey, &-titanium{
    color: $medium-grey;
  }
  &-light-grey, &-silver, &-platinum{
    color: $light-grey;
  }
  &-blue{
    color: $blue;
  }
  &-red{
    color: $red;
  }
  &-yellow{
    color: $yellow;
  }
  &-green{
    color: $green;
  }
  &-purple{
    color: $purple;
  }
  &-orange{
    color: $orange;
  }
  &-gold{
    color: $gold;
  }
  &-pink{
    color: $pink;
  }
}

.default-border{
  border-bottom-color: $light-grey;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.is-list{
  li{
    display: list-item;
    margin-left: 1rem;
  }
}