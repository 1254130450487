.is-gsm {
  .offer-contract-provider-info {
    background: $light-blue-grey;
    padding: 3rem 0 0 0;
    .provider-info {
      background: $white;
      border: 2px solid $medium-blue-grey;
      padding: 1.5rem;
      border-radius: $global-border-radius;
      ul {
        font-size: 1.4rem;
        li {
          position: relative;
          padding-left: 2rem;
          margin-bottom: 1rem;
          &:before {
            @extend .fa;
            content: "\f00c";
            position: absolute;
            left: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}