.tile.is-gsm.is-contract {
    .provider-image{
        height: 20px;
        margin-bottom: 1rem;
    }
    &.is-kpn{
        .provider-image{
            height: 21px;
        }
    }
    &.is-vodafone{
        .provider-image{
            height: 30px;
        }
    }
    .price-month{
        font-size: 4.2rem;
        font-weight: 700;
        position: relative;
        .cent{
            position: absolute;
            top: 0.2rem;
            font-size: 2.4rem;
        }
        .period{
            position: absolute;
            bottom: 0.5rem;
            margin-left: 0.1rem;
            font-size: 1.3rem;
            font-weight: 500;
        }
    }
    .price-once{
        color: $medium-grey;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }
    .data{
        margin-bottom: 0.5rem;
    }
    .call{
        margin-bottom: 0.5rem;
    }
    .amount{
        font-weight: 700;
    }

    @include media($xxs-only){
    }

    @include media($xs-only){
        .provider{
            height: 1.8rem;
        }
    }

    @include media($sm-only){
        .provider{
            margin-bottom: 1rem;
        }
        &.is-kpn{
            .provider-image{
                margin-bottom: 0.5rem;
            }
        }
        .price-once{
            margin-bottom: 1rem;
            font-size: 1.4rem;
        }

        &.is-sm-full{

        }
    }

    @include media($md-only){

        &.is-md-third{
            .price-month{
                font-size: 3.6rem;
                .cent{
                    top: 0;
                    font-size: 2rem;
                }
                .period{
                    font-size: 1.1rem;
                }
            }
            .price-once{
                font-size: 1.4rem;
            }
        }
    }

    @include media($lg-only){
        &.is-lg-half{

        }
    }

    @include media($xl-only){
        &.is-xl-third{

        }
        &.is-xl-half{

        }
    }

    // is not hero
    &.is-normal{
        @include media($xs-only){}
        @include media($sm-only){}
        @include media($md-only){}
        @include media($lg-only){}
        @include media($xl-only){}
    }

    // is hero
    &.is-hero{
        @include media($xs-only){}
        @include media($sm){
            .price-month {
                margin-top: -0.5rem;
                text-align: right;
                padding-right: 41px;
            }
            .price-once{
                text-align: right;
            }
        }
        @include media($sm-only){}
        @include media($md){}
        @include media($md-only){}
        @include media($lg-only){}
        @include media($xl-only){}
    }

    // is featured
    &.is-featured{
        @include media($xs-only){}
        @include media($sm-only){}
        @include media($md-only){}
        @include media($lg-only){}
        @include media($xl-only){}
    }
}
