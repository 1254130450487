// Asset paths
$local-font-path: "../fonts";
$local-image-path: "../images";

//CDN asset paths
$font-path: "//cdn.justwalk.nl/dist/fonts";
$image-path: "//cdn.justwalk.nl/dist/images";

// Fonts
$base-font-family: "Museo Sans", "sans-serif";
$heading-font-family: "Museo Sans", "sans-serif";
$code-font-family: "monospace", "monospace";

// Font-Face
$font-version: "1.0.0";

// Typography
$line-height: modular-scale(3, 1em);

// Colors
$black: #111111;
$white: #ffffff;
$light-blue-grey: #F2F6F9;
$medium-blue-grey: #D9E2E8;
$lightest-grey: #eeeeee;
$light-grey: #cccccc;
$medium-grey: #777777;
$medium-dark-grey: #444444;
$dark-grey: #333333;
$blue: #0089d0;
$red: #d45252;
$green: #28921f;
$purple: #551A8B;
$pink: #FF69B4;
$yellow: #ffbb00;
$gold: #FFD700;
$orange: #FF7600;
// Branding colors
$t-mobile: #e20075;
$ben: #00aeef;
$tele2: #5d0188;
$vodafone: #e60000;


// CSS3
$global-transition-duration-short: 0.2s;
$global-transition-duration-medium: 0.3s;
$global-transition-duration-long: 0.6s;
$global-transition-delay: 0.1s;
$global-border-radius: 8px;
$global-border-radius-half: $global-border-radius / 2;
$global-border-radius-fourth: $global-border-radius / 4;

// Spacing
$section-padding: 3rem;
$step-padding: 4rem;
