.hero{
    background-color: $white;
    .wrapper{
        @extend .container;
        position: relative;
    }
    .slogan-extended{
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.7rem;
    }
    .offer-wrapper{
        padding: 0;
        h2{
            background: $yellow;
            border-radius: 5px 5px 0 0;
            text-align: left;
            padding: 1rem 1.5rem;
            font-size: 2rem;
        }
        .tile{
            background: $white;
            border: 3px solid $yellow;
            border-top: none;
            border-radius: 0 0 $global-border-radius $global-border-radius;
        }
    }
    a.is-deal{
        position: absolute;
        bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 300px;
        text-align: center;
        background: $yellow;
        padding: 1.5rem 2.5rem;
        border: 3px solid;
        border-radius: $global-border-radius;
        font-size: 1.8rem;
        font-weight: 700;
        &:hover{
            background: $black;
            color: $yellow;
        }
        &.is-tele2_basis{
            width: 275px;
        }
    }
    .slick-dots{
        position: absolute;
        margin-top: 0;
    }
    @include media($xxs-only){
        .slick-dots{
            margin-top: -1.5rem;
        }
    }
    @include media($xs){
    }
    @include media($xs-only){
        .slick-dots{
            margin-top: -4.5rem;
        }
    }
    @include media($xs-down){
        .slick-dots{
            width: 62%;
        }
    }
    @include media($sm){
        .slogan-extended{
            margin-bottom: 1rem;
        }
        .slick-dots{
            width: 75%;
            margin-top: -2.5rem;
        }
    }
    @include media($md){
        .offer-wrapper {
            @include span-columns(20);
            @include shift(2);
        }
        .slick-dots{
            margin-top: -5rem;
        }
    }

    @include media($md-down){
        a.cta{
            margin-left: auto;
        }
    }

    @include media($md-only){
        a.cta{
            @include shift(2);
            left: 15px;
        }
    }
    @include media($lg){
        .wrapper{
            background: url('#{$image-path}/global/miky-hero.jpg') no-repeat left bottom;
            background-size: 400px;
            background-position: left bottom;
        }
        .offer-wrapper {
            @include span-columns(16);
            @include shift(8);
        }
        .slick-dots{
            margin-top: -2rem;
        }
        a.is-deal {
            right: auto;
            left: 2rem;
        }
    }
    @include media($xl) {
        .wrapper{
            background-size: 440px;
        }
        &[data-subscription=tmobile_go]{
            .wrapper{
                //background-position-x: 50px;
            }
        }
        .slick-dots{
            margin-top: -4rem;
        }
        .offer-wrapper {
            @include span-columns(14);
            @include shift(10);
        }
        a.is-deal {
            left: 8rem;
        }
    }
}
