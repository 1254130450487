.breadcrumbs{
  padding-top: 2.4rem;
  padding-bottom: 1rem;
  margin-top: -1.4rem;
  .wrapper{
    @extend .container-desktop-max;
  }
  span{
    font-size: 1.4rem;
  }
  a{
    &:hover{
      color: darken($blue,10);
      text-decoration: underline;
    }
  }
}