
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
	box-sizing: border-box;
}
.noUi-pips {
	position: absolute;
	color: $medium-grey;
}

/* Values;
 *
 */
.noUi-value {
	position: absolute;
	text-align: center;
}
.noUi-value-sub {
    color: $medium-grey;
	font-size: 10px;
    top: 18px;
}

/* Markings;
 *
 */
.noUi-marker {
	position: absolute;
	background: #CCC;
}
.noUi-marker-sub {
	background: #D3D3D3;
}
.noUi-marker-large {
	background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
	padding: 10px 0;
	height: 80px;
	top: 100%;
	left: 0;
	width: 100%;
  margin-top: -9px;
}
.noUi-value-horizontal {
	transform: translate3d(-50%,50%,0);
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%;
}
.noUi-value-vertical {
	transform: translate3d(0,-50%,0);
	padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
	width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
	width: 15px;
}
