html {
  font-size: 62.5%;
  color: $black;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
body {
  @include rem(font-size, 1.6rem);
  font-family: $base-font-family;
  line-height: 1;
  margin: 0;
}
pre,code,kbd,samp,tt{
  font-family: $code-font-family;
}
iframe {
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
figure,
button {
  margin: 0;
  padding: 0;
}
main,
li {
  display: block;
}
h1{
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
}
strong {
  font-weight: bold;
}
sup {
  position: relative;
  top: -.5em;
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
}
a,
button {
  color: inherit;
  cursor: pointer;
}
a {
  text-decoration: none;
  outline: none;
  @include media($desktop){
    &[href^="tel:"] {
      text-decoration: none !important;
      cursor: default;
    }
  }
}
button {
  overflow: visible;
  border: 0;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  &:focus {
    outline: 0;
  }
}
::-moz-focus-inner {
  padding: 0;
  border: 0;
}
img {
  max-width: 100%;
  height: auto;
  border: 0;
}