.tabs{
  @include outer-container();
  margin-top: 3rem;
  background: $white;
  border: 2px solid $light-grey;
  border-radius: $global-border-radius-half;
  padding: 1.5rem;
  .tab-links{
    font-size: 1.5rem;
  }
  .tab-content{
    @include span-columns(24);
    font-size: 1.5rem;
    .entry-content{
      display: none;
      &.is-active {
        display: block;
      }
    }
  }

  @include media($xs-down){
    .tab-links{
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $light-grey;
      margin-bottom: 1rem;
      p{
        display: inline;
        line-height: 3rem;
        margin-right: 1rem;
      }
    }
  }

  @include media($sm){
    .tab-links{
      @include span-columns(8);
      > a{
        position: relative;
        color: $blue;
        display: block;
        padding: 1rem;
        margin-bottom: 0.5rem;
        border-radius: $global-border-radius-half 0 0 $global-border-radius-half;
        &:after{
          position: absolute;
          left: 100%;
          top: 0;
          content: '';
          border-style: solid;
          border-width: 17px 0 17px 8px;
          border-color: transparent transparent transparent transparent;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &:hover{
          background: $lightest-grey;
          &:after{
            border-color: transparent transparent transparent $lightest-grey;
          }
        }
        &:focus{
          background: darken($lightest-grey,10);
          &:after{
            border-color: transparent transparent transparent darken($lightest-grey,10);
          }
        }
        &.is-active{
          background: $blue;
          color: $white;
          font-weight: 700;
          &:after{
            border-color: transparent transparent transparent $blue;
          }
        }
      }
    }
    .tab-content{
      @include span-columns(16);
      padding-left: 0.7rem;
    }
  }
  @include media($md){
    .tab-links{
      @include span-columns(6);
      font-size: 1.6rem;
      > a{
        &:last-child{
        }
        &:hover{
        }
        &:focus{
        }
        &.is-active{
          &:after{
            border-width: 18px 0 18px 9px;
          }
        }
      }
    }
    .tab-content{
      @include span-columns(18);
      font-size: 1.6rem;
      padding-left: 0.9rem;
    }
  }
}