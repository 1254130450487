.comments{
  margin: 2rem 0;
  h4{
    margin-bottom: 1rem;
  }
  .comment-list{
    margin-bottom: 2rem;
    > .comment{
      padding: 2rem 0;
      border-top: 2px dashed $light-grey;
      &:first-child{
        border: none;
        padding-top: 0;
      }
      & + .comment-respond{
        margin-bottom: 2rem;
      }
    }
  }
  .comment{
    .comment-meta{
      background: $lightest-grey;
      margin-bottom: 1rem;
      height: 40px;
      .avatar{
        float: left;
        margin: 0 1rem 0 0;
      }
      .comment-author{
        font-size: 1.8rem;
        font-weight: 700;
      }
      .comment-published{
        margin: 0.5rem 0;
        font-size: 1.4rem;
      }
    }
    .comment-pending{
      color: $medium-grey;
    }
    .comment-body{
      background: $lightest-grey;
      border: 3px solid $light-grey;
      border-radius: $global-border-radius-fourth;
      padding: 1rem;
    }
    .reply{
      a{
        display: inline-block;
        margin-top: 1rem;
        color: $blue;
        text-decoration: underline;
      }
    }
  }
  .children{
    margin-left: 2rem;
    .comment{
      margin: 1rem 0 0 0;
    }
    .comment-respond{
      margin-top: 2rem;
    }
  }

  .comment-form{
    @include clearfix;
  }
  .comment-respond{
    border-radius: $global-border-radius-fourth;
    background: $dark-grey;
    color: $white;
    padding: 1rem;
    h3{
      margin-bottom: 0.5rem;
    }
    #cancel-comment-reply-link{
      color: lighten($blue,10);
      font-weight: 500;
      margin-left: 1rem;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .comment-notes{
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .logged-in-as{
    margin-bottom: 1rem;
  }
  .comment-form-comment{
    margin-bottom: 1rem;
  }
  .comment-field{
    position: relative;
    input{
      width: 100%;
    }
  }
  .form-submit{
    width: 100%;
    .submit{
      width: 100%;
      background: $blue;
      color: $white;
      &:hover{
        background: darken($blue,10);
      }
    }
  }
  label{
    min-width: 65px;
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){}
  @include media($md-down){
    .comment-field {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  @include media($lg){
    .comment-field {
      width: 100%;
    }
    .comment-form-comment{
      @include span-columns(24);
      @include gutter-all-mq('margin-bottom','lg', 0.5);
    }
    .comment-form-author{
      @include span-columns(8);
    }
    .comment-form-email{
      @include span-columns(8);
    }
    .form-submit{
      @include span-columns(8);
    }
  }
  @include media($xl){
    .comment-form-comment{
      @include span-columns(24);
      @include gutter-all-mq('margin-bottom','xl', 0.5);
    }
  }
}