.video {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
  position: relative;

  embed,
  object,
  iframe {
    @include position(absolute, 0px null null 0px);
    @include size(100%);
  }
}