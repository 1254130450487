// Service open styling
body.service-open{
  .overview-use-cases .callcenter.is-open{
    display: inline;
  }
  .bar-service .callcenter.is-open{
    display: block;
  }
}

// Service closed styling
body.service-closed {
  .overview-use-cases .callcenter.is-closed {
    display: inline;
  }
  .bar-service .callcenter.is-closed {
    display: block;
  }
}

@include media($mobile){
  // Mobile menu
  // Service open styling
  body.service-open{
    .is-service-menu .callcenter.is-open{
      display: block;
    }
  }

  // Service closed styling
  body.service-closed{
    .is-service-menu .callcenter.is-closed{
      display: block;
    }
  }
}


@include media($sm){
  // Header
  // Service open styling
  body.service-open{
    .header-top .callcenter.is-open{
      display: block;
    }
  }

  // Service closed styling
  body.service-closed{
    .callcenter.is-closed{
      display: block;
    }
  }
}