.offer-deal{
  .inner-wrapper{
    padding: 2rem;
    background: #e20075;
    color:white;
    position: relative;
  }
  p{
    margin: 1rem 0;
    line-height: 1.2;
  }
  img{
    position: absolute;
    top: -45px;
    right: -10px;
  }
  @include media($xs){
    img{
      right: 30%;
    }
  }
}

.filter-result{
  .offer-deal{
    margin-bottom: 2rem;
    margin-top: 3rem;
    h3{
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2.5rem;
    }
  }
}

.order-service{
  .offer-deal{
    img{
      right: -10px;
    }
    @include media($md-down){
      margin-top: 2rem;
    }
    @include media($lg){
      img{
        top:-70px;
      }
    }
  }
}