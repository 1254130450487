.is-gsm {
    .offer-contract-bundle{
        background: $light-blue-grey;
    }
    .offer-contract-allinone {
        &.is-tele2{
            display: none;
        }
        background: $light-blue-grey;
        &.is-tmobile-go, &.is-vodafone{
            background: white;
        }
        button{
            .divider{
                display: block;
            }
            .name .is-inner:before{
                //TODO: refactor for tele2 to remove !important
                display: none !important;
            }
            .actual{
                font-size: 1.4rem;
                line-height: 1.6rem;
            }
        }
    }
}
