.is-gsm {
  .offer-phone-specification {
    .title-wrapper {
      position: relative;
      padding-left: 7rem;
      .fa-stack {
        position: absolute;
        left: 0;
        top: -10px;
        .fa-circle {
          color: $blue;
        }
      }
    }
    .phone-deal {
      margin: 3rem 0;
      border: 2px solid $yellow;
      border-radius: $global-border-radius-half;
      h4 {
        background: $yellow;
        text-align: center;
        font-size: 1.8rem;
        padding: 1rem;
      }
      p {
        text-align: center;
        padding: 1.5rem 1rem;
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }
    .phone-teaser {
      margin: 3rem 0;
      p {
        font-size: 1.5rem;
        line-height: 1.4;
      }
      .expander{
        display: block;
      }
    }
    .phone-specs {
      h4 {
        font-size: 2rem;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
        .fa {
          display: none;
          width: 2rem;
          text-align: center;
          //color: $orange;
        }
        .fa-mobile {
          font-size: 2.8rem;
          line-height: 1rem;
          top: 3px;
          position: relative;
        }
      }
      table {
        font-size: 1.4rem;
        line-height: 1.2;
        border-collapse: collapse;
        width: 100%;
        td {
          vertical-align: top;
          //border-bottom: 1px solid darken($lightest-grey,10);
          &:first-child {
            padding: 1rem 0.5rem 1rem 0.5rem;
            width: 125px;

          }
          &:last-child {
            padding: 1rem 0 1rem 0.5rem;
          }
        }
        tr {
          &:hover {
            td {
              background: $lightest-grey;
            }
          }
          &:last-child {
            td {
              border: none;
            }
          }
        }
        a {
          text-decoration: underline;
          &:hover {
            color: $blue;
          }
        }
      }
      .basic-specs-wrapper {
        @include outer-container();
        .basic-specs {
          //@include span-columns(12);
          margin-bottom: 2rem;
          td {
            &:first-child {
              width: 110px;
            }
          }
        }
      }
      .expander{
        display: block;
      }
      .expander-content {
        margin-top: 1.5rem;
      }
      .extended-specs-wrapper {
        @include outer-container();
        .extended-specs {
          margin-top: 2rem;
          margin-bottom: 2rem;
          li {
            padding-left: 0.5rem;
            &:before {
              content: "-";
              position: relative;
              left: -0.5rem;
            }
          }
        }
      }
      .expander-trigger {
        top: -2.5rem;
        position: relative;
        margin-bottom: -2rem;
      }
    }
    @include media($xxs-down) {
    }
    @include media($xs-down) {
      .phone-specs {
        .basic-specs-wrapper {
          .basic-specs {
            &:first-child {
              margin-bottom: 0;
            }
            .spec-dimensions,
            .spec-os,
            .spec-networks,
            .spec-voice-duration {
              display: none;
            }
          }
        }
      }
    }
    @include media($xs) {
    }
    @include media($sm) {
      .phone-specs {
        .basic-specs-wrapper {
          .basic-specs {
            @include span-columns(12);
          }
        }
      }
    }
    @include media($md) {
      .phone-specs {
        .extended-specs-wrapper {
          .extended-specs {
            @include span-columns(12);
            @include omega(2n);
          }
        }
      }
    }
    @include media($lg) {
    }
    @include media($xl) {
    }
  }
}