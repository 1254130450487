.social-share{
  background: $yellow;
  .wrapper{
    @extend .container;
    > h2{
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }
  .tile{
    display: table;
    width: 100%;
    @include gutter-all("margin-bottom");
    transition: all $global-transition-duration-short ease-in-out;
    background: $blue;
    color: $white;
    &:hover{
      background: darken($blue,10);
    }
    &:last-child{
      margin-bottom: 0;
    }
    .fa{
      display: table-cell;
      vertical-align: middle;
      font-size: 6rem;
      line-height: 6rem;
      width: 9rem;
      text-align: center;
      padding-right: 1.5rem;
    }
    .description{
      display: table-cell;
      vertical-align: middle;
    }
    h2{
      font-size: 1.8rem;
    }
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .tile{
      @include span-columns(8);
      margin-bottom: 0;
      .description{
        br{
          display: block;
        }
      }
    }
  }
  @include media($lg){
    .tile{
      display: table;
      .fa{
        float: none;
        width: 8rem;
      }
    }
  }
  @include media($xl){
    .tile{
      .description{
        br{
          display: none;
        }
      }
    }
  }
}