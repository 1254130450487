/* Basis */
.daisyconEnergyComparator{
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;   
	text-align:left;
	/*overflow:hidden;*/
}

.daisyconEnergyComparator div{
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;  
}
.daisyconEnergyComparator img{
	box-shadow:0px 0px 0px rgba(68,68,68,0.6) !important;
	-webkit-box-shadow:0px 0px 0px rgba(68,68,68,0.6) !important;
	border:none;
}

/* Comperator */
.daisyconEnergyComperatorPackageContainer{
	float:left;
	width:20%;
	margin-left:-1px;
}
.daisyconEnergyComperatorPackage{
	border:1px solid #eaeaea;
	padding:5px;
}
.daisyconEnergyComperatorPackageSpecs{
	padding:5px;
	background:#eaeaea;
}
.daisyconEnergyComperatorPackageRow{
	border-bottom:1px solid #eaeaea;
	padding:2px;
	line-height:30px;
	height:30px;
	text-align:center;
}
.daisyconEnergyStartComparePackage{
	background:#00a6da;
	right:10px;
	padding:10px;
	position:absolute;
	z-index:2;
	border-radius:2px;
	margin-top:10px;
	color:#FFF;
	cursor:pointer;
	display:none;
	height:35px;
	line-height:18px;
}
/*Filters*/
.daisyconEnergyFilters{
	width:25%;
	float:left;
}

.daisyconEnergyFilters select{
    border: #cecece 1px solid !important;
    border-radius: 2px;
    color: #333;
    height: 30px !important;
    width: 100%;
    margin-top:5px;
	padding: 0 !important;
}

.daisyconEnergyHeader{
	color:#FFF;
	line-height:24px;
	text-align:left;
	padding:5px;
	font-size:125%;
	vertical-align: middle !important;
	cursor:pointer;
	background-color: #3498DB;
}

.daisyconEnergyHeader img{
	margin: 0 !important;
	margin-right:5px !important;
	width:12px !important;
	border:none !important;
	vertical-align: middle !important;
}

.daisyconEnergyPart{
	margin-top:10px;
	margin-bottom:10px;
	-webkit-border-top-left-radius: 2px;
	-webkit-border-top-right-radius: 2px;
	-moz-border-radius-topleft: 2px;
	-moz-border-radius-topright: 2px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
} 

.daisyconEnergyPart:nth-child(-n+4) {
	border: 1px solid #3498DB;
}

.daisyconEnergyOptionContainer{
	overflow:hidden;
}

.daisyconEnergyOption{
	padding:5px;	
}

	.daisyconEnergyOption select, .daisyconEnergyOption input, .daisyconEnergyTopFilterChoise input{
		margin:2px !important;
		font-size:100% !important;
		line-height:100% !important;
	}
	
	.daisyconEnergyOption input[type=text]{
		border:1px solid #eaeaea !important;
		padding:5px !important;
		line-height:20px !important;
		height:30px !important;
		margin-top:5px;
		width:95%;
		border-radius:2px;
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
		background: none !important;
	}
	
	.daisyconEnergyOption .daisyconEnergylabel{
		width:100%;
	}
	
	.daisyconEnergyLabel{
		margin-bottom:5px;
		margin-top:5px;
		color:#000;
	}


	
/* Filter Specifiek */
.daisyconEnergyEnkel, .daisyconEnergyDubbel{
	float:left;
	width:50%;	
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;  
	padding:1%;
	background:#FFF;
	text-align:center;
	line-height:20px;
	cursor:pointer;
}
 
.daisyconEnergyDubbelContainer{
    display:none;
}

.diasyconEnergyShowCalculator{
	padding:5px;
	font-size:80%;
	cursor:pointer;
	border-top:1px solid #eaeaea;
	width:100%;
}
.diasyconEnergyShowCalculator:hover{
	background:#F3F3F3;
}

.daisyconFilterCheck{
	color:#333333;
	background:#F3F3F3;
	border:1px solid #eaeaea;
	padding:5px 10px;
	border-radius:2px;
	margin-top:5px;
	cursor:pointer;
}

.daisyconFilterCheckActive{
	color:#333333;
	border:1px solid #eaeaea;
	padding:5px 10px;
	border-radius:2px;
	margin-top:5px;
	cursor:pointer;
}

.daisyconFilterCheck img, .daisyconFilterCheckActive img{
	display:inline-block !important;
	vertical-align:middle !important;
	margin-right:5px;
	width: 13px;
	background:none !important;
	border:none !important;
}

.daisyconEnergyPlace{
	position:relative;
	font-style:italic;
	font-size:70%;
	margin-top:5px;
	font-style:italic;
	padding:5px;
	border:1px solid #eaeaea;
	background:#F3F3F3;
}


/* Mailfunctie */
.daisyconEnergyMail{
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;
    border-width: 0;
    outline: none;
    border-radius: 2px;
    background-color: rgb(255, 130, 1);
    color: #ecf0f1;
    transition: background-color .3s;
	box-sizing:border-box;
	padding:5px;
	font-size:20px;
	text-align:center;
	cursor:pointer;
	transition: opacity 0.5s;
}

.daisyconEnergyMail .daisyconEnergyGoElement{
	font-size:100% !important;
}

.daisyconEnergyBackground{
	position:fixed; 
	top:0; 
	left:0; 
	background: rgba(0, 0, 0, 0.25);
	z-index:5; 
	width:100%; 
	height:100%; 
	display:none;
}

.daisyconEnergyPopUpMail{
    font-family: "lucida grande",tahoma,verdana,arial,sans-serif;
    background: none repeat scroll 0 0 #FFFFFF;
    color: #333333;
    font-size: 12px;
    left: 0%;
    right: 0;
    position: fixed;
    top: 100%;
    width: 100%;
    z-index: 10;
    bottom: 0;
	box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
	display:none;
}

.daisyconEnergyMailContent{
    padding: 24px;
	box-sizing: border-box;

}

.daisyconEnergyMailContent  h4{
	font-size: 2.28rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0;
}
.daisyconEnergyPopUpMailClose{
    cursor: pointer;
    height: 50px;
    position: absolute;
    right: 2%;
    top: -25px;
    width: 50px;
    transition: box-shadow 0.4s, text-shadow 0.4s;
    background-color: rgb(107, 99, 98);
    text-shadow: 0px 0px 2px #2D2D2D;
    border-radius: 50px;
	text-align:center;
	line-height:50px;
    color: white;
	content: ' \D7';
	box-shadow: rgba(0, 0, 0, 0.156863) 0px 2px 5px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 10px 0px;
	display:none;
}
.daisyconEnergyPopUpMailClose::after{
    content: ' \D7';
    font-size: 32px;
    text-align: center;
}

.daisyconEnergyPopUpMailClose:hover{
  
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    text-shadow: 0px 0px 1px #2D2D2D;
}

.daisyconEnergyPopUpIntro{
	box-sizing: border-box;
	font-size:15px;
}

.daisyconEnergyPopupSendEmailFeedback{
	padding-top:40px;
}

.daisyconEnergyPopupSendEmailButton:hover{
	border: none !important;
}
.daisyconEnergyMailFeedback{
    text-align: center;
    font-size: 20px;
    padding: 20px;
    margin: 20px;
    background-color: #f3f3f3;
    border: 1px solid #CCC;
    border-radius: 2px;
	display:none;
}

.daisyconEnergyPopupSendTableNameTrue, .daisyconEnergyPopupSendTableNameFalse{
    display: none;
    position: absolute;
    top: 0;
    left: 270px;
    border-radius: 50px;
    height: 25px;
    width: 25px;
	color:white;
    font-size: 20px;
    line-height: 25px;
}


.daisyconEnergyPopupSendTableNameFalse::after{
    background-color: #4B4B6B;
    content: ' \D7';
    text-align: center;   
	background-color: rgb(244, 67, 54);
	text-shadow: 0px 0px 2px #BD2727; 
    position: absolute;
    top: 0;
    border-radius: 50px;
    height: 25px;
    width: 25px;
	color:white;
}

.daisyconEnergyPopupSendTableNameTrue{
    background-color: #37791B;
}

.daisyconEnergyPopupSendTableNameTrue::after{
    content: '\2713';
    margin-left: 5px;
    height: 21px;
    font-size: 18px;
    width: 25px;
    text-align: center;
}



/* Content */
.daisyconEnergyTableContainer{
	float:right;
	width:74%;	
	position:relative;
}

.daisyconRegionFound{
	text-align:center;
	padding:5px;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
	border:1px solid #ddbb4c;
	border-radius:2px;
	background:#f4e7be;
	margin-top:10px !important;
	display:none;
	margin-left:3%;
}

/* Resultaat */
.daisyconEnergyRow{
	border:1px solid #dddddd;
	background:#FFF;
	margin-top:10px;
	position: relative;
	border-radius:2px;
	position:relative;
	margin-left:3%;
	z-index:3;
	display:none;
}

.daisyconEnergyRowAction{
	
	display:block !important;
}
.daisyconEnergyRowAction .daisyconEnergyRowMoreInfo{
	background:#FFF !important;
}
.daisyconEnergyRowAction .daisyconEnergyRowNumber{
	width:34px;
	height:20px !important;
	line-height:20px;
	font-size:11px !important;
	border-radius:2px;
	margin-left:-5px;
	margin-top:2px;
}

.daisyconEnergyRowHeaderInfo{
	float:left;
	width:70%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.daisyconEnergyRowHeader{
	padding:5px;
	border-bottom:1px solid #eaeaea;
	line-height:30px;
}

.daisyconEnergyRowHeader img{
	height:30px !important;
	display:inline-block !important;
	vertical-align:middle !important; 
	margin-right:10px;
	border:none !important;
	margin-left:10px;
}

.daisyconEnergyRowHeaderCompare{
	float:right;
	background:#eaeaea;
	padding:5px 10px;
	margin-top:5px;
	line-height:120%;
	cursor:pointer;
}
.daisyconEnergyRowHeaderCompare img{
	width:13px !important;
	height:13px !important;
	margin:0px;
	display: inline-block !important;
	vertical-align: middle !important;
	margin-right: 5px;
	background:none !important;
	border:none !important;
}

.daisyconEnergyRowInfo{
	padding:10px;
	width:100%;
	display: inline-block;
	position: relative;
}

.daisyconEnergyRowNumber{
	border-radius:100%;
	background:#00a6da;
	color:#FFF;
	line-height:25px;
	width:25px;
	position:absolute;
	text-align:center;
	left:-12px;
	top:10px;
	font-weight:bold;
}

.daisyconEnergyRowAanbiederImg{
	float:left;
	width:20%;
	height:54px;
	display:inline-block !important;
	vertical-align:middle !important;
}

.daisyconEnergyRowAanbiederImg img{
	width:90%;
	margin-left:5%;
	margin-top:10px;
	display:inline-block !important;
	vertical-align:middle !important;
}

.daisyconEnergyRowAanbiederPriceContainer, .daisyconEnergyRowAanbiederGoContainer, .daisyconEnergyRowAanbiederDescriptionContainer{
	float:left;
	width:33.33333%;
	text-align:center;
	display:table;
}
.daisyconEnergyRowAanbiederPrice, .daisyconEnergyRowAanbiederGo, .daisyconEnergyRowAanbiederDescription{
	width:100%;
	height:100%;
    vertical-align: middle;
    display:table-cell;
	text-align:center;
}
.daisyconEnergyRowAanbiederDescription{
	text-align:left;
}
.daisyconEnergyRowAanbiederPrice a{
	vertical-align: middle;
	font-size:200%;
	color:#000;
	text-decoration:none;
}
.daisyconEnergyCrossPrice a{
	text-decoration: line-through;
	font-size:100%;
	color:#e74c3c;
}
.daisyconEnergySpot{
	background:#e4eef4;
	padding: 2px 5px;
	color: #333333;
	border-radius: 2px;
	float:left;
	display:block;
	clear:both;
}
.daisyconEnergySpotGroen{
	background:#96c737;
	padding: 2px 5px;
	color: #FFF;
	border-radius: 2px;
	float:left;
	display:block;
	clear:both;
}
.daisyconEnergyPackageLooptijd{
	margin-bottom:5px;

}

.daisyconEnergyAction{
	margin-top:5px;
}

.daisyconEnergySpotBesparing{
	background:#FFF;
	max-width: 200px;
	margin:0 auto;
	padding:5px;
	text-align:center;
	margin-top:5px;
	border-radius:2px;
}
.daisyconEnergyNoResultsFound{
	border-radius:2px;
	background:#f3f3f3;
	border:1px solid #eaeaea;
	margin-bottom:10px;
	padding:10px;
	width:97%;
	margin-left: 3%;
	margin-top:10px;
}

/* Meer informatie */
.daisyconEnergyInfo{
	height:0px;
	overflow:hidden;
	margin-top:-2px !important;
	border:1px solid #dddddd;
	border-top:none;
	background:#FFF;
	margin-top:10px;
	position: relative;
	border-radius:2px;
	position:relative;
	margin-left:3%;
	z-index:3;
	display:none;
}

.daisyconEnergyRowMoreInfo{
	text-align:center;
	padding:5px;
	border-top:1px solid #eaeaea;
	cursor:pointer;
}

.daisyconEnergieTableContainer{
	width:98%;
	margin:1%;
	border:1px solid #eaeaea;
	border-radius:2px;
}

.daisyconEnergyRowMoreInfo:hover{
	background:#eaeaea;
}

.daisyconEnergyRowMoreInfo img{
	display:inline-block !important;
	vertical-align:middle !important;
	height:8px !important;
	margin-right:10px !important;
	border:none !important;
}

.daisyconEnergyMoreInfoRow{
	padding:5px;
	border-bottom:1px solid #eaeaea;
}

.daisyconEnergyMoreInfoColl{
	float:left;
	width:50%;
}

.daisyconEnergyMoreInfoRowSub{
	display: none;
	font-style: italic;
}

.daisyconEnergyMoreInfoRowSub .daisyconEnergyMoreInfoColl:first-child{
	padding-left:20px;
}

.daisyconEnergyMoreInfoRow .daisyconEnergyMoreInfoColl:first-child{
    padding-left:15px;
}

.daisyconEnergyMoreInfoRowMore .daisyconEnergyMoreInfoColl:first-child:before{
    content: '\25ba';
    padding-right: 5px;
    font-size:8px;
    margin-left:-15px;
    margin-top:-4px;
    vertical-align: middle;
}

.daisyconEnergyMoreInfoRowHead{
	background:#F3F3F3;
	line-height:24px;
	border-bottom:1px solid #eaeaea;
}
.daisyconEnergyMoreInfoRowHeadSpec{
	font-size:80%;
}

.daisyconEnergyMoreInfoRowMore{
	cursor: pointer;
}

.daisyconEnergyMoreInfoRowTotal {
	font-weight:bold; 
	border-top:2px solid #eaeaea; 
	margin-top:10px;
	padding-top:10px;
	padding-bottom:10px;
}

/* Button */
.daisyconEnergyGo{
	background:#00a6da;
	color:#FFF;
	height: 40px;
	line-height:40px;
	border-radius:2px;
	cursor:pointer;
	min-width:120px;
	text-decoration:none;
	width:100%;
	margin-bottom:5px;
	display:block;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
	font-size:120%;
	max-width: 200px;
	margin: 0 auto;
}

.daisyconEnergyGoText{
	display:block;
	width:75%;
	float:left;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;
	text-align:center;
}
.daisyconEnergyGoElement{
	display:block;
	width:25%;
	float:right;
	font-size:140%;
	padding-left:2px;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
}
.daisyconCheckZipcode{
	color:#000 !important;
	height: 40px;
	line-height:40px;
	border-radius:2px;
	background:#CCC;
	cursor:pointer;
	text-decoration:none;
	width:100%;
	margin-bottom:5px;
	display:block;
	font-size:100%;
	margin: 0 auto;
	text-align:center;
	margin-top:10px;
	border-bottom:2px solid #000;
}

.daisyconEnergyTargetUrl{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    padding: 10px 20px;
    padding-right: 15px;
    text-decoration: none !important;
    border-radius: 2px;
    font-size: 120%;
    width:100%;
}

.daisyconEnergyTargetUrl:after {
    content: '»';
    padding-left:10px;
    padding-right:5px;
    font-size:140%;
}
.daisyconEnergyTargetUrl:hover:after {
   padding-left:15px;
   padding-right:0px;
}

.daisyconEnergyTargetUrl:hover {
    opacity: 0.9;
}

/* Calculate popup */
.daisyconEnergyBack, .daisyconEnergyLoaderBack{
	width:100%;
	height:100%;
	position:fixed;
	left:0px;
	background:#000;
	top:0px;
	opacity:0.6;
	z-index:1200;
	display:none;
	cursor:pointer;
}

.daisyconEnergyPartCalculate, .daisyconEnergyLoader, .daisyconEnergyPackagesCompare{
	width:60% !important;
	background:#FFF;
	border-radius:2px;
	position:fixed !important;
	padding:5px;
	top:20%;
	left:20%;
	z-index:1500;
	display:none;
}

.daisyconEnergyLoader{
	text-align:center;
}

.daisyconEnergyLoaderTitle{
	font-size:18px;
	margin-bottom:10px;
	margin-top:20px;
}

.daisyconEnergyLoader img{
	width:200px;
}

.daisyconEnergyPartCalculateButton{
	background:#00a6da;
	color:#FFF;
	width:40%;
	cursor:pointer;
	font-size:18px;
	line-height:40px;
	margin-top:10px;
	margin-bottom:10px;
	margin-left:30%;
	text-align:center;
}

.daisyconEnergyClos{
/*
	position:absolute;
	right:4px;
	background:#eaeaea;
	color:#333333;
	width:30px;
	height:30px;
	border-radius:2px;
	line-height:30px;
	text-align:center;
	top:4px;
	cursor:pointer;
*/
	float:right;
}

/* Blz */
.daisyconEnergyShowAll{
	float:left;
	line-height:25px;
	text-decoration:underline;
	margin-left:20px;
	cursor:pointer;
	margin-top:10px;
}

.daisyconEnergyBlzContainer{
	float:left;
	width:200px;
	border:1px solid #eaeaea;
	text-align:center;
	background:#efefef;
	color:#333333;
	padding:5px 20px 5px 20px;
	border-radius:2px;
	cursor:pointer;
	margin-top:10px;
}

/* Disclaimer */
.daisyconEnergyDisclaimerActive{
	margin-top:20px;
	text-align:center;
	cursor:pointer;
}

.daisyconEnergyDisclaimer{
	margin-top:20px;
	text-align:center;	
	display:none;
	cursor:pointer;
}

/* INPUT FORM*/

/* form starting stylings ------------------------------- */
.daisyconEnergyPopupgroup{ 
	position:relative; 
	margin-bottom:45px; 
}
.daisyconEnergyPopupgroup input {
	font-size:18px;
	padding:10px 10px 10px 5px;
	display:block;
	width:300px;
	border:none;
	border-bottom:1px solid #757575;
}
.daisyconEnergyPopupgroup input:focus {
	outline:none; 
}

/* LABEL ======================================= */
.daisyconEnergyPopupgroup label {
	color:#999; 
	font-size:18px;
	font-weight:normal;
	position:absolute;
	pointer-events:none;
	left:5px;
	top:10px;
	transition:0.3s ease all; 
	-moz-transition:0.3s ease all; 
	-webkit-transition:0.3s ease all;
}

/* active state */
.daisyconEnergyPopupgroup input:focus ~ label, .daisyconEnergyPopupgroup input:valid ~ label        {
  top:-20px;
  font-size:14px;
  color:rgb(97, 97, 97);
}

/* BOTTOM BARS ================================= */
.daisyconEnergyPopupgroup .bar    { position:relative; display:block; width:600px; }
.daisyconEnergyPopupgroup .bar:before,.daisyconEnergyPopupgroup  .bar:after     {
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:rgb(97, 97, 97);
  transition:0.3s ease all; 
  -moz-transition:0.3s ease all; 
  -webkit-transition:0.3s ease all;
}
.daisyconEnergyPopupgroup .bar:before {
  left:50%;
}
.daisyconEnergyPopupgroup .bar:after {
  right:50%; 
}

/* active state */
.daisyconEnergyPopupgroup input:focus ~ .daisyconEnergyPopupgroup .bar:before, .daisyconEnergyPopupgroup input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.daisyconEnergyPopupgroup .highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.daisyconEnergyPopupgroup input:focus ~ .daisyconEnergyPopupgroup .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from { background:rgb(97, 97, 97); }
  to    { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
    from { background:rgb(97, 97, 97); }
  to    { width:0; background:transparent; }
}
@keyframes inputHighlighter {
    from { background:rgb(97, 97, 97); }
  to    { width:0; background:transparent; }
}

/* BUTTON =====================*/
.daisyconEnergyPopupgBtn {
	width: 300px;
	position: relative;
	display: block;
	padding: 0;
	overflow: hidden;
	border-width: 0;
	outline: none;
	border-radius: 2px;
	background-color: rgb(255, 130, 1);
	color: #ecf0f1;
	transition: background-color .3s;
	text-shadow: none;
	font-size:16px;
	cursor:pointer;
}

.daisyconEnergyPopupgBtn span {
  display: block;
  padding: 12px 24px;
}

.daisyconEnergyMailText{
	width:75%;
	float:left;
}

.daisyconEnergyMailButton{
	width:25%;
	float:left;
}

/* ENERGIE EMAIL BOX */
.daisyconEnergyComparatorEmail{
	max-width:300px;
	width:100%;
	border:1px solid #eaeaea;
	border-radius:2px;
	text-align: left;
	margin:10px;
	float:right;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
}


.daisyconEnergyComparatorEmail div{
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
}

.daisyconEnergyComparatorEmailTitle{
	background:#1e72bd;
	font-size:16px;
	padding:20px;
	width:100%;
	color:#FFF;
	text-transform: uppercase;
	font-weight: 700;
	line-height:20px;
	cursor:pointer;
}

.daisyconEnergyComparatorEmailTitleFree{
	background:#71aadd;
	padding:2px 5px;
	color:#000;
	border-radius:2px;
}

.daisyconEnergyComparatorEmailContent{
	padding:20px;
}

.daisyconEnergyComparatorEmailText{
	padding:10px;
	border:1px solid #eaeaea;
	border-radius:2px;
	width:100%;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box; 
}

.daisyconEnergyComparatorEmailButton{
	margin-top:10px;
	line-height:30px;
	font-size:16px;
	color:#FFF;
	background:#ff7200;
	padding:10px;
	border-radius:2px;
	cursor:pointer;
}

.daisyconEnergyComparatorEmailButton:hover{
	opacity: 0.9;
}

.daisyconEnergyComparatorEmailButton:before{
	content: '\25ba';
	margin-right:5px;
}

.daisyconEnergyComparatorEmailSubtext{
	margin-top:5px;
	color:#CCC;
	font-size:12px;
}

.daisyconEnergyComparatorEmailOnderdeel{
	border-top:1px dashed #eaeaea;
	padding:10px 20px;
	background:#f3f3f3;
	font-size:10px;
}