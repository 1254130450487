.is-order{
  .modal{
    .modal-inner{
      .modal-intro{

      }
      .modal-content{
        font-size: 1.4rem;
        #error_callback{
          ul{
            padding: 2rem;
            li{
              display: list-item;
              padding: 0.25rem 0;
            }
          }
        }
      }
    }
  }
}