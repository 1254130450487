.tile.is-offer{
  .tile-content {
    @include outer-container();
    display: block;
    .cta{
      @include span-columns(24);
      border-radius: $global-border-radius / 1.5;
      background: $blue;
      color: $white;
      text-align: center;
      padding: 1rem;
      margin-top: 1rem;
    }
    &:hover {
      .cta {
        background: darken($blue,10);
      }
    }
  }
  h3{
    @include span-columns(24);
    @include omega();
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  @include media($xs-only){
    h3{
      font-size: 2.2rem;
    }
  }
  @include media($sm-only){
    // default: is-sm-half
    h3{
      font-size: 1.8rem;
    }
  }
  @include media($md-only){
    // default: is-md-half
    h3{
      font-size: 2.2rem;
    }
    &.is-md-third{
      h3{
        font-size: 1.7rem;
      }
    }
  }
  @include media($lg-only){
    // default: is-lg-third
  }
  @include media($xl-only){
    // default: is-lg-fourth
  }

  // is not hero
  &.is-normal{
    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){}
    @include media($lg-only){}
    @include media($xl-only){}
  }

  // is hero
  &.is-hero{
    @include media($xs-only){}
    @include media($sm-only){
      h3{
        font-size: 2.2rem;
      }
    }
    @include media($md-only){}
    @include media($lg-only){
      h3{
        font-size: 2.2rem;
      }
    }
    @include media($xl-only){
      h3{
        font-size: 2.4rem;
      }
    }
  }

  // is featured
  &.is-featured{
    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){}
    @include media($lg-only){}
    @include media($xl-only){}
  }
}