.hero.is-gsm{

    .offer-wrapper:not(.slick-slider) {
        .hero-tile-wrapper {
            display: none !important;
            &:first-child{
                margin-top: 20px;
                margin-bottom: 100px;
                display: block !important;
                position: relative;
                top: 40px !important;
            }
        }
    }

    .hero-tile-wrapper{
        margin-top: 10px !important;
        top: 40px !important;
    }

    .wrapper{
        //padding-top: 2rem;
        transition: padding 0.5s ease-in-out;
    }

    @include media($xxs-only){
        .wrapper {
            padding-bottom: 8.5rem;
        }
        .hero-tile-wrapper {
            margin-top: 110px !important;
            top: -90px !important;
        }
    }

    @include media($xs-only){
        .wrapper {
            padding-bottom: 6rem;
        }
        .hero-tile-wrapper {
            margin-top: 110px !important;
            top: -90px !important;
        }
    }

    @include media($xs-down){
        .hero-tile-wrapper {
            padding-right: 22px;
        }
    }

    @include media($sm-only){
        .wrapper {
            padding-bottom: 7.5rem;
        }
    }

    @include media($md-only){
        .wrapper{
            padding-bottom: 5rem;
        }
    }
    @include media($lg){
        .hero-tile-wrapper{
            top: 40px !important;
        }

        &[data-subscription=tmobile_basis]{}
        &[data-subscription=tmobile_go]{
            background-color: $t-mobile;
            .slogan-extended{
                color: $white;
            }
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-tmobile_go.jpg');
            }
        }
        &[data-subscription=ben_basis]{
            background-color: #d0dbdd;
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-ben_basis.jpg');
            }
        }
        &[data-subscription=tele2_basis] {
            background-color: $tele2;
            .slogan-extended {
                color: $white;
            }
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-tele2_basis.jpg');
                //background-image: url('#{$image-path}/global/hero/miky-hero-s6-edge.jpg');
            }
            .wrapper {
                transition: padding 0.5s ease-in-out;
            }
        }

        &[data-subscription=tmobile_basis]{
            //background-color: $t-mobile;
            .slogan-extended{
                //color: $white;
            }
            .wrapper {
                //background-image: url('#{$image-path}/global/hero/miky-hero-tmobile_basis.jpg');
            }
        }
        &[data-subscription=tmobile_go]{
        }
        &[data-subscription=ben_basis]{
            background-color: #d0dbdd;
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-ben_basis.jpg');
            }
        }
        &[data-subscription=vodafone_basis]{
            //background-color: $vodafone;
            background-color: $white;
            //TODO: activate _fireworks partial in order to use code below
            //.before, .after {
            //    position: absolute;
            //    width: 5px;
            //    height: 5px;
            //    border-radius: 50%;
            //    box-shadow: $box-shadow2;
            //    @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
            //}
            //.after {
            //    @include animation-delay((1.25s, 1.25s, 1.25s));
            //    @include animation-duration((1.25s, 1.25s, 6.25s));
            //}
            .slogan-extended{
                color: $white;
            }
            .wrapper {
                //background-image: url('#{$image-path}/global/hero/miky-hero-vodafone_basis.jpg');
                background-image: url('#{$image-path}/global/hero/miky-hero-vodafone_basis-alt.jpg');
            }
        }
        &[data-subscription=simonly]{
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-sim_only.jpg');
            }
        }
        &[data-subscription=gsmtopdeal]{
            .wrapper {
                background-image: url('#{$image-path}/global/hero/miky-hero-gsmtopdeal.jpg');
            }
        }
        &[data-subscription=los_basis]{
            //background-color: $red;
            .wrapper {
                //background-image: url('#{$image-path}/global/hero/miky-hero-los_basis.jpg');
            }
        }

    }
    @include media($xl){
        .hero-tile-wrapper{
            top: 50px !important;
        }
    }
}


