.is-gsm {
  .offer-intro {
    .offer-phone-image {
      @include omega();
      @include clearfix;
    }
    .phone-colors {
      button {
        transition: all $global-transition-duration-medium ease-in-out;
        display: block;
        position: relative;
        border: 2px solid $medium-dark-grey;
        border-radius: $global-border-radius-half;
        width: 2.5rem;
        height: 4.5rem;
        margin-bottom: 1rem;
        &:before, &:after {
          transition: all $global-transition-duration-medium ease-in-out;
        }
        &:hover, &:focus, &.is-active {
          &:before, &:after {
            position: absolute;
            margin-right: -1rem;
            top: 50%;
            content: "";
          }
          &:before {
            border-top: 1.2rem solid transparent;
            border-bottom: 1.2rem solid transparent;
            border-left: 1.2rem solid;
            border-left-color: inherit;
            right: -0.3rem;
            margin-top: -1.2rem;
          }
          &:after {
            right: 0;
            margin-top: -1rem;
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid transparent;
            border-left: 1rem solid;
          }
        }
        &:not(.is-active):focus {
          border-color: darken($blue, 10);
          &:before {
            border-left-color: darken($blue, 10);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &.is-vertical{

      }
      &.is-horizontal{

      }
    }
    .phone-image {
    }
    .phone-thumbs-wrapper {
      @include span-columns(6);
      margin-top: 1rem;
      .thumb-wrapper {
        display: block;
        height: 78px;
        text-align: center;
        margin-bottom: 1rem;
        padding: 0.5rem;
        .thumb {
          height: inherit;
          padding-bottom: 1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .offer-phone-teaser-text,.offer-phone-ribbon {
      @include span-columns(24);
      @include omega();
      font-size: 1.4rem;
      line-height: 1.4;
      margin-bottom: 2rem;
    }
    .offer-phone-ribbon{
      .ribbon{
        padding: 1rem;
      }
    }
    .info-cta {
      &:after {
        @extend .fa;
        content: "\f054";
        margin-left: 0.2rem;
        font-size: 1.2rem;
      }
    }
    .offer-phone-teaser-extra {
      @include span-columns(24);
      @include omega();
      font-size: 1.4rem;
    }
    .specs-wrapper {
      margin-bottom: 2rem;
    }
    .deal-wrapper {
      margin-bottom: 2rem;
      line-height: 1.4;
      .deal-cta {
        &:after {
          @extend .fa;
          content: "\f054";
          margin-left: 0.2rem;
          font-size: 1.2rem;
        }
      }
    }
    .specs {
      margin-bottom: 0.5rem;
      li {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
        &:before {
          @extend .fa;
          font-size: 1.2rem;
          content: "\f00c";
          position: absolute;
          left: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .specs-cta {
      &:after {
        @extend .fa;
        content: "\f054";
        margin-left: 0.2rem;
        font-size: 1.2rem;
      }
    }
    .review-wrapper {
      .video-wrapper {
      }
      .video-comment {
        margin: 0.5rem 0 1rem 0;
      }
      .review {

      }
      .review-rating {
        margin-bottom: 1rem;
        .star-wrapper {
          display: inline-block;
          margin-top: 0.5rem;
        }
        .fa {
          color: $yellow;
        }
      }
      .review-cta {
        &:after {
          @extend .fa;
          content: "\f054";
          margin-left: 0.2rem;
          font-size: 1.2rem;
        }
      }
    }

    @include media($xs-only) {
      .phone-thumbs-wrapper {
        .thumb-wrapper {
          height: 90px;
        }
      }
      .specs-wrapper {
        @include span-columns(12);
      }
      .specs {
        margin-bottom: 1.5rem;
      }
      .review-wrapper {
        @include span-columns(12);
        @include omega();
        @include gutter-all-mq('padding-left', 'xs');
        border-left: 1px solid $medium-grey;
      }
    }
    @include media($sm-only) {
      .phone-thumbs-wrapper {
        .thumb-wrapper {
          height: 92px;
        }
      }
      .specs-wrapper {
        @include span-columns(12);
      }
      .specs {
        margin-bottom: 1.5rem;
      }
      .review-wrapper {
        @include span-columns(12);
        @include omega();
        @include gutter-all-mq('padding-left', 'sm');
        border-left: 1px solid $medium-grey;
      }
    }
    @include media($md-only) {
      .phone-thumbs-wrapper {
        .thumb-wrapper {
          height: 109px;
        }
      }
      .specs-wrapper {
        @include span-columns(12);
      }
      .specs {
        margin-bottom: 1.5rem;
      }
      .review-wrapper {
        @include span-columns(12);
        @include omega();
        @include gutter-all-mq('padding-left', 'md');
        border-left: 1px solid $medium-grey;
      }
    }
    @include media($lg) {
      margin-bottom: 2rem;
    }
    @include media($lg-only) {
      .offer-phone-image {
        @include span-columns(12);
      }
      .phone-image {
      }
      .phone-thumbs-wrapper {
        margin-top: 2.7rem;
        //@include span-columns(20);
        //@include shift(4);
        //.phone-thumbs{
        //  @include clearfix;
        //  margin: 0.5em 2em;
        //  &.count-3{
        //    .thumb-wrapper{
        //      @include span-columns(8);
        //    }
        //  }
        //}
      }
      .offer-phone-teaser-text, .offer-phone-ribbon {
        @include span-columns(12);
        @include omega();
        font-size: 1.5rem;
      }
      .offer-phone-teaser-extra {
        @include span-columns(12);
        @include omega();
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
      .specs {
        @include clearfix;
        li {
          @include span-columns(12);
          @include omega(2n);
        }
      }
      .video-wrapper {
        width: 60%;
      }
    }
    @include media($xl) {
      .offer-phone-image {
        @include span-columns(10);
      }
      .phone-image {
      }
      .phone-thumbs-wrapper {
        margin-top: 3.2rem;
        //@include span-columns(20);
        //@include shift(4);
        //.phone-thumbs{
        //  @include clearfix;
        //  margin: 0.5em 2em;
        //  &.count-3{
        //    .thumb-wrapper{
        //      @include span-columns(8);
        //    }
        //  }
        //}
      }
      .offer-phone-teaser-text, .offer-phone-ribbon {
        @include span-columns(14);
        @include omega();
        font-size: 1.5rem;
      }
      .offer-phone-teaser-extra {
        @include span-columns(14);
        @include omega();
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
      .specs {
        @include clearfix;
        li {
          @include span-columns(10);
          @include omega(2n);
        }
      }
      .video-wrapper {
        width: 70%;
      }
    }

    &.is-sim {
      .offer-phone-image {
        @include span-columns(7);
        padding-right: 1rem;
        margin-bottom: 0;
      }
      .offer-phone-teaser-text {
        @include span-columns(17);
        @include omega();
      }
      //.offer-phone-teaser-extra {
      //  @include span-columns(17);
      //  @include omega();
      //}

      .phone-colors {
        display: none;
      }
      .phone-image {
        @include span-columns(24);
        @include omega();
      }
      @include media($xs-only) {
      }
      @include media($sm-only) {
      }
      @include media($md-only) {
      }
      @include media($lg-only) {
      }
      @include media($xl) {
        .offer-phone-image {
          @include span-columns(5);
          padding-right: 1rem;
          margin-bottom: 0;
        }
        .offer-phone-teaser-text {
          @include span-columns(19);
          @include omega();
        }
        .offer-phone-teaser-extra {
          @include span-columns(19);
          @include omega();
        }
      }
    }

    &.is-phone,
    &.is-info {
      .phone-colors {
        @include span-columns(4);
        margin-top: 1.4rem;
      }
      .phone-image {
        @include span-columns(12);
      }
      @include media($xs-only) {
        .phone-colors {
          @include span-columns(4);
        }
        .phone-image {
          @include span-columns(10);
        }
      }
      @include media($sm-only) {
        .phone-colors {
          @include span-columns(3);
        }
        .phone-image {
          @include span-columns(8);
        }
      }
      @include media($md-down) {
        .offer-phone-image {
          padding-bottom: 2rem;
        }
      }
      @include media($md-only) {
        .phone-colors {
          @include span-columns(3);
        }
        .phone-image {
          @include span-columns(11);
        }
      }
      @include media($lg-only) {
        .phone-image {
          @include span-columns(14);
          padding: 0 1rem 0 0;
        }
        .phone-thumbs-wrapper {
          @include span-columns(6);
          @include omega();
          margin-top: 1rem;
          .thumb-wrapper{
            height: 100px;
          }
        }
        .phone-colors {
          @include span-columns(4);
        }
      }
      @include media($xl) {
        .phone-image {
          @include span-columns(14);
          @include omega();
          padding: 0 1rem 0 0;
        }
        .phone-thumbs-wrapper {
          @include span-columns(6);
          @include omega();
          margin-top: 1rem;
          .thumb-wrapper{
            height: 100px;
          }
        }
        .phone-colors {
          @include span-columns(4);
        }
      }
    }
  }
}