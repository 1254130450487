.tile.is-gsm.is-offer.is-phone{
    .offer-image{
        @include span-columns(10);
    }
    .offer-content{
        @include span-columns(14);
    }

    .usp{
        margin-top: 1.5rem;
        color: darken($medium-grey,20);
        font-size: 1.4rem;
        max-height: 4.2rem;
    }

    @include media($xxs-only){
        h3{
            font-size: 2.2rem;
        }
    }

    @include media($xs-only){
        .offer-content {
            font-size: 1.8rem;
        }
    }

    @include media($sm-only){
        .offer-image{
            @include span-columns(10);
        }
        .offer-content{
            @include span-columns(14);
            font-size: 1.5rem;
        }

        .usp{
            margin-top: 1rem;
        }

        &.is-sm-full{

        }
    }

    @include media($md-only){

    }

    @include media($lg-only){
        .usp{
            font-size: 1.6rem;
            line-height: 2rem;
            max-height: 8rem;
        }
        &.is-lg-half{

        }
    }

    @include media($xl-only){
        .usp{
            font-size: 1.6rem;
            line-height: 2rem;
            max-height: 6rem;
        }
        &.is-xl-third{

        }
        &.is-xl-half{

        }
    }

    // is not hero
    &.is-normal{
        h3{
            text-align: center;
        }
        .offer-content{
            @include omega();
            padding-left: 0.5rem;
        }
        @include media($xs-only){
            .offer-image{
                @include span-columns(9);
            }
            .offer-content {
                @include span-columns(15);
                @include omega();
            }
        }
        @include media($sm-only){}
        @include media($md-only){
            .offer-image{
                @include span-columns(8);
            }
            .offer-content {
                @include span-columns(16);
                @include omega();
            }

            &.is-md-third{
                .offer-image{
                    @include span-columns(10);
                }
                .offer-content{
                    @include span-columns(14);
                    @include omega();
                    font-size: 1.4rem;
                }
            }
        }
        @include media($lg-only){}
        @include media($xl-only){}
    }

    // is hero
    &.is-hero{
        .tile-content{
            position: relative;
        }
        h3{
            text-align: left;
        }
        .wrapper-left{
            display: block;
        }
        .wrapper-right{
            display: block;
        }
        .offer-image{
            @include omega();
            transform:scale(0.95);
            transition: all .2s ease-in-out;
            position: absolute;
            right: 0;
        }
        .usp{
            margin-bottom: 1.5rem;
        }

        &:hover{
            .offer-image{
                transform:scale(1);
            }
        }

        @include media($xxs-only){
            .offer-image {
                @include span-columns(14);
                @include omega();
                top: 30px;
                right: -40px;
            }
        }
        @include media($xs-down){
        }
        @include media($xs){
        }
        @include media($xs-only){
            .offer-content {
                @include span-columns(13);
            }
            .offer-image{
                @include span-columns(11);
                @include omega();
                top: 25px;
                right: -5px;
            }
        }
        @include media($sm){
            .offer-content {
                @include span-columns(16);
            }
            .offer-image{
                @include span-columns(8);
                @include omega();
                right: 0;
            }
            .wrapper-left{
                @include span-columns(8 of 16);
            }
            .wrapper-right{
                @include span-columns(8 of 16);
                @include omega();
            }
            .usp{
                margin-bottom: 0;
            }
        }
        @include media($sm-only){
            .offer-content{
                font-size: 1.5rem;
            }
            .offer-image{
                top: -100px;
            }
        }
        @include media($md-only){
            .offer-content {
            }
            .offer-image{
                top: -106px;
            }
        }
        @include media($lg){
            .offer-content {
                @include span-columns(17);
                font-size: 1.8rem;
            }
            .offer-image{
                @include span-columns(7);
                @include omega();
                right: 0;
            }
        }
        @include media($lg-only){
            .offer-content {
            }
            .offer-image{
                top: -98px;
            }
        }
        @include media($xl-only){
            .offer-content {
            }
            .offer-image{
                top: -115px;
            }
        }
    }

    // is featured
    &.is-featured{
        @include media($xs-only){}
        @include media($sm-only){}
        @include media($md-only){}
        @include media($lg-only){}
        @include media($xl-only){}
    }
}
