// TODO add is-order class to body (see lib variable $order_status == true)
.is-order{
  .is-order-menu{
    a{
      cursor: default;
      pointer-events: none;
      &:hover{

      }
    }
    li{
      &:not(.is-active), &:not(.is-current){
        &:hover{
          a{
            background: $blue !important;
          }
          &:after{
            border-color: transparent transparent transparent $blue !important;
          }
        }
      }
    }
  }
  main{
    padding: 3rem 0;
  }
}
