.template_filter, .template_filter-sim-only{
  &.page_vodafone{
      //TODO: activate _fireworks partial in order to use code below
      //.before, .after {
      //    position: absolute;
      //    width: 5px;
      //    height: 5px;
      //    border-radius: 50%;
      //    box-shadow: $box-shadow2;
      //    @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
      //}
      //.after {
      //    @include animation-delay((1.25s, 1.25s, 1.25s));
      //    @include animation-duration((1.25s, 1.25s, 6.25s));
      //}
  }
  .filter-with-result{
    margin-top: 2rem;
    margin-bottom: 2rem;
    &.has-no-filters{
      margin-top: 3rem;
    }
  }
  .filter-options{
    .is-filter{
      &.is-bundle{
        label{
          width:42px;
        }
      }
    }
  }
  .filter-result{
    @include span-columns(24);
  }
  .empty-filter{
    padding: 1rem 2rem;
    line-height: 2rem;
    background: $light-blue-grey;
    color: darken($blue, 10);
    h3{
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 1.5rem;
    }
  }
  .offer-wrapper{
    @include outer-container();
  }
  .tile{
    @include gutter-all-mobile("margin-bottom");
    @include gutter-all-desktop("margin-bottom", 0.75);
    border: 2px solid $light-grey;
  }
    @include media($xs-only){
        .offer-wrapper{
            margin-left: 4rem;
            margin-right: 4rem;
        }
    }
  @include media($xs){

    .filter-options{
      &.is-mobile{
        .is-filter{
          @include span-columns(12);
          @include omega(2n);
        }
      }
    }
  }
  @include media($sm){
    .tile {
      &.is-normal {
        @include span-columns(12);
        &.is-second {
          @include omega;
        }
      }
    }
  }
  @include media($md){
    .page-content{
      padding: 2rem 0 0 0;
    }
    .filter-options{
      &.is-desktop{
        @include span-columns(6);
      }
    }
    .filter-result{
      @include span-columns(18);
    }
  }
  @include media($lg){}
  @include media($xl){
    .filter-options{
      &.is-desktop{
        @include span-columns(4);
      }
    }
    .filter-result{
      @include span-columns(20);
    }
    .tile{
      &.is-normal {
        @include span-columns(8);
        &.is-second {
          @include span-columns(8);
        }
        &.is-third {
          @include omega();
        }
      }
    }
  }
}
