.accordion {
    border-radius: $global-border-radius-half;
    color: $white;
    > li{
        &:first-child{
            border-radius: $global-border-radius-half $global-border-radius-half 0 0;
        }
        &:last-child{
            border-radius: 0 0 $global-border-radius-half $global-border-radius-half;
            ul.submenu{
                border-radius: 0 0 $global-border-radius-fourth $global-border-radius-fourth;
            }
        }
        &:first-child:last-child{
            border-radius: $global-border-radius-half;
        }
        > a{
            position: relative;
            &:after{
                position: absolute;
                right: 1rem;
                @extend .fa;
                content: '\f078';
                margin-left: 0.5rem;
                font-size: 1.4rem;
                top: 50%;
                margin-top: -7px;
            }
        }
    }
    li {
        border-bottom: 1px solid $white;
        &:last-child{
            border: none;
        }
        > a {
            display: block;
            padding: 1rem;
            text-decoration: none;
            line-height: 1.2;
        }
    }

    ul.submenu {
        background-color: $white;
        color: $black;
        display: none;
        li {
            &:focus,
            &:hover {
                background-color: darken($white, 10);
            }
            &.active{
                background: $light-grey;
                font-weight: 700;
            }
        }
    }

    .is-expanded {
        display: block;
        padding-bottom: 0;
        > a{
            &:after {
                content: '\f077';
            }
        }
    }

    &.is-links{

    }
    &.is-content{
        ul.submenu {
            li {
                padding: 1rem;
            }
        }
    }
    &.is-grey{
        > li{
            background-color: $dark-grey;
            &.active{
                background-color: darken($dark-grey,10);
            }
        }
        > li {
            &:focus,
            &:hover {
                background-color: darken($dark-grey,10);
            }
        }
        ul.submenu {
            > li {
                &:first-child {
                    border-top: 2px solid darken($dark-grey,10);
                }
            }
        }
    }
    &.is-blue{
        > li{
            background-color: $blue;
            &.active{
                background-color: darken($blue,10);
            }
        }
        > li {
            &:focus,
            &:hover {
                background-color: darken($blue,10);
            }
        }
        ul.submenu {
            > li {
                &:first-child {
                    border-top: 2px solid darken($blue,10);
                }
            }
        }
    }

    &.is-grey-submenu{
        ul.submenu{
            background: $lightest-grey;
        }
    }

    &.is-initial-open{
        ul.submenu{
            display: block;
        }
    }

    &.is-direct-link{
        > li{
            > a{
                &:after{
                    position: relative;
                    right: 0;
                    content: '\f054';
                }
            }
        }
    }

    &.is-separate{
        > li{
            border-radius: $global-border-radius-half;
            ul.submenu{
                border-radius: 0 0 $global-border-radius-fourth $global-border-radius-fourth;
            }
        }
    }
}
