.expander {
    //display: none;
    .expander-trigger {
        display: block;
    }

    .expander-content{
    }

    &.is-info{
        position: relative;
        margin-top: 1.5rem;
        .expander-trigger{
            display: inline-block;
            padding: 0.5rem;
            font-size: 1.4rem;
            color: $blue;
            &:hover{
                color: darken($blue, 10);
            }
            &:before{
                @extend .fa;
                content: '\f068';
                font-size: 1.2rem;
                margin-right: 0.5rem;
            }
            &.expander-hidden{
                &:before{
                    content: '\f067';
                }
            }
        }
        .expander-content{
            background: $white;
            position: relative;
            margin-top: 1rem;
            > h3{
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }
        }
        .entry-content{
            font-size: 1.4rem;
            h2,h3{
                font-size: 1.8rem;
            }
            h4{
                font-size: 1.6rem;
            }
            blockquote{
                line-height: 2rem;
            }
            > *:last-child{
                margin-bottom: 0;
            }
        }
        .expander-close{
            position: absolute;
            right: 0.8rem;
            top: 0.7rem;
            font-size: 2.4rem;
            color: $blue;
            &:hover{
                color: darken($blue, 10);
            }
        }
    }
    &.is-offer{
        @include gutter-all('top',-1);
        @include gutter-all('margin-bottom',-1);
    }
    &.is-order{
        top: -2rem;
        &.is-condition{
            top: 0;
        }
        margin-top: 0;
        .cta{
            background: $blue;
            color: $white;
            padding: 1rem 0.5rem;
            &:hover{
                background: darken($blue, 10);
            }
        }
    }
    &.is-ilt{
        margin-bottom: -2.5rem;
    }
    &.has-border{
        .expander-content{
            padding: 1rem 2.5rem 1rem 1rem;
            border: 2px solid $light-grey;
            border-radius: $global-border-radius;
        }
    }

    .expander-hidden {
    }

    .expander-hidden + .expander-content {
        //display: none;
    }
}
