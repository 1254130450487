.is-gsm {
  &.template_order-form {
    .control-row{
      input{
        &.is-highlight{
          border-color: $green;
        }
      }
    }
    .modal-secci{
      .modal-inner{
        width: 95%;
        max-height: 70%;
        max-width: 1024px;
        min-width: 320px;
      }
    }
  }
}