.is-gsm {
  .overview-providers {
    background: $yellow;
    .tile {
      background: $white;
      border: 2px solid $dark-grey;
    }
    @include media($xs) {
    }
    @include media($sm) {
    }
    @include media($md) {
      .tile {
        @include span-columns(12);
        @include omega(2n);
        margin-bottom: 0;
      }
    }
    @include media($lg) {
    }
    @include media($xl) {
    }
  }
}