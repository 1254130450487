.is-gsm {
  .offer-phone-color {
    .phone-colors {

    }
    button {
      transition: all $global-transition-duration-short ease-in-out;
      position: relative;
      width: 4rem;
      height: 4rem;
      margin-right: 0.5rem;
      border: 2px solid $white;
      outline: 2px solid $medium-dark-grey;
      outline: 2px solid transparent;
      &:hover {
        border-color: $white;
        outline-color: $blue;
      }
      &:focus, &.is-active {
        border-color: $white;
        outline-color: darken($blue, 10);
      }
    }
  }
}