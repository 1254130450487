.is-gsm {
  .overview-offer-types {
    background: $blue;
    color: $white;
    .wrapper{
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
    .tile {
      background: $white;
      color: $blue;
      position: relative;
      h3{
        position: relative;
        display: inline-block;
        padding-left: 3rem;
        &:before{
          @extend .fa;
          content: $fa-var-chevron-circle-right;
          margin-right: 1rem;
          font-size: 2.4rem;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -1.2rem;
        }
      }
      &:hover {
        background: darken($blue, 20);
        color: $white;
      }
      &.is-yellow{
        background: $yellow;
        color: $black;
        &:hover{
          background: $black;
          color: $yellow;
        }
      }
      &.has-miky-only{
        @include gutter-all('margin-bottom', 1, 1);
      }
      .is-miky-only{
        text-align: center;
        position: absolute;
        bottom: -26px;
        background: $white;
        color: $black;
        padding: 5px 10px;
        width: 130px;
        left: 45px;
        left: calc(50% - 65px);
        border-radius: 0 0 6px 6px;
      }
    }
    @include media($xs) {
      .tile {
        @include span-columns(12);
        @include omega(2n);
      }
    }
    @include media($sm) {
    }
    @include media($md) {
      .tile {
        @include span-columns(8);
        @include omega-reset(2n);
        @include omega(3n);
        //&:last-child{
        //  @include shift(8);
        //}
      }
    }
    @include media($lg) {
      .tile {
        @include span-columns(6);
        @include omega-reset(3n);
        @include omega(4n);
        //&:last-child{
        //  @include shift(0);
        //}
      }
    }
    @include media($xl) {
    }
  }
}
