// Basic grid settings
$column: modular-scale(3, 1em, $golden);
$gutter: modular-scale(1, 3em, $golden);
$fg-column: $column;
$fg-gutter: $gutter;
$grid-columns: 24;
$border-box-sizing: true;
$default-feature: min-width; // Default @media feature for the breakpoint() mixin
$default-layout-direction: LTR;

// Constrained width
$max-width: 80em;
$max-width-xxs: 20em;   // 320/16
$max-width-xs:  28em;   // 448/16
$max-width-sm:  36em;   // 576/16
$max-width-md:  48em;   // 768/16
$max-width-lg:  64em;   // 1024/16
$max-width-xl:  80em;   // 1280/16

// Visual Grid
$visual-grid: false;
$visual-grid-color: #000;
$visual-grid-opacity: 0.1;
$visual-grid-index: front;
$disable-warnings: true;
