// TODO optimize/restructure

// `Widths
//----------------------------------------------------------------------------------------------------

.input-50 {
  width: 50px;
}

.input-75 {
  width: 75px;
}

.input-100 {
  width: 100px;
}

.input-150 {
  width: 150px;
}

.input-200 {
  width: 200px;
}

.input-250 {
  width: 250px;
}

.input-300 {
  width: 300px;
}

.input-full {
  width: 100%;
}

// `UI Consistency
//----------------------------------------------------------------------------------------------------

fieldset{
  margin: 0;
  padding: 0;
  border: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input,
//button,
select,
textarea {
  // Suppress red glow that Firefox
  // adds to form fields by default.
  &:invalid {
    box-shadow: none;
  }

  &:focus {
    // for Opera
    z-index: 1;
  }
}

input[type="file"],
input[type="radio"],
input[type="checkbox"] {
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}

input[type="file"]::-webkit-file-upload-button{
  height: 2.6rem;
  margin: 0.2rem 0;
}

//button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  background: $medium-grey;
  background-clip: padding-box;
  border: none;
  cursor: pointer;
  color: $white;
  display: inline-block;
  outline: 0;
  overflow: visible;
  margin: 0;
  padding: 0 10px;
  text-shadow: none;
  text-decoration: none;
  vertical-align: top;
  width: auto;
  height: 3rem;
  line-height: 3rem;

  // Helvetica Neue present, because it works better
  // for line-height on buttons than Arial, on OS X.
  font-size: 1.4rem;
  font-family: "Museo Sans", "Helvetica Neue", Arial, "Liberation Sans", FreeSans, sans-serif;

  // Base64 encoded "../images/button.png' with IE7 fallback
  //background: #ddd url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAAAAADhgtq/AAAAHElEQVQoz2P8z4ADsOCW+UeGnsFs2nDzz5AMHQA+JRlAyyOraAAAAABJRU5ErkJggg==) repeat-x;

  &:hover {
    background: darken($medium-grey,10);
    text-decoration: none;
  }

  &:active {
    background: darken($medium-grey,20);
  }

  // Kill phantom spacing and dotted
  // border that appears in Firefox.
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  box-sizing: border-box;
  background-clip: padding-box;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid;
  border-color: $light-grey;
  color: black;
  outline: 0;
  margin: 0;
  padding: .6rem;
  text-align: left;
  font-size: 1.3rem;
  height: 3rem;
  vertical-align: top;

  // Leaving out Helvetica Neue, to not throw off size="..."
  // on inputs. Arial is more reliable, on Windows and OS X.
  font-family: "Museo Sans", Arial, "Liberation Sans", FreeSans, sans-serif;

  //placeholder color
  &::-webkit-input-placeholder {
    color: darken($light-grey,10);
    font-weight: 300;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: darken($light-grey,10);
    font-weight: 300;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    color: darken($light-grey,10);
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    color: darken($light-grey,10);
    font-weight: 300;
  }

  &:hover{
    border-color: darken($light-grey, 10);
  }

  &:focus {
    border-color: darken($light-grey, 20);
  }

  &[disabled],
  &[readonly]{
    background-color: #eee;
    color: $medium-grey;
    &:hover{
      border-color: $light-grey;
    }
    &:focus{
      border-color: $light-grey;
    }
  }
}

input[type="file"]{
  font-family: "Museo Sans", Arial, "Liberation Sans", FreeSans, sans-serif;
  font-size: 1.3rem;
  height: 3rem;
  vertical-align: top;
}

//textarea,
//input,
//select{
//  &:required{
//  }
//  &:required:valid{
//    border-color: $green;
//  }
//  &:required:focus:invalid{
//    border-color: $red;
//  }
//}
//
//textarea,
//input[type="date"],
//input[type="datetime"],
//input[type="datetime-local"],
//input[type="email"],
//input[type="month"],
//input[type="number"],
//input[type="password"],
//input[type="search"],
//input[type="tel"],
//input[type="text"],
//input[type="time"],
//input[type="url"],
//input[type="week"]{
//  &:required{
//    background: #fff url(#{$image-path}/global/red-asterisk.png) no-repeat 98% center;
//    padding: 6px 24px 6px 6px;
//  }
//  &:required:valid{
//    background: #fff url(#{$image-path}/global/valid.png) no-repeat 98% center;
//  }
//  &:required:focus:invalid{
//    background: #fff url(#{$image-path}/global/invalid.png) no-repeat 98% center;
//    padding: 6px 24px 6px 6px;
//  }
//}

input[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled],
input[readonly],
input[readonly],
select[readonly],
select[readonly] option,
select[readonly] optgroup,
textarea[readonly]{
  box-shadow: none;
  user-select: none;
  color: $medium-grey;
  cursor: default;
}

//button[disabled],
input[type="reset"][readonly],
input[type="submit"][readonly],
input[type="button"][readonly],
input[type="reset"][readonly],
input[type="submit"][readonly],
input[type="button"][readonly]{
  box-shadow: none;
  user-select: none;
  background: $light-grey;
  color: $lightest-grey;
  cursor: default;
}

// Separate rule for Firefox.
// Separate rule for IE, too.
// Cannot stack with WebKit's.
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $medium-grey;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $medium-grey;
}

input.placeholder_text,
textarea.placeholder_text {
  color: $medium-grey;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

// Set height back to normal,
// for Opera, WebKit, and IE.
select[size="0"],
select[size="1"] {
  height: 3rem;
}

// Tweaks for Safari + Chrome.
@media (-webkit-min-device-pixel-ratio: 0) {
  select[size],
  select[multiple],
  select[multiple][size] {
    background-image: none;
    padding-right: 3px;
  }

  select,
  select[size="0"],
  select[size="1"] {
    // Base64 encoded "../images/select_arrow.gif"
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }

  ::-webkit-validation-bubble-message {
    box-shadow: none;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #666), color-stop(1, black));
    border: 0;
    color: white;
    font: 13px/17px "Museo Sans", "Lucida Grande", Arial, "Liberation Sans", FreeSans, sans-serif;
    overflow: hidden;
    padding: 15px 15px 17px;
    text-shadow: black 0 0 1px;
    min-height: 16px;
  }

  ::-webkit-validation-bubble-arrow,
  ::-webkit-validation-bubble-top-outer-arrow,
  ::-webkit-validation-bubble-top-inner-arrow {
    box-shadow: none;
    background: #666;
    border-color: #666;
  }
}

textarea {
  min-height: 40px;
  overflow: auto;
  resize: vertical;
  width: 100%;
}

optgroup {
  color: black;
  font-style: normal;
  font-weight: normal;

  // Font family repeated, for Firefox.
  font-family: "Museo Sans", Arial, "Liberation Sans", FreeSans, sans-serif;

  // Kill phantom spacing and dotted
  // border that appears in Firefox.
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

label{
  font-size: 1.4rem;
  &.is-radio,&.is-checkbox{
    // TODO check filter pages and other places where forms are used
    height: 3rem;
    display: inline-block;
    line-height: 3rem;
    padding-right: 1rem;
    user-select: none;
  }
  &.is-inline{
    height: 3rem;
    line-height: 3.1rem;
    display: block;
  }
  &.is-autoheight{
    height: auto;
    line-height: 1.2;
  }
  &.is-float{
    float: left;
  }
  &.is-disabled,
  &.is-readonly{
    color: $medium-grey;
  }
}

label,
select,
input[type="checkbox"],
input[type="radio"]{
  cursor: pointer;
}

/* === Form hints === */
.form-hint-wrapper{
  position: relative;
}

.form-hint {
  background: $red;
  border-radius: $global-border-radius-fourth;
  color: white;
  font-size: 1.2rem;
  padding: .6rem;
  z-index: 999; /* hints stay above all other elements */
  position: absolute; /* allows proper formatting if hint is two lines */
  display: none;
  min-width: 250px;
  min-height: 3rem;
  line-height: 1.8rem;
  max-width: 100%;
  top: 100%;
  left: 0;
  margin-top: 7px;
  &:before{
    content: "\25b2";
    color: $red;
    position: absolute;
    top: -12px;
    left: 6px;
  }
  //&.right{
  //  left: 100%;
  //  margin-left: .6rem;
  //  top: 0;
  //  &:before{
  //    content: "\25C0";
  //    color: $red;
  //    position: absolute;
  //    top: 7px;
  //    left: -6px;
  //  }
  //}
  &.is-always-visible{
    display: block;
    width: 100%;
    position: relative;
  }
}

input:focus + .form-hint {display: inline;}
input:focus + .form-hint.is-always-visible {display: inline-block;}

input:required.is-valid + .form-hint {background: $green;}
input:required.is-valid + .form-hint::before {color: $green;}

// `Checkboxes and radiobuttons replace
//----------------------------------------------------------------------------------------------------

input[type="checkbox"],
input[type="radio"]{
  position: relative;
  top: 2px;
}

input[type="checkbox"] + .input-icon,
input[type="radio"] + .input-icon{
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 18px;
  display: inline-block;
  position: relative;
  text-align: center;
  top: 2px;
  margin-right: 3px;
  user-select: none;
  &:before{
    font-size: 20px;
    color: #aaa;
  }
}
input[type="checkbox"] + .input-icon{
  &.is-fixed-width {
    top: 2px;
  }
  &:before{
    content: "\f096";
  }
}
input[type="radio"] + .input-icon{
  &.is-fixed-width {
    top: 1px;
  }
  &:before{
    content: "\f1db";
  }
}

label:not(.is-disabled):hover input[type="checkbox"]:not(:checked) + .input-icon,
label:not(.is-disabled):hover input[type="radio"]:not(:checked) + .input-icon,
label:not(.is-readonly):hover input[type="checkbox"]:not(:checked) + .input-icon,
label:not(.is-readonly):hover input[type="radio"]:not(:checked) + .input-icon{
  &:before {
    color: $blue;
  }
}
label:not(.is-disabled):hover input[type="checkbox"]:not(:checked) + .input-icon,
label:not(.is-readonly):hover input[type="checkbox"]:not(:checked) + .input-icon{
  font-weight: 600;
  &:before {
    content: "\f096";
  }
}
label:not(.is-disabled):hover input[type="radio"]:not(:checked) + .input-icon,
label:not(.is-readonly):hover input[type="radio"]:not(:checked) + .input-icon{
  &:before {
    content: "\f10c";
  }
}

input[type="checkbox"]:checked + .input-icon,
input[type="radio"]:checked + .input-icon{
  &:before{
    color: #00679d;
  }
}
input[type="checkbox"]:checked + .input-icon{
  font-weight: 400;
  &:before{
    content: "\f14a";
  }
}
input[type="radio"]:checked + .input-icon{
  &:before{
    content: "\f058";
  }
}
