#secci-form{
  font-size: 1.6rem;
  h2{
    font-size: 2.8rem;
    color: darken($blue, 20);
  }
  h3{
    font-size: 1.8rem;
    background: $blue;
    color: $white;
    padding: 10px 20px;
  }
  h4{
    font-size: 1.8rem;
  }
  section{
    margin: 20px 0;
  }
  .row{
    padding: 10px 0;
    //margin: 0 10px;
    border-bottom: 1px solid lighten($blue, 10);
    &.is-before-full-width{
      border-bottom: none;
    }
    &.is-full-width{
      padding: 10px 10px 5px 10px;
      margin-top: 15px;
      border-width: 2px;
      border-color: $blue;
    }
    &:last-child{
      border-bottom: none;
    }
    &.is-condensed{
      border: none;
      padding: 10px 0 0 0;
    }
    .label, .content{
      display: inline-block;
      vertical-align: top;
      padding: 0 10px;
      font-size: 1.5rem;
      line-height: 1.4;
    }
    .label{
      width: 40%;
      small{
        display: inline-block;
      }
    }
    .content{
      width: 59%;
    }
    @include media($xs-down){
      .label{
        width: 100%;
      }
      .content{
        width: 100%;
      }
    }
    @include media($sm){
      .label{
        width: 40%;
      }
      .content{
        width: 59%;
      }
    }
  }
}