@if $visual-grid == true or $visual-grid == yes {
  body:before {
    display: none;

    /* Grid - minus container gutter */
    @include media($xxs) {
      display: block;
      max-width: $max-width-xxs - ((flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
    @include media($xs) {
      max-width: $max-width-xs - ((flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
    @include media($sm) {
      max-width: $max-width-sm - ((flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
    @include media($md) {
      max-width: $max-width-md - ((flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
    @include media($lg) {
      max-width: $max-width-lg - ((flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
    @include media($xl) {
      max-width: $max-width-xl - ((flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * 4);
    }
  }
}

// ------------------------------------------------------------
// Omega Extended
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

@mixin omega-fix(){
  &.reset-margin-right{
    margin-right: 0;
  }
}

// ------------------------------------------------------------
// Hidden classes
//
// hidden-* classes are removed from layout
// invisible-* classes are hidden visually and from screenreaders, but maintain layout
// ------------------------------------------------------------

/*
 * Hide on set media query and up
 */
.is-hidden {
  display: none !important;
}

@include media($xxs){
  .hidden-xxs-up{
    display: none !important;
  }
  .invisible-xxs-up, .is-invisible {
    visibility: hidden;
  }
}

@include media($xs){
  .hidden-xs-up {
    display: none !important;
  }
  .invisible-xs-up {
    visibility: hidden;
  }
}

@include media($sm){
  .hidden-sm-up {
    display: none !important;
  }
  .invisible-sm-up {
    visibility: hidden;
  }
}

@include media($md){
  .hidden-md-up {
    display: none !important;
  }
  .invisible-md {
    visibility: hidden;
  }
}

@include media($lg){
  .hidden-lg-up {
    display: none !important;
  }
  .invisible-lg-up {
    visibility: hidden;
  }
}


/*
 * Hide on set media query and down
 */
@include media($lg-down){
  .hidden-lg-down {
    display: none !important;
  }
  .invisible-lg-down {
    visibility: hidden;
  }
}

@include media($md-down){
  .hidden-md-down {
    display: none !important;
  }
  .invisible-md-down {
    visibility: hidden;
  }
}

@include media($sm-down){
  .hidden-sm-down {
    display: none !important;
  }
  .invisible-sm-down {
    visibility: hidden;
  }
}

@include media($xs-down){
  .hidden-xs-down {
    display: none !important;
  }
  .invisible-xs-down {
    visibility: hidden;
  }
}


/*
 * Hide on single media query
 */
@include media($xxs-only){
  .hidden-xxs {
    display: none !important;
  }
  .invisible-xxs {
    visibility: hidden;
  }
}

@include media($xs-only){
  .hidden-xs {
    display: none !important;
  }
  .invisible-xs {
    visibility: hidden;
  }
}

@include media($sm-only){
  .hidden-sm {
    display: none !important;
  }
  .invisible-sm {
    visibility: hidden;
  }
}

@include media($md-only){
  .hidden-md {
    display: none !important;
  }
  .invisible-md {
    visibility: hidden;
  }
}

@include media($lg-only){
  .hidden-lg {
    display: none !important;
  }
  .invisible-lg {
    visibility: hidden;
  }
}

@include media($xl-only){
  .hidden-xl {
    display: none !important;
  }
  .invisible-xl {
    visibility: hidden;
  }
}
