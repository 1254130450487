.bar-review{
  background: $lightest-grey;
  .wrapper{
    @extend .container;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  img{
    border-radius: 100%;
    margin: 0 auto;
    display: block;
  }
  .quote{
    margin-top: 1rem;
    padding: 0 2rem;
    text-align: center;
  }
  blockquote{
    font-size: 2rem;
    font-weight: 700;
  }
  .quote-text{
    padding: 0 2rem;
    &:before,&:after{
      color: $yellow;
    }
  }
  .quote-by{
    margin-top: 1rem;
  }
  @include media($xs){}
  @include media($sm){
    .quote-wrapper{
      display: table;
      width: 100%;
    }
    img{
      display: table-cell;
      vertical-align: middle;
      max-width: 12rem;
    }
    .quote{
      display: table-cell;
      vertical-align: middle;
      text-align: left;
    }
  }
  @include media($md){}
  @include media($lg){}
  @include media($xl){}
}