.template_order-form{
  main{
    @include outer-container();
    padding: 3rem 0 5rem 0;
  }
  section{
    fieldset{
     h2{
       font-size: 1.8rem;
     }
    }
  }
  .order-navigation{
    &.is-order{
      margin-top: 2rem;
    }
  }
  .order-service{
    margin-top: 4rem;
  }
  @include media($xs-down){
  }
  @include media($sm){
  }
  @include media($sm-only){
    form.order-form{
      .is-label{
        @include span-columns(10);
      }
      .is-input{
        @include span-columns(14);
      }
    }
  }
  @include media($sm){
    form.order-form{
      .is-label{
        &.is-multiline{
          line-height: 1.4;
        }
      }
    }
  }
  @include media($md-only){
    form.order-form{
      .is-label{
        @include span-columns(8);
      }
      .is-input{
        @include span-columns(16);
      }
    }
  }
  @include media($lg-only){
    form.order-form{
      @include span-columns(18);
      .is-label{
        @include span-columns(8);
      }
      .is-input{
        @include span-columns(16);
      }
    }
    .order-service{
      @include span-columns(6);
      margin-top: 4rem;
    }
  }
  @include media($xl-only){
    form.order-form{
      @include span-columns(18);
      .is-label{
        @include span-columns(6);
      }
      .is-input{
        @include span-columns(12);
      }
    }
    .order-service{
      @include span-columns(6);
    }
  }
}
