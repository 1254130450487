.is-gsm {
  .offer-addendum {
    .legal {
      font-size: 1.4rem;
      color: $medium-grey;
    }

    @include media($sm-down) {
      .legal {
        text-align: center;
      }
    }
  }
}