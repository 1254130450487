.service-contact{
  h3{
    font-weight: 500;
    margin: 2rem 0
  }
  .tile{
    @include gutter-all-mq('margin-bottom','xxs');
    @include span-columns(12);
    background: $blue;
    color: $white;
    text-align: center;
    font-size: 1.8rem;
    &:hover{
      background: darken($blue,10);
    }
    .fa{
      font-size: 2rem;
      //margin-right: 0.5rem;
    }
    h4{
      display: inline-block;
    }
  }
  @include media($xxs-only){
    .tile {
      @include span-columns(12);
      h4{
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
  @include media($xs){
    .tile{
      .fa{
        margin-right: 1rem;
      }
    }
  }
  @include media($sm){
    .tile {
      @include span-columns(8);
    }
  }
  @include media($md){}
  @include media($lg){
    .wrapper{
      background: url("#{$image-path}/global/miky-question.jpg") no-repeat right bottom;
      padding-bottom: 5rem;
    }
    .tile {
      @include span-columns(6);
    }
  }
  @include media($xl){
    .wrapper{
      background-position-x: 85%;
    }
    .tile {
      @include span-columns(4);
    }
  }
}

body{
  &.service-open{
    .is-open{
      display: block;
    }
  }
  &.service-closed{
    .is-closed{
      display: block;
    }
  }
}
