.provider-choice{
  background: $blue;
  color: $white;
  .wrapper{
    @extend .container;
    > h2{
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
  }
  .tile{
    display: table;
    @include gutter-all("margin-bottom");
    transition: all $global-transition-duration-short ease-in-out;
    background: $white;
    color: $blue;
    &:hover{
      background: darken($blue,20);
      color: $white;
    }
    &:last-child{
      margin-bottom: 0;
    }
    h2{
      font-size: 1.8rem;
      margin-bottom: 0.4rem;
    }
    p{
      font-size: 1.4rem;
    }
  }
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .tile{
      @include span-columns(8);
      @include omega(3n);
    }
  }
  @include media($lg){}
  @include media($xl){}
}