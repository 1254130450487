.tile.is-gsm.is-offer{
  position: relative;

  //TODO: replace these selectors with a new class '.is-textfade' (add to all tile components first)
  h3,li,.data,.call{
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    &:after{
      content: "";
      width: 12px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
    }
  }
  h3{
    &:after {
      width: 18px;
    }
  }

  .usp{
    overflow: hidden;
  }

  .ribbon-container {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    left: -2px;
    top: 52px;
    font-weight: 400;
    .ribbon {
      @include transform(rotate(-45deg));
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      font-size: 1.2rem;
      left: -60px;
      padding: 4px 55px;
      position: relative;
      text-align: center;
      top: 28px;
      width: 200px;
      font-weight: 600;
      line-height: 1.6;
      &.is-multiline{
        padding-left: 66px;
        font-size: 1.1rem;
        line-height: 1.4;
      }
    }
  }
  @include media($xs-only) {
    .ribbon-container {
      .ribbon {
      }
    }
  }
  @include media($sm-only) {
    .ribbon-container {
      top: 48px;
      .ribbon {
        left: -70px;
        padding-left: 70px;
        font-size: 1rem;
        &.is-multiline{
          padding-left: 78px;
          font-size: 1rem;
        }
      }
    }
  }
  @include media($md-only) {
    .ribbon-container {
      top: 47px;
      .ribbon {
        left: -70px;
        padding-left: 70px;
        font-size: 1rem;
        &.is-multiline{
          padding-left: 78px;
          font-size: 1rem;
        }
      }
    }
  }
  @include media($lg-only) {
    .ribbon-container {
      top: 50px;
      .ribbon {
      }
    }
  }
  @include media($xl-only) {
    .ribbon-container {
      top: 50px;
      .ribbon {
      }
    }
  }
  &.slick-slide{
    border-radius: 0;
    border-left: 1px solid $lightest-grey;
    &.slick-current{
      border-left-color: transparent;
    }
    .ribbon-container{
      left: 0;
    }
  }
}