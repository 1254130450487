.infinite-scroll{
  .infinite-loader {
    color: $blue;
    @extend .fa;
    @extend .fa-spin;
    font-size: 3rem;
    margin: 0 1rem 2rem 50%;
    position: relative;
    left: -30px;
    &:before{
      content: "\f110";
    }
  }
  #infinite-handle{
    text-align: center;
    span{
      display: inline-block;
      background: $blue;
      color: $white;
      padding: 1rem 1.5rem;
      cursor: pointer;
      border-radius: $global-border-radius-half;
      &:hover{
        background: darken($blue,10);
      }
    }
  }
}