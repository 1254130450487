.is-gsm {
  .offer-type {
    background: $light-blue-grey;
    h3 {
      margin-bottom: 1rem;
    }
    .button-wrapper {
      button {
      }
    }
    @include media($lg) {
      &.is-rtl {
        text-align: right;
        button {
          float: none !important;
          display: inline-block !important;
          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.is-gsm.template_offer{
  .offer-main {
    .offer-type {
      .button-wrapper {
        button {
          margin-bottom: 0;
        }
      }
    }
  }
}
