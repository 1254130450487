.filter-options {
  &.is-mobile {
    background: $lightest-grey;
    margin-bottom: 2rem;
    .wrapper {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
    .is-filter {
      margin-bottom: 1rem;
      select {
        width: 100%;
      }
    }
  }
  &.is-desktop {
    .is-filter {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }
  .is-subfilter {
    margin: 0.5rem 0;
  }
  h2 {
    line-height: 3rem;
    font-weight: 600;
    font-size: 1.8rem;
  }
  label {
    &.is-checkbox, &.is-radio {
      width: 100%;
    }
  }

  // Specific filter styling
  .bundle-data {
    label {
      width: 70px;
    }
  }
  .bundle-month-price {
    input {
      width: 70px;
    }
  }
}