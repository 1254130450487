.widget{
  margin-bottom: 2rem;
  h3{
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  a{
    text-decoration: underline;
    line-height: 1.2;
    font-size: 1.4rem;
    &:hover{
      color: $blue;
    }
  }
  ul{
    li{
      margin: 1rem 0;
      a{
      }
    }
  }
  &.widget_tag_cloud{
    .tagcloud{
      @include clearfix;
      a{
        font-size: 1.4rem !important;
        float:left;
        clear:left;
        margin: 0.5rem 0;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}