.bar-service{
  background: $yellow;
  .wrapper{
    @extend .container;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .service-offline{
    text-align: center;
    .callcenter{
      line-height: 1.3;
    }
    .intro{
      display: block;
      font-size: 2rem;
      font-weight: 700;
    }
    .is-open{
      .call-cta{
        display: block;
      }
      .call-number{
        font-size: 1.8rem;
        font-weight: 700;
      }
      .call-open{
        font-size: 1.4rem;
      }
    }
    .is-closed{
      .mail-cta{
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 700;
        text-decoration: underline;
      }
      .is-alt{
        font-size: 1.4rem;
        white-space: nowrap;
      }
    }
  }
  @include media($xs){}
  @include media($sm){
    .service-offline {
      text-align: left;
      .is-open {
        .call-cta {
          display: inline;
          margin-right: 1rem;
        }
      }
      .is-closed{
        .mail-cta{
          display: inline;
        }
      }
    }
  }
  @include media($md){}
  @include media($lg){
    .service-offline {
      text-align: center;
      .intro{
        display: inline;
      }
      .is-open{
        .call-cta {
          margin-left: 1rem;
        }
      }
      .is-closed{
        .mail-cta{
          margin-left: 1rem;
        }
      }
    }
  }
  @include media($xl){}
}