.blog-content{
  @include media($xs){}
  @include media($sm){}
  @include media($md){
    .blog-posts{
      @include span-columns(16);
    }
    .blog-sidebar{
      @include span-columns(8);
    }
  }
  @include media($lg){
    .blog-posts{
      @include span-columns(18);
    }
    .blog-sidebar{
      @include span-columns(6);
    }
  }
  @include media($xl){}
}