// Flexible gutter
@function flex-gutter-helper($container-columns: $fg-max-columns, $gutter: $fg-gutter) {
  $container-width: $container-columns * $fg-column + ($container-columns - 1) * $fg-gutter;
  @return ($gutter / $container-width);
}

@mixin gutter-all($type, $multiplier:1, $extra:0){
  @include media($xxs-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($xs-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($sm-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($md-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($lg-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($xl-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
}

@mixin gutter-all-mq($type, $mq, $multiplier:1, $extra:0){
  @if $mq == "xxs" {
    @include media($xxs-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
  @else if $mq == "xs"{
    @include media($xs-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
  @else if $mq == "sm"{
    @include media($sm-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
  @else if $mq == "md"{
    @include media($md-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
  @else if $mq == "lg"{
    @include media($lg-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
  @else if $mq == "xl"{
    @include media($xl-only){
      #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
    }
  }
}

@mixin gutter-all-mobile($type, $multiplier:1, $extra:0){
  @include media($xxs-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xxs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($xs-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xs - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($sm-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-sm - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
}

@mixin gutter-all-desktop($type, $multiplier:1, $extra:0){
  @include media($md-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-md - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($lg-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-lg - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
  @include media($xl-only){
    #{$type}: (flex-gutter-helper($grid-columns) * ($max-width-xl - (flex-gutter-helper($grid-columns) * 2))) * $multiplier + $extra;
  }
}
