article.post{
  h2.entry-title{
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .entry-meta{
    font-size: 1.4rem;
    margin-bottom: 1rem;
    color: $medium-grey;
    time{
      display: inline;
    }
    .author{
      display: inline;
      a{
        color: darken($medium-grey,10);
        font-weight: 700;
        &:hover{
          color: $blue;
        }
      }
    }
    .categories{
      display: inline;
      color: darken($medium-grey,10);
      font-weight: 700;
      &:hover{
        color: $blue;
      }
    }
    .tags{
      display: none;
    }
  }
  .image{
    padding-top: 0.5rem;
    img{
      border-radius: $global-border-radius-half;
    }
  }
  .entry-summary{
    > span{
      line-height: 1.4;
      display: inline-block;
    }
    a{
      white-space: nowrap;
      color: $blue;
      &:after{
        @extend .fa;
        content: '\f054';
        font-size: 1.2rem;
        margin-left: 0.3rem;
      }
      &:hover{
        color: darken($blue,10);
      }
    }
  }
  @include media($xs){}
  @include media($xs-down){
    .image{
      display: none;
    }
  }
  @include media($sm){
    .image{
      display: table-cell;
      width: 100px;
      vertical-align: top;
      padding-right: 20px;
    }
    .entry-summary{
      display: table-cell;
      vertical-align: top;
    }
  }
  @include media($md){}
  @include media($lg){}
  @include media($xl){}
}