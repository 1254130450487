.template_order-overview{
  main{
    @include outer-container();
    padding: 3rem 0 5rem 0;
  }

  .order-image{
    padding-right: 2rem;
    .product-image{
      margin-bottom: 1rem;
    }
    .provider-image{
      padding: 0 1rem;
      max-height: 20px;
      display: block;
      margin: 0 auto;
    }
  }
  .order-item{
    font-size: 1.4rem;
    &.is-discount{
      color: $green;
    }
    &.is-title{
      h2{
        margin-bottom: 0.25rem;
        font-size: 1.6rem;
      }
      p{
        font-size: 1.2rem;
      }
    }
    > div{
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      line-height: 1.2;
      &.is-price{
        background: $lightest-grey;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .is-content{
    border-bottom: 1px solid $light-grey;
    .order-section-title{
      font-size: 1.6rem;
      h2{
        margin-bottom: 0.5rem;
        padding-top: 1.3rem
      }
      p{
        font-size: 1.4rem;
      }
    }
    .order-item{
      &.is-strong{
        font-size: 1.5rem;
        font-weight: 700;
      }
      &:last-child{
        > div{
          padding-bottom: 1.5rem;
        }
      }
      .order-title{
        //font-size: 1.4rem;
      }
    }
  }
  .order-header{
    .order-item {
      font-size: 1.6rem;
      font-weight: 700;
      > div{
        padding-top: 1rem;
        padding-bottom: 0;
        border-radius: $global-border-radius-half $global-border-radius-half 0 0;
      }
    }
    &.is-content{
      margin-top: 1rem;
      .order-item{
        > div{
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        &:first-child{
          > div{
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
  .order-footer{
    border-top: 1px solid $light-grey;
    .order-section-title{
      font-size: 2rem;
      padding: 1rem 0;
    }
    .order-item {
      font-size: 2rem;
      font-weight: 700;
      > div{
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 0 0 $global-border-radius-half $global-border-radius-half;
      }
    }
  }

  .order-overview{
    @include span-columns(24);
    @include omega();
    .order-image{
      display: none;
    }
    .order-details{
      @include span-columns(24);
      @include omega();
    }
    .order-navigation{
      @include span-columns(24);
      margin-top: 2rem;
    }
  }

  .order-details{
    section{
      @include outer-container();
      .order-section-title{
        display: none;
      }
      .order-description{
        @include span-columns(24);
      }
    }
    .disclaimer{
      display: block;
      margin-top: 1rem;
      color: $medium-grey;
    }
  }

  .order-item{
    @include outer-container();
    .order-title{
      @include span-columns(10);
      &.is-full-width{
        @include span-columns(24);
        @include omega();
      }
    }
    .order-monthly{
      @include span-columns(7);
      text-align: right;
    }
    .order-once{
      @include span-columns(7);
      text-align: right;
    }
  }

  .order-service{
    @include span-columns(24);
    margin-top: 4rem;
    > div{
      margin-bottom: 2rem;
    }
  }

  @include media($xxs-only){
    .content{
      padding: 0;
    }
    .order-item{
      font-size: 1.2rem;
      &.is-title{
        h2{
          font-size: 1.4rem;
        }
        p{
          font-size: 1.1rem;
        }
      }
    }
    .order-header{
      .order-item {
        font-size: 1.2rem;
        > div{
          &.is-price{

          }
        }
      }
    }
    .order-footer{
      .order-section-title{
        font-size: 1.6rem;
      }
      .order-item {
        font-size: 1.6rem;
      }
    }
  }
  @include media($xs){}
  @include media($xs-down){}
  @include media($sm){}
  @include media($sm-down){
    .is-content {
      .order-item {
        &:first-child {
          > div {
            padding-top: 1.5rem;
          }
        }
      }
    }
  }
  @include media($md){
    .is-content {
      .order-item {
        &.is-first {
          > div {
            padding-top: 1.5rem;
          }
        }
      }
    }

    .order-footer{
      .order-title{
        visibility: hidden;
      }
    }

    .order-overview{
      @include span-columns(24);
      @include omega();
      .order-image{
        @include span-columns(4);
      }
      .order-details{
        @include span-columns(20);
        @include omega();
      }
      .order-navigation{
        @include span-columns(24);
      }
    }

    .order-details{
      section{
        @include outer-container();
        .order-section-title{
          @include span-columns(7);
          &.is-full-width{
            @include span-columns(24);
            @include omega();
          }
        }
        .order-description{
          @include span-columns(17);
        }
      }
    }

    .order-item{
      @include outer-container();
      &.is-title{
        display: none;
      }
      .order-title{
        @include span-columns(10);
        &.is-full-width{
          @include span-columns(24);
          @include omega();
        }
      }
      .order-monthly{
        @include span-columns(7);
      }
      .order-once{
        @include span-columns(7);
      }
    }

    .order-service{
      @include span-columns(12);
      @include shift(6);
    }
  }
  @include media($lg){
    .order-overview {
      @include span-columns(18);
    }
    .order-service{
      @include span-columns(6);
      @include shift(0);
      margin-top: 0;
    }
  }
  @include media($xl){}

  &.is-energy{
    .order-contract{
      .order-section-title{
        font-size: 1.8rem;
        h2{
          font-size: 2rem;
          margin-bottom: 1rem;
        }
        .contract-title{

        }
        .contract-extra{

        }
      }
    }
    .source{
      margin-top: 1rem;
      background: lighten($light-grey,5);
      color: $dark-grey;
      padding: 1rem;
      border-radius: $global-border-radius-fourth;
      &.is-calculated{
        background: $green;
      }
      &.is-average{
        background: lighten($light-grey,5);
      }
      small{
        font-size: 80%;
      }
    }

    .order-item{
      .order-title{
      }
      .order-monthly{
      }
      .order-once{
      }
    }

    .order-footer{
      .order-item{
        font-size: 1.4rem;
        > div{
          padding-left: 0;
        }
      }
    }
    @include media($md){
      .order-details{
        section{
          @include outer-container();
          .order-section-title{
            @include span-columns(5);
            &.is-full-width{
              @include span-columns(24);
              @include omega();
            }
          }
          .order-description{
            @include span-columns(19);
          }
        }
      }

      .order-footer{
        .order-item{
          font-size: 2rem;
        }
      }
    }
  }
}
