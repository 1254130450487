.is-gsm {
    .overview-contracts-with-phone {
        background: $lightest-grey;
        @include media($xs-only){
            .offer-wrapper.slick-slider {
                margin-right: 8rem;
            }
        }
        @include media($xs) {
        }
        @include media($sm) {
            .offer-wrapper:not(.slick-slider) {
                .tile {
                    @include span-columns(12);
                    @include omega(2n);
                }
            }
        }
        @include media($md) {
        }
        @include media($lg) {
            .offer-wrapper:not(.slick-slider) {
                .tile {
                    @include span-columns(8);
                    @include omega-reset(2n);
                    @include omega(3n);
                    &:nth-child(n+4) {
                        display: none;
                    }
                }
            }
        }
        @include media($xl) {
            .offer-wrapper:not(.slick-slider) {
                .tile {
                    @include span-columns(6);
                    @include omega-reset(3n);
                    @include omega(4n);
                    &:nth-child(n+4) {
                        display: block;
                    }
                }
            }
        }
    }
}
