.offer-wrapper.slick-slider{
  @include gutter-all('margin-bottom');
  background: transparent;
  border-radius: $global-border-radius;
}

.offer-wrapper:not(.slick-slider) {
  .tile.is-offer {
    background-color: $white;
  }
}
