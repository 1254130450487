.is-gsm {
  .offer-contract-specification {
    background: $light-blue-grey;
    border-bottom: 1rem solid $blue;
    .title-wrapper {
      position: relative;
      padding-left: 7rem;
      .fa-stack {
        position: absolute;
        left: 0;
        top: -10px;
        .fa-circle {
          color: $blue;
        }
      }
    }
    .tabs {
      border: 2px solid $medium-blue-grey;
    }
    @include media($xs) {
    }
    @include media($sm) {
    }
    @include media($md) {
    }
    @include media($lg) {
    }
    @include media($xl) {
    }
  }
}