.is-gsm {
  .filter-sorter {
    text-align: right;
    margin-bottom: 2rem;
    h2 {
      line-height: 3rem;
      font-weight: 500;
      display: inline;
    }
  }
}