.is-overview{
  .overview-wrapper{
    @include outer-container();
  }
  .offer-wrapper{
    &:after{
      clear: both;
      content: "";
      display: table;
    }
    &.slick-slider{
      background: $white;
    }
    &:not(.slick-slider) {
      .tile {
        @include gutter-all('margin-bottom');
      }
    }
  }
  a.overview-cta{
    display: inline-block;
    text-align: center;
    border-radius: $global-border-radius-half;
    padding: 1rem 2rem;
    background: $dark-grey;
    color: $white;
    &:hover{
      background: darken($dark-grey,10);
    }
    .fa-chevron-right{
      margin-left: 0.5rem;
    }
  }
  p.overview-cta {
    line-height: 1.2;
    font-weight: 300;
  }
  @include media($mobile){
    .overview-cta{
      margin-top: 1rem;
    }
    .slick-slider + .overview-cta{
      margin-top: 1.5rem;
    }
  }
  @include media($sm-only){
    a.overview-cta{
      min-width: 49%;
    }
  }
  @include media($md){
    a.overview-cta{
      min-width: 33%;
    }
  }
  @include media($xl){
    a.overview-cta{
      min-width: 25%;
    }
  }
}