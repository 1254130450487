.tile.is-image{
    &.is-normal{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-color: $t-mobile;
        @include media($xs-only){
            background-position-y: -15px;
        }
        .tile-content{
            h3,.offer-image,.offer-content{
                visibility: hidden;
            }
            &:hover{
                .cta{
                    background: $black;
                    color: $yellow;
                }
            }
        }
        .cta {
            background: $yellow;
            color: $black;
        }
    }
    &.is-madness{
        background-image: url('#{$image-path}/gsm/t-mobile-madness.jpg') !important;
    }
    &.is-vodafone{
        background-image: url('#{$image-path}/gsm/vodafone-overstap-knallers.jpg') !important;
    }
}
