.tile.is-gsm.is-sim{
  .offer-content{
  }
  .offer-bundle{
    margin-bottom: 1.5rem;
  }
  .amount{
    font-weight: 700;
  }
  .price-month{
    font-size: 4.2rem;
    font-weight: 700;
    position: relative;
    .cent{
      position: absolute;
      top: 0.2rem;
      font-size: 2.4rem;
    }
    .period{
      position: absolute;
      bottom: 0.5rem;
      margin-left: 0.1rem;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  @include media($xxs-only){
    h3{
      font-size: 1.8rem;
    }
    .offer-image{
      @include span-columns(7);
    }
    .offer-content{
      @include span-columns(17);
      @include omega();
    }
    .offer-bundle{
      margin-bottom: 1.5rem;
    }
  }
  @include media($xs-only){
    .offer-image{
      @include span-columns(6);
    }
    .offer-content{
      @include span-columns(18);
      @include omega();
    }
    .offer-bundle{
      font-size: 1.8rem;
    }
  }
  @include media($sm-only){
    .offer-image{
      @include span-columns(6);
    }
    .offer-content{
      @include span-columns(18);
      @include omega();
    }
    .offer-bundle{
      font-size: 1.8rem;
    }
  }
  @include media($md-only){
    h3{
      position: relative;
      &:before{
        content: " ";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
      .center{
        display: inline-block;
        vertical-align: middle;
      }
    }
    .offer-image{
      @include span-columns(6);
    }
    .offer-content{
      @include span-columns(18);
      @include omega();
    }
  }
  @include media($lg-only){
    h3{
      font-size: 1.8rem;
    }
    .offer-image{
      @include span-columns(6);
    }
    .offer-content{
      @include span-columns(18);
      @include omega();
    }
  }
  @include media($xl-only){
    .offer-image {
      @include span-columns(4);
    }
    .offer-content {
      @include span-columns(20);
      @include omega();
    }
    .offer-bundle {
      @include span-columns(10 of 20);
      margin-top: 2.5rem;
    }
    .offer-price {
      @include span-columns(10 of 20);
      margin-top: 1.5rem;
    }
  }

  &.is-normal{
    .offer-content{
      padding-left: 0.5rem;
    }
    .price-once{
      color: $medium-grey;
      font-size: 1.5rem;
    }

    @include media($xs-only){}
    @include media($sm-only){}
    @include media($md-only){
      .price-once{
        font-size: 1.4rem;
      }
    }
    @include media($lg-only){}
    @include media($xl-only){
      .price-once{
        font-size: 1.4rem;
      }
    }
  }

  &.is-hero{
    .tile-content{
      position: relative;
    }
    h3{
      text-align: center;
    }
    .wrapper-left{
      display: block;
    }
    .wrapper-right{
      display: block;
    }
    .offer-image{
      @include omega();
    }
    .usp{
      margin: 1.5rem 0;
      color: #444444;
      font-weight: 700;
    }
    @include media($xxs-only){
      .offer-image{
        @include span-columns(10);
        @include omega();
      }
      .offer-content{
        @include span-columns(14);
      }
      .price-once{
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
    @include media($xs){
      .offer-image{
        position: absolute;
        right: 0;
      }
    }
    @include media($xs-only){
      .offer-content {
        @include span-columns(14);
        font-size: 1.8rem;
      }
      .offer-image{
        @include span-columns(10);
        @include omega();
        top: 41px;
      }
    }
    @include media($sm){
      h3{
        text-align: left;
      }
      .wrapper-left{
        @include span-columns(8 of 16);
      }
      .wrapper-right{
        @include span-columns(8 of 16);
        @include omega();
      }
      .usp{
        margin-bottom: 0;
      }
    }
    @include media($sm-only){
      .offer-content{
        @include span-columns(17);
        font-size: 1.5rem;
      }
      .offer-image{
        @include span-columns(7);
        @include omega();
        top: -70px;
      }
    }
    @include media($md-only){
      .offer-image{
        @include span-columns(7);
        @include omega();
      }
      .offer-content {
        @include span-columns(17);
      }
      .offer-image{
        top: -87px;
      }
    }
    @include media($lg){
      .offer-content {
        font-size: 1.8rem;
      }
    }
    @include media($lg-only){
      .offer-content {
        @include span-columns(17);
      }
      .offer-image{
        @include span-columns(7);
        @include omega();
        top: -104px;
      }
    }
    @include media($xl-only){
      .offer-content {
        @include span-columns(17);
      }
      .offer-image{
        @include span-columns(7);
        @include omega();
        top: -84px;
      }
    }
  }

  &.is-full-width{
    border: none;
    padding: 1rem 0 2rem 0;
    border-bottom: 1px solid lighten($light-grey, 10);
    margin-bottom: 1rem;
    border-radius: 0;

    &.is-preferred{
      border-width: 2px;
    }

    &[data-deal="jbl"]{
      .deal-description{
        font-size: 85%;
        color: $t-mobile;
        font-weight: 700;
      }
    }

    h3{
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }

    .offer-image{
      display: none;
    }

    .offer-bundle{
      @include span-columns(13);
      font-size: 1.4rem;
      margin: 0;
      .data{
        margin: 0.5rem 0;
      }
      .call{
        margin-bottom: 0.5rem;
      }
      .sms{
      }
    }

    .offer-specs{
      @include span-columns(24);
      @include omega();
      margin-top: 1rem;
      li{
        white-space: normal;
        overflow: visible;
        font-size: 1.2rem;
        padding: 0.2rem 0 0.2rem 1.5rem;
        small{
          white-space: nowrap;
        }
        &:before{
          @extend .fa;
          content: '\f00c';
          position: absolute;
          left: 0;
        }
        &:after{
          height: auto;
          width: auto;
          position: relative;
          background: transparent;
        }
      }
    }

    .price-month{
      @include span-columns(10);
      @include omega();
      margin-top: 1.5rem;
    }

    @include media($xxs-only){
      .price-month{
        font-size: 3.2rem;
        .cent{
          top: 0.1rem;
          font-size: 2rem;
        }
        .period{
          font-size: 1rem;
          bottom: 0.3rem;
        }
      }

      &[data-deal="jbl"]{
        .deal-description{
          position: relative;
          top: -4px;
        }
      }
    }

    @include media($xs){
      .offer-image{
        @include span-columns(3);
      }

      .offer-bundle {
        @include span-columns(11);
        //font-size: 1.8rem;
        .data {
          margin-top: 0.7rem;
        }
      }

      .price-month {
        @include span-columns(10);
        @include omega();
      }
    }
    @include media($sm){
      h3{
        font-size: 2.2rem;
      }

      .offer-image{
        @include span-columns(4);
        max-height: 100px;
      }

      .offer-bundle {
        @include span-columns(8);
        @include omega();
        .data {
          margin-top: 0.4rem;
        }
      }

      .offer-specs{
        @include span-columns(20);
        @include omega();
        margin-top: 0;
        margin-bottom: 1rem;
      }

      .price-month {
        @include span-columns(12);
        @include shift(0);
        margin-top: 1rem;
      }
    }
    @include media($md){
      .offer-image{
        max-height: 102px;
      }

    }
    @include media($lg){
      .offer-image{
        @include span-columns(3);
      }
      .offer-bundle{
        @include span-columns(6);
        padding-left: 0;
        .data{
        }
      }
      .price-month{
        @include span-columns(8);
        @include omega();
        margin-top: 1.1rem;
      }
      .offer-specs{
        @include span-columns(21);
        @include omega();
      }
      .cta{
        @include span-columns(7);
        @include omega();
        margin-top: 1.3rem;
      }
    }
    @include media($xl){
      .offer-image{
        @include span-columns(2);
      }
      h3{
        @include span-columns(11);
      }
      .offer-specs{
        @include span-columns(11);
        @include omega();
        margin-bottom: 0;
      }
      .offer-bundle{
        @include span-columns(5);
      }
      .price-month{
        @include span-columns(6);
        margin-top: 1.8rem;
      }
      .cta{
        margin-top: 1.8rem;
      }

      &.is-tmobile,&.is-tele2{
        .offer-bundle{
          margin-top: -0.8rem;
        }
        .price-month{
          margin-top: 1rem;
        }
        .cta{
          margin-top: 1rem;
        }
      }
    }
  }
}